import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import InvoicePayment from "./InvoicePayment.js";
import Form from "./Form.js";

const Invoice = ({invoice, refetchData}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false);
  const [url, setUrl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [openInvoicePayment, setOpenInvoicePayment] = useState(false);

  const deleteInvoice = () => {
    axios.delete(`/api/o/${match.params.organization_id}/projects/${invoice.project.token}/invoices/${invoice.token}`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        notice("Deleted invoice");
        refetchData();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const downloadInvoice = () => {
    axios.post(`/api/o/${match.params.organization_id}/projects/${invoice.project.token}/invoices/${invoice.token}/download_invoice`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setUrl(response.data.url);
        setOpen(true);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      // reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  };

  const handleUpdate = (status) => {
    axios.post(`/api/o/${match.params.organization_id}/projects/${invoice.project.token}/invoices/${invoice.token}/status`, {
      status: status
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  return(
    <React.Fragment>
      <tr className="background-3">
        <th scope="row">{invoice.invoice_number}</th>
        <td>{invoice.issued}</td>
        <td>{invoice.due}</td>
        <td>
          <Link to={`/o/${match.params.organization_id}/projects/${invoice.project.token}/details`}>{invoice.project.title}</Link>
        </td>
        <td>
          {invoice.bill_to}
        </td>
        <td>
          {numberToCurrency((invoice.price || 0), invoice.currency)}
        </td>
        <td>
          {numberToCurrency((invoice.paid_amount || 0), invoice.currency)}
        </td>
        <td>
          <div class="btn-group">
            <div class="dropdown-togglec cursor-pointer opacity-7 opacity-10-hover" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {(invoice.status == null) && 
                <React.Fragment>
                  <i class="fad fa-circle-notch color-yellow mr-5"></i>Pending payment
                </React.Fragment>
              }
              {(invoice.status === "Paid") && 
                <React.Fragment>
                  <i class="fad fa-circle color-green mr-5"></i>Paid
                </React.Fragment>
              }

              {(invoice.status === "Partial") && 
                <React.Fragment>
                  <i class="fad fa-circle-notch color-green mr-5"></i>Partial payment
                </React.Fragment>
              }

              {(invoice.status === "Void") && 
                <React.Fragment>
                  <i class="fad fa-circle color-red mr-5"></i>Void
                </React.Fragment>
              }

              {(invoice.status === "Collections") && 
                <React.Fragment>
                  <i class="fad fa-circle color-orange mr-5"></i>Collections
                </React.Fragment>
              }
            </div>

            <ul class="dropdown-menu pull-right animated fadeInUp">
              <li><a onClick={() => setOpenInvoicePayment(true)}><i class="fad fa-circle color-green mr-5"></i>Record payment</a></li>
              <li><a onClick={() => handleUpdate("Void")}><i class="fad fa-circle color-red mr-5"></i>Void</a></li>
              <li><a onClick={() => handleUpdate("Collections")}><i class="fad fa-circle color-orange mr-5"></i>Collections</a></li>
            </ul>
          </div>
        </td>
        <td>
          <a className="color-1 btn btn-small background-hover" target="_blank" href={`/invoice/${invoice.token}`}><i class="far fa-link mr-8"></i>Checkout</a>
        </td>
        <td className="text-right">
          <div class="btn-group">
            <a type="button" class="btn btn-small background-hover opacity-4 color-1 opacity-10-hover dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fas fa-ellipsis-v"></i>
            </a>

            <ul class="dropdown-menu pull-right animated fadeInUp" style={{minWidth: 'unset'}}>
              <li><a onClick={() => setOpenForm(true)}><i class="fal fa-pencil"></i>Edit</a></li>
              {invoice.status == null &&
                <li><a onClick={deleteInvoice}><i class="fal fa-trash"></i>Delete</a></li>
              }
              <li><a onClick={downloadInvoice}><i class="fal fa-file-pdf"></i>Download invoice</a></li>
            </ul>
          </div>
        </td>
      </tr>

      <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
        <iframe src={url} className="download-invoice-iframe" />
      </Modal>

      <Modal open={openInvoicePayment} onClose={() => setOpenInvoicePayment(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <InvoicePayment invoice={invoice} refetchData={refetchData} closeModal={() => setOpenInvoicePayment(false)}/>
      </Modal>

      <Modal open={openForm} onClose={() => setOpenForm(false)} className="modal-body-white" classNames={{ modal: "width-800" }} center>
        <Form invoice={invoice} refetchData={() => {refetchData();}} project_id={null} closeModal={() => setOpenForm(false)}/>
      </Modal>
    </React.Fragment>
  )
}

export default Invoice;