import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 

const BankInfo = ({organization, refetchData, closeModal}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [address, setAddress] = useState(organization.address)
  const [bankInfo, setBankInfo] = useState(organization.bank_info);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.put(`/api/o/${match.params.organization_id}`, {
      address: address,
      bank_info: bankInfo
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData();
        notice("Updated successfully");
        closeModal();
      } else {
        notice("An error occured")
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }


  return(
    <React.Fragment>
      <h4 className="color-black-always modal-title">Wire transfer instructions</h4>
      
      <div className="row no-margin">
        <form onSubmit={handleSubmit}>
          <div className="field">
            <label>Business address</label>
            <textarea rows="3" className="form-control" placeholder="1234 Mainstreet Blvd. San Francisco, CA, 33647" value={address} onChange={(e) => setAddress(e.target.value)}/>
          </div>

          <div className="field">
            <label>Wire instructions</label>
            <textarea className="form-control" rows="3" placeholder="Routing# and Account # for wire transfer" value={bankInfo} onChange={(e) => setBankInfo(e.target.value)}/>
          </div>

          <div className="field text-right">
            <button className="btn btn-primary" type="submit">Submit</button>
          </div>
        </form>
      </div>
    </React.Fragment>
  )
}

export default BankInfo;