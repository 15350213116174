import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import loadingData from "../../Shared/Lotties/lottie-loading.json";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "../../Shared/Lotties/lottie-empty.json";

const OnboardingStripeProducts = ({setImportFinished}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = () => {
    axios.get(`/api/o/${match.params.organization_id}/fetch_products.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setProducts(response.data.products);
        setLoaded(true)
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/o/${match.params.organization_id}/import_products`, {
      selected_products: selectedProducts
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setImportFinished(true)
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  };

  // Function to handle checkbox change
  const handleCheckboxChange = (event, product) => {
    const { checked } = event.target;
    if (checked) {
      setSelectedProducts([...selectedProducts, product]);
    } else {
      setSelectedProducts(selectedProducts.filter((p) => p.id !== product.id));
    }
  };

  const products_list = products.map(product => {
    return(
      <div className="onboarding-products-wrapper" key={product.id}>
        <input className="onboarding-products-checkbox" type="checkbox" checked={selectedProducts.some((p) => p.id === product.id)} onChange={(event) => handleCheckboxChange(event, product)}/>
        <div className="ml-10">
          <div className="color-1 dark-color-black">{product.name}</div>
          <div className="color-1 opacity-4 font-12 dark-color-black">{product.prices.length} prices</div>
        </div>
      </div>
    )
  })

  return(
    <React.Fragment>
      {loaded ? (
        <div style={{width: "100%", maxHeight: "50vh", overflow: "auto"}}>
          {products.length > 0 ? (
            products_list
          ):(
            <>
              <p className="font-12 large-padding opacity-4 text-center">
                <Player style={{width: "300px", margin: "auto"}} mode="normal" autoplay loop src={animationData} />
                You have no products to import
              </p>
            </>
          )}
        </div>
      ):(
        <div className="text-center opacity-7 width-100-percent large-padding">
          <Player style={{width: "100px", margin: "auto"}} mode="normal" autoplay loop src={loadingData} />
          <p className="font-12 opacity-6 mt-15 text-center">
            Loading your products from Stripe...
          </p>
        </div>
      )}
      {(loaded && products.length > 0) &&
        <div className="field mt-15" style={{width: "100%"}}>
          <button disabled={selectedProducts.length == 0} onClick={handleSubmit} style={{width: "100%"}} className="btn btn-primary-blue btn-block"><i class="far fa-file-import mr-10"></i> Import {selectedProducts.length} products</button>
        </div>
      }
    </React.Fragment>
  )
}

export default OnboardingStripeProducts