import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import Toggle from 'react-toggle';
import {UserContext} from "../../Shared/UserContext.js";
import Currency from "./Currency.js";

const DiscountForm = ({closeModal, refetchData, currency}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const currentUser = useContext(UserContext);
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [services, setServices] = useState([]);
  const [showStartDate, setShowStartDate] = useState(false)
  const [showExpiryDate, setShowExpiryDate] = useState(false);
  const [showRedemptionLimit, setShowRedemptionLimit] = useState(false)
  
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [type, setType] = useState("Percentage");
  const [duration, setDuration] = useState('Forever');
  const [numberOfMonths, setNumberOfMonths] = useState();
  const [everyMonth, setEveryMonth] = useState(false);
  const [expiryDate, setExpiryDate] = useState();
  const [redemptionLimit, setRedemptionLimit] = useState();
  const [startDate, setStartDate] = useState();
  const [code, setCode] = useState('');
  const [title, setTitle] = useState('');
  const [percentageOff, setPercentageOff] = useState();
  const [amountOff, setAmountOff] = useState();
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

  useEffect(() => {
    fetchServices();
  },[])

  const fetchServices = () => {
    axios.get(`/api/o/${match.params.organization_id}/services.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setServices(response.data.services)
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const handleSelectChange = (event) => {
    const selectedOption = event.target.value;
    
    // Check if the selected option is already in the array
    if (!selectedOptions.includes(selectedOption)) {
      setSelectedOptions((prevOptions) => [...prevOptions, selectedOption]);
    }
  };

  const handleRemoveOption = (optionToRemove) => {
    setSelectedOptions((prevOptions) =>
      prevOptions.filter((option) => option !== optionToRemove)
    );
  };
  
  const selected_list = selectedOptions.map((selected) => {
    return (
      <span className="badge badge-primary mr-5 mt-10" key={selected}>
        {selected}
        <i className="fas fa-times cursor-pointer ml-10" onClick={() => handleRemoveOption(selected)}></i>
      </span>
    );
  });

  const handleDurationChange = (event) => {
    setDuration(event.target.value);
  };

  const handleMonthsChange = (event) => {
    setNumberOfMonths(event.target.value);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/o/${match.params.organization_id}/coupons`, {
      title: title, 
      code: code, 
      selected_services: selectedOptions, 
      type: type, 
      duration: duration, 
      number_of_months: numberOfMonths, 
      // very_month: everyMonth,
      expiry_date: expiryDate, 
      redemption_limit: redemptionLimit, 
      start_date: startDate,
      percentage_off: percentageOff,
      amount_off: amountOff,
      currency: selectedCurrency
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData(); 
        closeModal();
      } else {
        notice(response.data.error)
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const services_list = services.map(service => {
    return(
      <option value={service.token}>{service.title}</option>
    )
  });

  const handleChange = (e) => {
    // Remove any spaces from the input value
    const newValue = e.target.value.replace(/\s/g, '');
    setCode(newValue);
  };

  return(
    <form onSubmit={handleSubmit}>
      <div className="row">
        <h4 className="font-weight-600 mb-15">General</h4>
        <div className="field">
          <label>Name</label>
          <input className="form-control" placeholder="This is so you can identify it." required={true} defaultValue={title} value={title} onChange={(e) => setTitle(e.target.value)}/>
          <p className="daas-form-control-info">This will appear on customers' receipts and invoices.</p>
        </div>

        <div className="field">
          <label>ID</label>
          <input className="form-control" placeholder="No spaces allowed" required={true} defaultValue={code} value={code} onChange={handleChange}  />
          <p className="daas-form-control-info">This is what your customer will enter to redeem.</p>
        </div>

        <div className="field">
          <h4 className="font-weight-600 mb-15">Discount</h4>
          <div className="col-12" style={{display: "flex"}}>
            <a onClick={() => {setType("Percentage"); setSelectedOptions([]); setPercentageOff(null); setAmountOff(null)}} className={`btn btn-small mr-5 ${type == "Percentage" ? "btn-primary" : "btn-primary-outline dark-color-black"}`}>Percentage discount</a>
            <a onClick={() => {setType("Fixed"); setSelectedOptions([]); setPercentageOff(null); setAmountOff(null)}} className={`btn btn-small mr-5 ${type == "Fixed" ? "btn-primary" : "btn-primary-outline dark-color-black"}`}>Fixed amount discount</a>
          </div>
        </div>

        {type == "Percentage" && 
          <div className="field row">
            <div className="col-12 col-md-6 pr-15">
              <label>Percentage off</label>
              <div className="input-group">
                <input
                  type="number"
                  className="form-control"
                  placeholder="50"
                  required={true}
                  defaultValue={percentageOff}
                  value={percentageOff}
                  onChange={(e) => setPercentageOff(e.target.value)}
                />
                <span className="input-group-addon">%</span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <label>Service</label>
              <select value={selectedOptions} onChange={handleSelectChange}>
                <option value="">Select services</option>
                {services_list}
              </select>

              {selected_list}
            </div>
          </div>
        }

        {type == "Fixed" && 
          <div className="field row">
            <div className="col-12 col-md-6 pr-15">
              <label>Amount off</label>
              <div className="input-group">
                <span className="input-group-addon">$</span>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Price"
                  required={true}
                  defaultValue={amountOff} value={amountOff} onChange={(e) => setAmountOff(e.target.value)}
                />
                <span className="input-group-addon">{currency}</span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <label>Service</label>
              <select value={selectedOptions} onChange={handleSelectChange}>
                <option value="">Select services</option>
                {services_list}
              </select>

              {selected_list}
            </div>
          </div>
        }

        <div className="row">
          <div className="field col-6 pr-15">
            <label>Duration</label>
            <select
              className="form-control"
              id="exampleSelect"
              value={duration}
              onChange={handleDurationChange}
            >
              <option value="Forever">Forever</option>
              <option value="Once">Once</option>
              <option value="Repeating">Multiple Months</option>
            </select>
            <p className="daas-form-control-info">For subscriptions and customers, this determines how long this coupon will apply once redeemed.</p>
          </div>

          <div className="field col-6">
            <Currency setSelectedCurrency={setSelectedCurrency} />
          </div>

          {duration === 'Repeating' && (
            <div className="field col-6">
              <label>Number of Months</label>
              <input
                type="number"
                className="form-control"
                id="numberOfMonths"
                value={numberOfMonths}
                onChange={handleMonthsChange}
              />
            </div>
          )}
        </div>
        
        {/* ADDITIONAL OPTIONS */}
        <div className="daas-discount-wrapper">
          <h5 className="font-weight-600 mb-15 display-flex justify-content-between align-items-center cursor-pointer opacity-6 opacity-10-hover" onClick={() => {setShowAdvancedSettings(!showAdvancedSettings); setStartDate(null); setExpiryDate(null); setRedemptionLimit(null);}}>
            Advanced Settings
          </h5>
          
          {showAdvancedSettings && 
            <div className="animated fadeIn">
              <div className="row field">
                <div className="col-10">
                  <label>Discount has a start date?</label>
                  <p className="opacity-7 font-12">Schedule discount to activate in the future</p>

                  {showStartDate && 
                    <input className="form-control" type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)}/>
                  }
                </div>

                <div className="col-2 text-right">
                  <Toggle checked={showStartDate} onChange={() => {setShowStartDate(!showStartDate); setStartDate(null)}} icons={false}/>
                </div>
              </div>

              <div className="row field">
                <div className="col-10">
                  <label>Discount has an expiry date?</label>
                  <p className="opacity-7 font-12">Set a date for discount expiration</p>

                  {showExpiryDate && 
                    <input className="form-control" type="date" value={expiryDate} onChange={(e) => setExpiryDate(e.target.value)}/>
                  }
                </div>

                <div className="col-2 text-right">
                  <Toggle checked={showExpiryDate} onChange={() => {setShowExpiryDate(!showExpiryDate); setExpiryDate(null)}} icons={false}/>
                </div>
              </div>

              <div className="row field">
                <div className="col-10">
                  <label>Discount has a redemption limit?</label>
                  <p className="opacity-7 font-12">Specify the maximum number of redemptions</p>

                  {showRedemptionLimit && 
                    <input className="form-control" type="number" value={redemptionLimit} onChange={(e) => setRedemptionLimit(e.target.value)}/>
                  }
                </div>

                <div className="col-2 text-right">
                  <Toggle checked={showRedemptionLimit} onChange={() => {setShowRedemptionLimit(!showRedemptionLimit); setRedemptionLimit(null)}} icons={false}/>
                </div>
              </div>
            </div>
          }

          {/* <div className="row field">
            <div className="col-10">
              <label>Discount applies every month?</label>
              <p className="opacity-7 font-12">Enable discount to be applied every month</p>
            </div>

            <div className="col-2 text-right">
              <Toggle checked={everyMonth} onChange={() => setEveryMonth(!everyMonth)} />
            </div>
          </div> */}

          <div className="field text-right">
            <button disabled={disabled || (selectedOptions.length == 0) || selectedCurrency === ''} className="btn btn-primary" type="submit">Submit</button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default DiscountForm