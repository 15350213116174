import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import Edit from "./Edit.js";
import { Modal } from 'react-responsive-modal';
import DisplayHTML from "../../Shared/DisplayHTML.js";

const Taskboard = ({taskboard, refetchData}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);

  const deleteTaskboard = () => {
    axios.delete(`/api/o/${match.params.organization_id}/taskboards/${taskboard.token}`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData();
      } else {
        errors_list = response.data.errors;
        errors_list.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const taskboard_services_list = taskboard.services.map(service => {
    return(
      <span className="badge background-2 mr-5 mb-5">{service.title}</span>
    )
  });

  return(
    <React.Fragment>
      <div className="col-12 col-md-4">
        <div className="taskboard-item border-all background-3">
          <div className="taskboard-item-title">
            <h5 className="font-weight-600 no-margin">
              {taskboard.title}
            </h5>

            <div class="btn-group">
              <a type="button" class="btn btn-small color-1 font-12 opacity-4 opacity-10-hover animate dropdown-toggle" data-toggle="dropdown">
                <i class="fas fa-ellipsis-v"></i>
              </a>
              
              <ul class="dropdown-menu animated fadeInUp" role="menu">
                <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
                  <Edit taskboard={taskboard} closeModal={() => setOpen(false)} refetchData={refetchData}/>
                </Modal>
                <li>
                  <a onClick={() => setOpen(true)}>
                    <i className="fas fa-pencil mr-5"></i>Edit
                  </a>
                </li>

                <li>
                  <a onClick={deleteTaskboard} disabled={disabled}>
                    <i className="fas fa-trash mr-5"></i>Delete
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <p className="opacity-7">
            <DisplayHTML htmlContent={taskboard.description} classNames="taskboard-item-description"/>
          </p>
          <div className="taskboard-item-services">
            {taskboard_services_list}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Taskboard;