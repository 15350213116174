import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import Select from "react-select";
import loadingData from "../Shared/Lotties/lottie-loading.json";
import { Player } from "@lottiefiles/react-lottie-player";
import { v4 as uuidv4 } from 'uuid';

const Services = ({invoiceItems, setInvoiceItems, closeModal}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [servicePrices, setServicePrices] = useState([]);
  const [chosenServicePrice, setChosenServicePrice] = useState(null);

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = () => {
    axios.get(`/api/o/${match.params.organization_id}/services.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setServices(response.data.services); 
        setLoaded(true)
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
    });
  }

  const handleServicePriceChange = (selectedOption) => {
    setChosenServicePrice(selectedOption);
  };

  const options = servicePrices.map((service_price) => ({
    value: service_price.token,
    label: `${(parseFloat((service_price.amount/ 100).toFixed(2))).toLocaleString('en-US', {style: 'currency',currency: (service_price.currency)})} ${service_price.recurring ? `per ${service_price.recurring_interval_count} ${service_price.recurring_interval}` : ""}`,
  }));

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black', // Text color of options
    }),
  };

  const serviceOptions = services.filter((item) => !selectedServices.some((selected) => selected.value === item.token)).map((item) => ({
    value: item.token,
    label: item.title,
  }));

  const handleServiceSelect = (selectedOption) => {
    setChosenServicePrice(null);
    if(selectedOption.value == null){
      setSelectedService(null)
    } else {
      setSelectedService(selectedOption);
      axios.get(`/api/o/${match.params.organization_id}/services/${selectedOption.value}.json`)
      .then(function (response) {
        console.log(response);
        if (response.data.success) {
          setServicePrices(response.data.service.service_prices);
        } else {

        }
      })
      .catch(function (error) {
        console.log(error)
        notice("An error occurred")
      })
      .then(function () {
        // always executed
      });
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    
    // Find the selected service price object
    const selectedServiceObject = services.find(service => service.token === selectedService.value);
    const selectedServicePriceObject = servicePrices.find(price => price.token === chosenServicePrice.value);

    const newItem = { 
      token: uuidv4(),
      description: selectedServiceObject.title, 
      quantity: 1, 
      amount: (selectedServicePriceObject.amount / 100), 
      service_id: selectedService.value, 
      service_price_id: chosenServicePrice.value, 
      service: true
    };
    setInvoiceItems([...invoiceItems, newItem]);
    closeModal()
  }


  return(
    loaded ?(
      <React.Fragment>
        <form onSubmit={handleSubmit}>
          <div className="field">
            <label>Service</label>
            <Select
              options={serviceOptions}
              value={selectedService}
              onChange={handleServiceSelect}
              className="min-width-350"
              placeholder="Select..."
              styles={customStyles} 
            />
          </div>

          {selectedService != null && 
            <div className="field">
              <label>Select pricing</label>
              <Select
                options={options}
                value={chosenServicePrice}
                onChange={handleServicePriceChange}
                placeholder="Select a Service Price"
                styles={customStyles} 
              />
            </div>
          }

          <div className="field text-right">
            <button disabled={disabled || chosenServicePrice === null} className="btn btn-primary" type="submit">{t("Add service")}</button>
          </div>
        </form>
      </React.Fragment>
    ):(
      <>
        <Player style={{width: "100px", margin: "auto"}} mode="normal" autoplay loop src={loadingData} />
      </>
    )
  )
}

export default Services;