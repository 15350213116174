import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import axios from "axios"; 
import Edit from "./Edit.js";
import { UserContext } from "../../Shared/UserContext.js";
import { useTranslation } from 'react-i18next';
import Toggle from 'react-toggle';
import ReactTooltip from 'react-tooltip';

const Invite = ({refetchData, users, defaultType, hide}) => {
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [inviteLinks, setInviteLinks] = useState();
  const [copied, setCopied] = useState(false);
  const [organizationUser, setOrganizationUser] = useState(false)
  const [email, setEmail] = useState("");
  const [linkType, setLinkType] = useState(defaultType || "Client")
  const [isPrivate, setIsPrivate] = useState(false);
  const currentUser = useContext(UserContext);
  const { t } = useTranslation();

  useEffect(() => {
    axios.get(`/api/o/${match.params.organization_id}/projects/${match.params.project_id}/invite_links.json`)
    .then(function(response){
      setOrganizationUser(response.data.organization_user)
      setInviteLinks(response.data.invite_links);
      setLoaded(true);

    })
  },[])

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/o/${match.params.organization_id}/invite`, {
      email: email, 
      link_type: linkType, 
      project_id: match.params.project_id,
      private: isPrivate
    })
    .then(function(response){
      console.log(response);
      notice("Invite email sent")
      setEmail("");
      setDisabled(false);
    })
    .catch(function(error){
      console.log(error)
      setDisabled(false);
    })
  }

  const users_list = users.map((user, index) => {
    // Check if user.type is "Client" and user.private is true
    if ((location.href.includes("/portal/o/") && user.private)) {
      // If condition is met, return null or an empty fragment to skip rendering
      return null;
    }
  
    // Render the Edit component for other cases
    return (
      <React.Fragment key={index}>
        <Edit
          user_id={user.identification_id}
          user={user}
          type={user.type}
          refetchData={refetchData}
          organizationUser={organizationUser}
        />
      </React.Fragment>
    );
  });
  
  


  return(
    loaded &&
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        {hide == null && 
          <h4 className="color-black-always modal-title">{t("Invite others")}</h4>
        }
        <div className="display-flex justify-content-between align-items-center">
          <h5 className="font-600 color-black-always">{t("Email")}</h5>
          {linkType == "Workspace" && 
            <CopyToClipboard text={`${inviteLinks.workspace_invite}`} onCopy={() => {setCopied(true); setTimeout(function(){setCopied(false)}, 2000);}}>
              <a class={`${copied ? "color-green" : "color-1"} opacity-6 opacity-10-hover font-12`} type="button"><i class="fal fa-link mr-5"></i>
                Workspace {t("Invite link")}
              </a>
            </CopyToClipboard>
          }

          {linkType === "Collaborator" &&
            <CopyToClipboard text={isPrivate ? `${inviteLinks.collaborator_invite}?private=true` : inviteLinks.collaborator_invite} onCopy={() => {setCopied(true); setTimeout(function(){setCopied(false)}, 2000);}}>
              <a class={`${copied ? "color-green" : "color-1"} opacity-6 opacity-10-hover font-12`} type="button"><i class="fal fa-link mr-5"></i> 
                {isPrivate && "Private"} Collaborator {t("invite link")}
              </a>
            </CopyToClipboard>
          }

          {linkType === "Client" &&
            <CopyToClipboard text={`${inviteLinks.client_invite}`} onCopy={() => {setCopied(true); setTimeout(function(){setCopied(false)}, 2000);}}>
              <a class={`${copied ? "color-green" : "color-1"} opacity-6 opacity-10-hover font-12`} type="button"><i class="fal fa-link mr-5"></i> 
              {location.href.includes("/portal/o") ? (
                "Team"
              ):(
                "Client"
              )} {t("Invite link")}
            </a>
            </CopyToClipboard>
          }
        </div>
        
        <div className="field">
          <div class="input-group">
            <input value={email} onChange={(e) => setEmail(e.target.value)} class="form-control" placeholder="email" type="email"/>
            <div class="input-group-btn">
              {linkType === "Collaborator" && 
                <>
                  <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
                  <a onClick={() => {setIsPrivate(false)}} data-tip="Collaborator will be visible to client" type="button" class={`btn border-right background-hover min-width-unset color-1 height-45px border-radius-none border-all ${isPrivate ? "" : "background-active"}`}>
                    <i class="fal fa-eye"></i>
                  </a>
                  <a onClick={() => {setIsPrivate(true)}} data-tip="Collaborator won't be visible to client" type="button" class={`btn border-right background-hover min-width-unset color-1 height-45px border-radius-none border-all ${isPrivate ? "badge-red" : ""}`}><i class="fal fa-eye-slash"></i></a>
                </>
              }

              {/* {!location.pathname.includes("/portal/o/") && 
              } */}
            <a type="button" className="btn invite-user-button border-all dropdown-toggle min-width-unset color-1 invite-user-middle-button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {location.href.includes("/portal/o") ? (
                "Team"
              ):(
                linkType
              )}
              <i class="far fa-angle-down ml-8"></i>
            </a>
              <ul className="dropdown-menu pull-right">
              {(organizationUser && !location.pathname.includes("/portal/o/")) && 
                <React.Fragment>
                  <li><a onClick={() => setLinkType("Workspace")} style={{whiteSpace: "normal"}}>
                    <label className="cp" style={{marginBottom: "-5px"}}>Workspace member</label>
                    <p className="font-11 opacity-4 margin-none">{t("Access to all projects on the workspace.")}</p>
                  </a></li>

                  <li><a onClick={() => setLinkType("Collaborator")} style={{whiteSpace: "normal"}}>
                    <label className="cp" style={{marginBottom: "-5px"}}>Collaborator</label>
                    <p className="font-11 opacity-4 margin-none">{t("Access to only this project without ability to view payment details.")}</p>
                  </a></li>
                </React.Fragment>
              }

              <li><a onClick={() => setLinkType("Client")} style={{whiteSpace: "normal"}}>
                <label className="cp" style={{marginBottom: "-5px"}}>{t(!location.pathname.includes("/portal/o/") ? "Client" : "Teammate")}</label>
                <p className="font-11 opacity-4 margin-none">{t("Access to only this project with ability to pay.")}</p>
              </a></li>
            </ul>
              <button disabled={disabled || email.length === 0 } type="submit" class="btn btn-primary min-width-unset ml-8 "><i class="fas fa-paper-plane"></i></button>
            </div>
          </div>
        </div>
      </form>

      <div className="field mt-25">
        <h5 className="font-600 color-black-always">{t("Invited")}</h5>
        {users_list}
      </div>
    </React.Fragment>
  )
}

export default Invite;