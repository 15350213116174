import React , { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import isURL from 'validator/es/lib/isURL';
import ReactTooltip from 'react-tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';


const Form = ({closeModal, refetchData, task_id, project_id}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(true)
  const [version, setVersion] = useState("v1")
  const { t } = useTranslation();

  const checkBankDomain = () => {

    const bankDomains = [
      "jpmorganchase.com",
      "bankofamerica.com",
      "wellsfargo.com",
      "citibank.com",
      "hsbc.com",
      "barclays.com",
      "pnc.com",
      "capitalone.com",
      "tdbank.com",
      "53.com",
      "usbank.com",
      "suntrust.com",
      "bbt.com",
      "regions.com",
      "frostbank.com",
      "schwab.com",
      "americanexpress.com",
      "navyfederal.org",
      "ally.com",
      "bbvausa.com",
      "fnb-online.com",
      "firstcitizens.com",
      "bokfinancial.com",
      "cnb.com",
      "fnbo.com",
      "peoples.com",
      "zionsbank.com",
      "eastwestbank.com",
      "bankozarks.com",
      "umb.com",
      "comerica.com",
      "huntington.com",
      "mbfinancial.com",
      "key.com",
      "mtb.com",
      "rocklandtrust.com",
      "santanderbank.com",
      "valley.com",
      "hancockwhitney.com",
      "fidelitybankonline.com"
    ];
    
    const url = document.getElementById("website_link").value;
    const urlObj = new URL(url);
    const urlDomain = urlObj.hostname.replace("www.", "");
    return bankDomains.includes(urlDomain)

  }

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setDisabled(true);
  
    if (checkBankDomain() === false) {
      try {
        const url = `/replays?project_id=${project_id || match.params.project_id}&folder_id=${match.params.folder_id}&version=${version}&task_id=${task_id}`;
        const data = formData("replayWebsiteForm");
        const response = await axios.post(url, { replay: data });


        console.log(response);
        await refetchData();
        closeModal();
        setDisabled(false);
        
      } catch (error) {
        console.log(error);
        notice("An error occured");
      }
    } else {
      alert("Banks aren't allowed.");
    }
  }
  

  const checkWebsite = (e) => {
    if(isURL(e.target.value)){
      setError(null)
    } else {
      setError("That's not a valid url")
    }
  }

  return(
    <React.Fragment>
      <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
      <h4 className="color-black-always modal-title">{t("Website URL")}</h4>

      <form id="replayWebsiteForm" onSubmit={handleSubmit}> 
        <div className="field">
            <input defaultValue="https://" class="form-control" name="website_link" id="website_link" placeholder="https://ycombinator.com" onChange={(e) => checkWebsite(e)}/>
            <p class="font-12 color-red" id="error">{error}</p>
          {/* <div class="input-group">
            <div class="input-group-btn">
              <select name="websiteVersion" id="websiteVersion" style={{width: "60px", padding: "10px 10px"}} onChange={(event) => setVersion(event.target.value)} value={version}>
                <option value="v1">V1</option>
                <option value="v2">V2</option>
              </select>
            </div>
          </div> */}
          {/* <div class="website-version-tooltip" data-tip="If your website isn't working properly, try V2.">Try V2 if your site doesn't load properly</div> */}
        </div>

        <div class="field text-right">
          <button disabled={disabled || error} name="button" type="submit" class="btn btn-primary">{disabled ? "Submitting..." : "Submit"}</button>
        </div>
      </form>
    </React.Fragment>
  )
}

export default Form