import React, { useState, useContext } from 'react';
import {UserContext} from "../Shared/UserContext.js";
import axios from "axios"; 
import Filestack from "./Filestack.js";
import Toggle from 'react-toggle';

const Edit = ({project, closeModal}) => {
  const currentUser = useContext(UserContext);
  const [disabled, setDisabled] = useState(false)
  const [submitting, setSubmitting] = useState(false);
  const [title, setTitle] = useState(project.title);
  const [logo, setLogo] = useState(project.avatar);
  const [isPrivate, setIsPrivate] = useState(project.private);

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setSubmitting(true);
    setDisabled(true);
  
    try {
      const data = formData("editProject")
      const response = await axios.patch(`/api/o/${project.organization.token}/projects/${project.token}`, {
        title: title,
        logo: logo,
        private: isPrivate
      });
  
      console.log(response);
      if(response.data.success){
        await fetchProjectsAsync();
        closeModal();
        notice("Project updated");
        setDisabled(false);
        setSubmitting(false);
      } 
  
    } catch (error) {
      console.log(error);
      notice("An error occurred")
    }
  }

  return(
    <React.Fragment>
      <h4 className="modal-title color-black-always">Edit project</h4>

      <div className="row no-margin">
        <Filestack project={project} setImage={setLogo} image={logo}/>
        <form id="editProject" onSubmit={handleSubmit}>
          <div className="field">
            <label className="color-black-always">Title</label>
            <input className="form-control" name="title" placeholder="Title of your project" defaultValue={project.title} onChange={(e) => setTitle(e.target.value)} required/>
          </div>

          <div className="field display-flex align-items-center">
            <Toggle icons={false} checked={isPrivate} onChange={() => {setIsPrivate(!isPrivate);}} />
            <span className="ml-8">Private project</span>
          </div>

          <div className="field text-right">
            <button type="submit" disabled={disabled} className="btn btn-primary">{submitting ? "Submitting..." : "Submit"}</button>
          </div>
        </form>
      </div>
    </React.Fragment>
  )
}

export default Edit