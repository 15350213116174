import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import Toggle from 'react-toggle';
import { Modal } from 'react-responsive-modal';
import Form from "./Form.js";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ServiceDropdown from "./ServiceDropdown.js";
import AddonForm from "../Addons/AddonForm.js";
import ChooseUpgradeOptions from "./ChooseUpgradeOptions.js";

const Service = ({services, service, refetchData, stripeAccount}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [isToggled, setIsToggled] = useState(service.active);
  const [showPrices, setShowPrices] = useState(false);
  const [open, setOpen] = useState(false);
  const [openUpgrade, setOpenUpgrade] = useState(false);

  useEffect(() => {
    
  },[isToggled])



  const toggleService = (boolean) => {
    axios.post(`/api/o/${match.params.organization_id}/services/${service.token}/toggle`, {
      active: boolean
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        notice("Deleted");
        refetchData();
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const prices_list = service.service_prices.map(price => {
    return(
      <div className="daas-services-item-prices-item">
        {price.recurring && 
        <React.Fragment>
          {numberToCurrency((price.amount/100), price.currency)} {price.currency.toUpperCase()} per {price.recurring_interval_count} {price.recurring_interval}
        </React.Fragment>
        }
        {!price.recurring && 
          <React.Fragment>
            {numberToCurrency((price.amount/100), price.currency)} {price.currency.toUpperCase()}
          </React.Fragment>
        }
      </div>
    )
  })

  return(
    <React.Fragment>
      <tr className="hover-parent-opacity">
        <td className="service-item-td"><Toggle checked={isToggled} onChange={() => {setIsToggled(!isToggled); toggleService(!isToggled)}} /></td>
        <td className="service-item-td font-weight-600 cursor-pointer">
          {service.title} {service.addon && <span className="badge background-3">Add-on</span>}
          {service.credit_type && <i class="far fa-coins ml-8"></i>}
        </td>
        <td className="service-item-td">
          {service.service_prices.length > 1 ? (
            <React.Fragment>
              <a className="color-blue" onClick={() => setShowPrices(!showPrices)}>{service.service_prices.length} plans</a>
              {showPrices && 
                prices_list 
              }
            </React.Fragment>
          ):(
            prices_list
          )}
        </td>

        <td className="service-item-td">
          {service.service_upgrades.length > 0 ?(
            <a onClick={() => setOpenUpgrade(true)} className="btn btn-small background-hover ml-5 color-1" style={{marginLeft: "-15px"}}>
              <i class="fal fa-angle-double-up mr-5"></i>{service.service_upgrades.length} Upgrades 
            </a>
          ):(
            <a onClick={() => setOpenUpgrade(true)} className="btn btn-small background-hover ml-5 color-1 hover-child-opacity" style={{marginLeft: "-15px"}}>
              <i class="fal fa-angle-double-up mr-5"></i>Add upgrades 
            </a>
          )}

          <Modal open={openUpgrade} onClose={() => setOpenUpgrade(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
            <ChooseUpgradeOptions services={services} service={service} closeModal={() => setOpenUpgrade(false)} refetchData={refetchData}/>
          </Modal>
        </td>

        <td className="service-item-td">
          {service.service_addons.length}
          <a onClick={() => setOpen(true)} className="ml-5 color-1" disabled={stripeAccount == null}>
            <i class="fal fa-plus-circle ml-5"></i>
          </a>  
          <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
            {/* <Form closeModal={() => setOpen(false)} refetchData={refetchData} currency={"usd"} addon={true}/> */}
            <AddonForm services={services} refetchData={refetchData} service_addons={service.service_addons} selected_services={{value: service.token, label: service.title}} closeModal={() => setOpen(false)}/>
          </Modal>
        </td> 
        <td className="service-item-td text-right"> 
          <CopyToClipboard text={`https://${window.location.host}/services/${service.token}/checkouts`} onCopy={() => alert("Link copied")} data-tip="Put this on your website or share with your client.">
            <button className="btn btn-primary-outline btn-small"><i class="fal fa-copy mr-10"></i> Checkout link</button>
          </CopyToClipboard>
          
          <ServiceDropdown service={service} refetchData={refetchData} addon={false}/>
        </td>
      </tr>
    </React.Fragment>
  )
}

export default Service