import React , { useState } from 'react';
import { useRouteMatch } from 'react-router-dom'
import { Modal } from 'react-responsive-modal';

const Video = ({src, title, classNames}) => {
  const [open, setOpen] = useState(false);
  
  return(
    <React.Fragment>
      <div className={`client-onboarding-checklist-item-link ${classNames}`} onClick={() => setOpen(true)}>
        {title} <i class="fal fa-video ml-10"></i>
      </div>

      <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'modal-video-guide'}} center>
        <video controls src={src} style={{width: "100%", height: "100%", borderRadius: "5px"}} />
      </Modal>
    </React.Fragment>
  )
}

export default Video