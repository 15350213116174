import React , { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Oauth from "./Oauth";
import axios from "axios";

const NewClientAccount = ({organization, email, setStep, refetchData, setEmail}) => {
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);
  
    let errors_list = [];
  
    axios
      .post(`/api/registrations`, {
        user: {
          username: username,
          email: email,
          password: password, 
          is_client: true 
        },
      })
      .then(function (response) {
        console.log(response);
        if (response.data.success) {
          if(refetchData != null){
            refetchData();
          } else {
            history.push(`/portal/o/${response.data.organization_id}/projects`)
          }
        } else {
          errors_list = response.data.errors;
          errors_list.forEach((error) => {
            notice(error);
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        notice("An error occurred");
      })
      .then(function () {
        setDisabled(false);
      });
  };  

  return(
    <React.Fragment>
      <form id="registrations" onSubmit={handleSubmit}>
        <div class="field text-center mb-25">
          {organization != null && 
            <img src={organization.avatar} className="avatar-md mb-10" />
          }
          <h3 class="font-weight-600">Create an account</h3>
          <p className="opacity-7">One step away from amazing things</p>
        </div>

        {/* Email */}
        <div class="field">
          <div class="input-group" style={{display: "block"}}>
            <div id="emailStatus"></div>
            <div class="input-group addon">
              <span class="input-group-addon" id="basic-addon1"><i class="fa fa-envelope"></i></span>
              <input value={email} defaultValue={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" class="form-control form-control-dark" required="required" type="email" />
            </div>
          </div>
        </div>

        {/* Username */}
        <div class="field">
          <div class="input-group" style={{display: "block"}}>
            <div class="input-group addon">
              <span class="input-group-addon" id="basic-addon1"><i class="fa fa-user"></i></span>
              <input autoFocus value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Full Name" class="form-control form-control-dark" required="required" type="text"/>
            </div>
          </div>
        </div>

        {/* Submit */}
        <div class="field">
          <p id="signupError" className="color-red"></p>
          <input class="display-none" type="text" name="tour[false]" value="false" />
          <button disabled={disabled || (email.length == 0) || (username.length == 0)} type="submit" class="btn btn-primary btn-block default-padding">Create account</button>
        </div>
      </form>

      <div class="form-group text-center">
        <p className="font-12">
          <a class="color-blue" onClick={() => setStep("Check")}>Login instead</a>
        </p>
      </div>
    </React.Fragment>
  )
}

export default NewClientAccount