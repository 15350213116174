import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import FormFields from "./FormFields.js";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const TaskForms = ({refetchData, taskForm, chosenServiceForm, setQuestions, closeModal, serviceForm}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [selectedFieldType, setSelectedFieldType] = useState('');
  const [selectedService, setSelectedService] = useState(serviceForm != null ? (serviceForm.service != null ? serviceForm.service.token : null) : null);
  const [title, setTitle] = useState(serviceForm != null ? serviceForm.title : "");
  const [fields, setFields] = useState(chosenServiceForm != null ? chosenServiceForm.questions : (serviceForm != null ? serviceForm.questions : []));
  const availableFieldTypes = ['text', 'upload', 'checkbox', 'textarea', 'radio', "line"];
  const [services, setServices] = useState([]);
  const [organizationUsers, setOrganizationUsers] = useState([]);
  const [projectUsers, setProjectUsers] = useState([]);
  const [serviceFormUsers, setServiceFormUsers] = useState(serviceForm ? serviceForm.service_form_users : []);

  useEffect(() => {
    if(setQuestions != null ){
      setQuestions(fields);
    }
  },[fields]);

  useEffect(() => {
    fetchServices();
    fetchUsers();
  },[])

  const fetchServices = () => {
    axios.get(`/api/o/${match.params.organization_id}/services.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setServices(response.data.services); 
        setLoaded(true)
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      
    });
  }
  
  const fetchUsers = () => {
    axios.get(`/api/o/${match.params.organization_id}/people.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setOrganizationUsers(response.data.organization_users);
        setProjectUsers(response.data.project_users);
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      
    });
  }

  const handleFieldTypeChange = (fieldType, kind) => {
    // const fieldType = e.target.value;

    if (availableFieldTypes.includes(fieldType)) {
      const newField = {
        id: Date.now(),
        type: fieldType,
        label: fieldType,
        value: fieldType === 'checkbox' ? [] : '',
        options: [], 
        placeholder: null, 
        position: (fields.length + 1), 
        kind: kind, 
        isRequired: false, 
        maxCharacters: null
      };

      setFields((prevFields) => [...prevFields, newField]);
      setSelectedFieldType(fieldType);
    }
  };

  // update the field object
  const updateField = (fieldId, updatedProps) => {
    setFields((prevFields) => {
      return prevFields.map((field) => {
        if (field.id === fieldId) {
          return { ...field, ...updatedProps };
        }
        return field;
      });
    });
  };

  const updateFieldById = (id, key, newValue) => {
    notice(`${id} ${key} ${newValue}`);
    setFields(prevFields =>
      prevFields.map(field =>
        field.id === id ? { ...field, [key]: newValue } : field
      )
    );
  };
  
  const handleFieldChange = (fieldId, placeholder) => {
    updateField(fieldId, { placeholder });
  };

  const handleLabelChange = (fieldId, label) => {
    updateField(fieldId, { label });
  };

  const handleOptionsChange = (fieldId, options) => {
    updateField(fieldId, { options });
  };

  const handleValueChange = (fieldId, value) => {
    updateField(fieldId, { value });
  };

  const handleRequiredChange = (fieldId, isRequired) => {
    notice(isRequired);
    updateField(fieldId, {isRequired})
  }

  const removeField = (fieldId) => {
    setFields((prevFields) => prevFields.filter((field) => field.id !== fieldId));

    axios.delete(`/api/o/${match.params.organization_id}/questions/${fieldId}`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData();
      }
    });
  };

  const addOptionToField = (id, option) => {
    setFields((prevFields) => {
      const updatedFields = prevFields.map((field) => {
        if (field.id === id) {
          return {
            ...field,
            options: [...field.options, option]
          };
        }
        return field;
      });
      return updatedFields;
    });
  };

  const reorderFields = (startIndex, endIndex) => {
    notice("fields reordered")
    // setFields((prevFields) => {
    //   const result = Array.from(prevFields);
    //   const [removed] = result.splice(startIndex, 1);
    //   result.splice(endIndex, 0, removed);
  
    //   // Update position values
    //   result.forEach((field, index) => {
    //     field.position = index + 1;
    //   });
  
    //   return result;
    // });
  };


  const handleRadioCheckboxOptionChange = (fieldId, oldValue, newValue) => {
    setFields((prevFields) => {
      const updatedFields = prevFields.map((field) => {
        if (field.id === fieldId) {
          // Find the option by its oldValue and update it to the newValue
          const updatedOptions = field.options.map((option) => {
            if (option === oldValue) {
              return newValue;
            }
            return option;
          });
          return { ...field, options: updatedOptions };
        }
        return field;
      });
      return updatedFields;
    });
  };

  const handleRadioChange = (fieldId, value) => {
    setFields((prevFields) => {
      const updatedFields = prevFields.map((field) => {
        if (field.id === fieldId) {
          return { ...field, value: value };
        }
        return field;
      });
      return updatedFields;
    });
  };

  const removeOption = (fieldId, valueToRemove) => {
    setFields((prevFields) => {
      const updatedFields = prevFields.map((field) => {
        if (field.id === fieldId) {
          // Filter the options array to exclude the valueToRemove
          const updatedOptions = field.options.filter((option) => option !== valueToRemove);
          return { ...field, options: updatedOptions };
        }
        return field;
      });
      return updatedFields;
    });
  };
  
  
  
  const handleValueAdd = (fieldId, value) => {
    setFields((prevFields) => {
      return prevFields.map((field) => {
        if (field.id === fieldId) {
          const updatedValue = field.value.includes(value)
            ? field.value.filter((val) => val !== value)
            : [...field.value, value];
          return { ...field, value: updatedValue };
        }
        return field;
      });
    });
  };

  const handleOptionChange = (e) => {
    if(e.target.value === ""){
      setSelectedService(null);
    } else {
      setSelectedService(e.target.value);
    }
  };

  const services_list = services.map(service => {
    return(
      <option value={service.token}>{service.title}</option>
    )
  });

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/o/${match.params.organization_id}/services/${selectedService}/service_forms`, {
      title: title,
      fields: fields,
      service_form_users: serviceFormUsers
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData();
        closeModal();
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const handleUpdate = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.put(`/api/o/${match.params.organization_id}/services/${selectedService}/service_forms/${serviceForm.token}`, {
      title: title,
      fields: fields, 
      service_form_users: serviceFormUsers,
      service_form_id: serviceForm.token
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData();
        closeModal();
        notice("updated successfully")
      }
    })
    .catch(function(error){
      console.log("error:", error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const toggleServiceFormUser = (user) => {
    if (serviceFormUsers.some(u => u.user_id === user.user_id)) {
      // If user is already in the array, remove them
      setServiceFormUsers(serviceFormUsers.filter(u => u.user_id !== user.user_id));
    } else {
      // If user is not in the array, add them
      setServiceFormUsers([...serviceFormUsers, user]);
    }
  }

  const uniqueOrganizationUsers = organizationUsers.filter((user, index, self) =>
    index === self.findIndex(u => u.user_id === user.user_id)
  );

  const organization_users_list = uniqueOrganizationUsers.map(organization_user => {
    return (
      <li key={organization_user.user_id} onClick={() => toggleServiceFormUser(organization_user)}>
        <a className="color-1">
          <img src={organization_user.avatar} className="avatar-small mr-8" alt="avatar" />{organization_user.username}

          {serviceFormUsers.some(user => user.user_id === organization_user.user_id) && 
            <i class="far fa-check ml-15 color-blue"></i>
          }
        </a>
      </li>
    );
  });

  const uniqueProjectUsers = projectUsers.filter((user, index, self) =>
    index === self.findIndex(u => u.user_id === user.user_id)
  );
  const project_users_list = uniqueProjectUsers.map(project_user => {
    return (
      <li key={project_user.user_id} onClick={() => toggleServiceFormUser(project_user)}>
        <a className="color-1">
          <img src={project_user.avatar} className="avatar-small mr-8" alt="avatar" />{project_user.username}
          
          {serviceFormUsers.some(user => user.user_id === project_user.user_id) && 
            <i class="far fa-check ml-15 color-blue"></i>
          }
        </a>
      </li>
    );
  });

  const service_form_users_list = serviceFormUsers.map(service_form_user => {
    return(
     <li>
        <img src={service_form_user.avatar} className="avatar mr-8" />
     </li> 
    )
  })

  return(
    <React.Fragment>
      {!taskForm && 
        <div className="row">
          <div className="field row">
            <div className="col-12 col-md-6 pr-15">
              <label>Title</label>
              <input className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} required={true} placeholder="Name of the task"/>
            </div>

            <div className="col-12 col-md-6">
              <label>Service</label>
              <select id="options-select" value={selectedService} onChange={handleOptionChange} disabled={serviceForm != null}>
                <option value={""}>{loaded ? "Universal" : "Loading..."}</option>
                {services_list}
              </select>
              <p className="font-12 opacity-6 mt-5">Universal means it will be available on all taskboards.</p>
            </div>
          </div>

          <div className="field">
            <label>Task</label>
            <input disabled className="form-control  opacity-6" placeholder="Required field"/>
          </div>

          <div className="field">
            <label>Description</label>
            <textarea disabled className="form-control  opacity-6"rows="5">Required field</textarea>
          </div>

          <div className="field row">
            <div className="col-12 col-md-12">
              <label htmlFor="priority">Priority:</label>
              <select disabled className="form-control opacity-6">
                <option value="">Required field</option>
                <option value="High">High</option>
                <option value="Medium">Medium</option>
                <option value="Low">Low</option>
              </select>
            </div>
          </div>
        </div>
      } 
        {!taskForm && 
          <div className="field">
            <div className="field">
              <div>
                <label>Assign members (It'll auto assign tasks to these people)</label>
              </div>

              <div className="display-flex list-unstyled mt-5">
                {service_form_users_list}
                <div class="btn-group">
                  <a type="button" class="btn btn-small background-hover color-1 border-all dropdown-toggle" data-toggle="dropdown">
                    <i class="far fa-plus"></i>
                  </a>
                  <ul class="dropdown-menu animated fadeInUp" role="menu">
                    {organization_users_list}
                    <li class="divider"></li>
                    {project_users_list}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        }

        <div className="field">
          <FormFields fields={fields} setFields={setFields} handleFieldChange={handleFieldChange} handleLabelChange={handleLabelChange} handleOptionsChange={handleOptionsChange} removeField={removeField} reorderFields={reorderFields} addOptionToField={addOptionToField} handleRadioChange={handleRadioChange} handleValueAdd={handleValueAdd} taskForm={taskForm} handleValueChange={handleValueChange} removeOption={removeOption} handleRadioCheckboxOptionChange={handleRadioCheckboxOptionChange} handleRequiredChange={handleRequiredChange} updateFieldById={updateFieldById}/>
        </div>

        {!taskForm && 
          <div className="row">
            <div className="field">
              <div class="btn-group dropup">
                <button type="button" class="btn btn-primary-outline btn-small dropdown-toggle dark-color-black" data-toggle="dropdown">
                <i class="far fa-plus mr-5"></i> Add questions
                </button>

                <ul class="dropdown-menu animated fadeInUp" role="menu">
                  <li><a onClick={() => handleFieldTypeChange("text", "text")}><i class="far fa-horizontal-rule mr-5"></i> Text</a></li>
                  <li><a onClick={() => handleFieldTypeChange("textarea", "")}><i class="far fa-grip-lines mr-5"></i> Textarea</a></li>
                  <li><a onClick={() => handleFieldTypeChange("text", "link")}><i class="far fa-link mr-5"></i> Link</a></li>
                  <li><a onClick={() => handleFieldTypeChange("text", "date")}><i class="far fa-calendar mr-5"></i> Date</a></li>
                  <li><a onClick={() => handleFieldTypeChange("upload", "")}><i class="far fa-upload mr-5"></i> Upload</a></li>
                  <li><a onClick={() => handleFieldTypeChange("checkbox", "")}><i class="far fa-check-square mr-5"></i> Checkbox(multiple)</a></li>
                  <li><a onClick={() => handleFieldTypeChange("line", "")}><i class="far fa-horizontal-rule mr-5"></i> Line</a></li>
                </ul>
              </div>
            </div>

            <div className="field text-right">
              <button disabled={disabled} onClick={serviceForm != null ? handleUpdate : handleSubmit} className="btn btn-primary">
                {serviceForm != null ? "Update" : "Create"}
              </button>
            </div>
          </div>
        }
    </React.Fragment>
  )
}

export default TaskForms