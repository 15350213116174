import React, { useState } from 'react';
import Stripe from "./Stripe.js";
import TermsAndConditions from "./TermsAndConditions.js";
import BankInfo from "./BankInfo.js";
import { Modal } from 'react-responsive-modal';

const CheckoutSettings = ({organization, refetchData}) => {
  const [open, setOpen] = useState(false);
  
  return(
    <React.Fragment>
      <Stripe />
      
      <div className={`border-all default-padding border-radius mt-15 background-3`}>
        <div className="row align-items-center">
          <div className="col-12 col-md-8">
            <h5 className="font-weight-600"><i class="far fa-university-s mr-5"></i>Wire transfer instructions</h5>
            <p>Add your wire transfer instructions here for your invoices.</p>
          </div>

          <div className="col-12 col-md-4 text-right">
            <a onClick={() => setOpen(true)} className="btn btn-primary">
              Add wire instructions
            </a>

            <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
              <BankInfo organization={organization} refetchData={refetchData} closeModal={() => setOpen(false)}/>
            </Modal>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CheckoutSettings