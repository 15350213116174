import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import Toggle from 'react-toggle';

const StripeAccount = ({stripeAccount, refetchData}) => {
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false);
  const [automaticTax, setAutomaticTax] = useState(stripeAccount.automatic_tax);
  const [taxCollection, setTaxCollection] = useState(stripeAccount.tax_collection);
  const [billingAddressCollection, setBillingAddressCollection] = useState(stripeAccount.billing_address_collection);

  const handleDelete = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.delete(`/api/o/${match.params.organization_id}/stripe_accounts/${stripeAccount.token}`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        notice("Removed successfully");
        refetchData();
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const handleTaxToggle = () => {
    const updatedTaxStatus = !automaticTax;
    setAutomaticTax(updatedTaxStatus);

    // Here, you can send a request to your server to update the Stripe account with the new tax status
    axios.post(`/api/o/${match.params.organization_id}/stripe_accounts/${stripeAccount.token}/automatic_tax`, {
      automatic_tax: updatedTaxStatus
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData();
        // You may want to refetch data here if needed
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured while updating tax setting")
    });
  }

  const handleTaxCollectionToggle = (e) => {
    const updatedTaxCollectionStatus = !taxCollection;
    setTaxCollection(updatedTaxCollectionStatus);

    // Here, you can send a request to your server to update the Stripe account with the new tax status
    axios.post(`/api/o/${match.params.organization_id}/stripe_accounts/${stripeAccount.token}/tax_collection`, {
      tax_collection: updatedTaxCollectionStatus
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData();
        // You may want to refetch data here if needed
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured while updating tax setting")
    });
  }

  const handleBillingAddressCollection = (e) => {
    const updatedToggle = !billingAddressCollection;
    setBillingAddressCollection(updatedToggle);

    // Here, you can send a request to your server to update the Stripe account with the new tax status
    axios.post(`/api/o/${match.params.organization_id}/stripe_accounts/${stripeAccount.token}/billing_address_collection`, {
      billing_address_collection: updatedToggle
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData();
        // You may want to refetch data here if needed
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured while updating tax setting")
    });
  }

  return(
    <React.Fragment>
      <div className="row align-items-center animated fadeIn">
        <div className="col-12 col-md-8">
          <h5 className="font-weight-600"><i class="fas fa-percentage mr-5"></i>Automatic taxes</h5>
          <p>Taxes will be automatically calculated based on the address of payer. Please make sure taxes are properly setup on Stripe.</p>
        </div>

        <div className="col-12 col-md-4 text-right">
          <Toggle checked={automaticTax} onChange={handleTaxToggle}/>
        </div>
      </div>

      <div className="row align-items-center mt-30 border-top mt-15 pt-15 animated fadeIn">
        <div className="col-12 col-md-8">
          <h5 className="font-weight-600"><i class="fas fa-hand-holding-usd mr-5"></i>Tax ID Collection</h5>
          <p>
            With tax ID collection enabled, Checkout shows and hides the tax ID collection form depending on your customer's location.
          </p>
        </div>

        <div className="col-12 col-md-4 text-right">
          <Toggle checked={taxCollection} onChange={handleTaxCollectionToggle}/>
        </div>
      </div>

      <div className="row align-items-center mt-30 border-top mt-15 pt-15 animated fadeIn">
        <div className="col-12 col-md-8">
          <h5 className="font-weight-600"><i class="fas fa-location mr-5"></i>Require address</h5>
          <p>
            We automatically determine if we should collect the address based on their location, but you can make it a requirement too.
          </p>
        </div>

        <div className="col-12 col-md-4 text-right">
          <Toggle checked={billingAddressCollection} onChange={handleBillingAddressCollection}/>
        </div>
      </div>

      <div className="row align-items-center mt-30 border-top mt-15 pt-15 animated fadeIn">
        <div className="col-12 col-md-8">
          <h5 className="font-weight-600"><i class="fab fa-stripe-s mr-5"></i>Remove Stripe integration</h5>
          <p>This will remove your Stripe integration and all services.</p>
        </div>

        <div className="col-12 col-md-4 text-right">
          <button disabled={disabled} onClick={handleDelete} className="btn btn-danger-outline">Remove integration</button>
        </div>
      </div>
    </React.Fragment>
  )
}

export default StripeAccount