import React , { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import Toggle from 'react-toggle';

const TermsAndConditions = (props) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [url, setUrl] = useState("");
  const [require, setRequire] = useState(false);

  useEffect(() => {
    fetchOrganization();
  }, []);

  const fetchOrganization = () => {
    axios.get(`/api/o/${match.params.organization_id}.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setUrl(response.data.organization.terms);
        setRequire(response.data.organization.require_terms)
        setLoaded(true)
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const requireTermsForm = (boolean) => {
    axios.put(`/api/o/${match.params.organization_id}`, {
      require_terms: boolean
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        notice("Updated successfully. Checkout will now require agreement to your service agreement.")
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.put(`/api/o/${match.params.organization_id}`, {
      terms: url.length > 0 ? url : null
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        notice("Updated successfully");
        fetchOrganization();
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }


  return(
    loaded && 
    <React.Fragment>
      <div className="background-3 border-all border-radius default-padding">
        <h4 className="font-weight-600">Terms of Service setup</h4>
        <p className="opacity-7 text-12" style={{marginTop: "-5px"}}>Require terms of service agreement when your client checks out to legally protect yourself</p>

        <div className="field border-bottom default-padding row align-items-center" style={{marginLeft: "-15px", marginRight: "-15px"}}>
          <div className="col-12 col-md-8">
            <h5 className="font-weight-600"><i class="far fa-file-contract mr-5"></i>Link to service agreement</h5>
            <p>Provide a link to your service agreement. Leave empty if you don't require it.</p>
          </div>

          <div className="col-12 col-md-4 text-right">
            <form onSubmit={handleSubmit}>
              <div class="input-group">
                <input defaultValue={url} onChange={(e) => setUrl(e.target.value)} type="url" class="form-control" placeholder="https://example.com/terms-and-conditions"/>
                <span class="input-group-btn">
                  <button class="btn btn-primary" type="submit" disabled={disabled}>Save</button>
                </span>
              </div>
            </form>
          </div>
        </div>

        {/* Toggle */}
        <div className="field default-padding row  align-items-center" style={{marginLeft: "-15px", marginRight: "-15px"}}>
          <div className="col-12 col-md-8">
            <h5 className="font-weight-600"><i class="fas fa-sign mr-5"></i>Require signature</h5>
            <p>Require viewing and a signature of the terms of service before proceeding to payment.</p>
          </div>

          <div className="col-12 col-md-4 text-right">
            <Toggle checked={require} onChange={() => {setRequire(!require); requireTermsForm(!require)}} />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TermsAndConditions