import React, { useState } from 'react';

function CurrencyPicker({ selectedCurrency, setSelectedCurrency, edit }) {
  const handleCurrencyChange = (currency) => {
    setSelectedCurrency(currency);
  };

  const currencyList = [
    { code: "usd", name: "US Dollar" },
    { code: "aed", name: "UAE Dirham" },
    { code: "afn", name: "Afghanistan Afghani" },
    { code: "all", name: "Albanian Lek" },
    { code: "amd", name: "Armenian Dram" },
    { code: "ang", name: "Netherlands Antillian Guilder" },
    { code: "aoa", name: "Kwanza" },
    { code: "ars", name: "Argentine Peso" },
    { code: "aud", name: "Australian Dollar" },
    { code: "awg", name: "Aruban Guilder" },
    { code: "azn", name: "Azerbaijan Manat" },
    { code: "bam", name: "Convertible Marks" },
    { code: "bbd", name: "Barbados Dollar" },
    { code: "bdt", name: "Bangladeshi Taka" },
    { code: "bgn", name: "Bulgarian Lev" },
    { code: "bif", name: "Burundi Franc" },
    { code: "bmd", name: "Bermuda Dollar" },
    { code: "bnd", name: "Brunei Dollar" },
    { code: "bob", name: "Boliviano" },
    { code: "brl", name: "Brazil Real" },
    { code: "bsd", name: "Bahamian Dollar" },
    { code: "bwp", name: "Pula" },
    { code: "bzd", name: "Belize Dollar" },
    { code: "cad", name: "Canadian Dollar" },
    { code: "cdf", name: "Franc Congolais" },
    { code: "chf", name: "Swiss Franc" },
    { code: "clp", name: "Chilean Peso" },
    { code: "cny", name: "Yuan Renminbi" },
    { code: "cop", name: "Colombian Peso" },
    { code: "crc", name: "Costa Rican Colon" },
    { code: "cve", name: "Cape Verde Escudo" },
    { code: "czk", name: "Czech Koruna" },
    { code: "djf", name: "Djibouti Franc" },
    { code: "dkk", name: "Danish Krone" },
    { code: "dop", name: "Dominican Peso" },
    { code: "dzd", name: "Algerian Dinar" },
    { code: "egp", name: "Egyptian Pound" },
    { code: "etb", name: "Ethiopian Birr" },
    { code: "eur", name: "Euro" },
    { code: "fjd", name: "Fiji Dollar" },
    { code: "fkp", name: "Falkland Islands Pound" },
    { code: "gbp", name: "Pound Sterling" },
    { code: "gel", name: "Lari" },
    { code: "gip", name: "Gibraltar Pound" },
    { code: "gmd", name: "Dalasi" },
    { code: "gnf", name: "Guinea Franc" },
    { code: "gtq", name: "Guatemalan Quetzal" },
    { code: "gyd", name: "Guyana Dollar" },
    { code: "hkd", name: "Hong Kong Dollar" },
    { code: "hnl", name: "Honduran Lempira" },
    { code: "hrk", name: "Croatian Kuna" },
    { code: "htg", name: "Haiti Gourde" },
    { code: "huf", name: "Forint" },
    { code: "idr", name: "Indonesian Rupiah" },
    { code: "ils", name: "New Israeli Sheqel" },
    { code: "inr", name: "Indian Rupee" },
    { code: "isk", name: "Iceland Krona" },
    { code: "jmd", name: "Jamaican Dollar" },
    { code: "jpy", name: "Japanese Yen" },
    { code: "kes", name: "Kenyan Shilling" },
    { code: "kgs", name: "Kyrgyzstan Som" },
    { code: "khr", name: "Cambodian Riel" },
    { code: "kmf", name: "Comorian Franc" },
    { code: "krw", name: "South Korean Won" },
    { code: "kwd", name: "Kuwaiti Dinar" },
    { code: "kzt", name: "Kazakhstan Tenge" },
    { code: "lak", name: "Lao Kip" },
    { code: "lbp", name: "Lebanese Pound" },
    { code: "lkr", name: "Sri Lanka Rupee" },
    { code: "lrd", name: "Liberian Dollar" },
    { code: "lsl", name: "Loti" },
    { code: "mad", name: "Moroccan Dirham" },
    { code: "mdl", name: "Moldovan Leu" },
    { code: "mga", name: "Malagasy Ariary" },
    { code: "mkd", name: "Macedonian Denar" },
    { code: "mmk", name: "Kyat" },
    { code: "mnt", name: "Tugrik" },
    { code: "mop", name: "Pataca" },
    { code: "mru", name: "Ouguiya" },
    { code: "mur", name: "Mauritius Rupee" },
    { code: "mvr", name: "Maldives Rufiyaa" },
    { code: "mwk", name: "Malawi Kwacha" },
    { code: "mxn", name: "Mexican Peso" },
    { code: "myr", name: "Malaysian Ringgit" },
    { code: "mzn", name: "Mozambique Metical" },
    { code: "nad", name: "Namibia Dollar" },
    { code: "ngn", name: "Nigerian Naira" },
    { code: "nio", name: "Cordoba Oro" },
    { code: "nok", name: "Norwegian Krone" },
    { code: "npr", name: "Nepalese Rupee" },
    { code: "nzd", name: "New Zealand Dollar" },
    { code: "omr", name: "Rial Omani" },
    { code: "pab", name: "Balboa" },
    { code: "pen", name: "Nuevo Sol" },
    { code: "pgk", name: "Kina" },
    { code: "php", name: "Philippine Peso" },
    { code: "pkr", name: "Pakistan Rupee" },
    { code: "pln", name: "Poland Zloty" },
    { code: "pyg", name: "Paraguay Guarani" },
    { code: "qar", name: "Qatari Rial" },
    { code: "ron", name: "Romanian Leu" },
    { code: "rsd", name: "Serbian Dinar" },
    { code: "rub", name: "Russian Ruble" },
    { code: "rwf", name: "Rwanda Franc" },
    { code: "sar", name: "Saudi Riyal" },
    { code: "sbd", name: "Solomon Islands Dollar" },
    { code: "scr", name: "Seychelles Rupee" },
    { code: "sdg", name: "Sudanese Pound" },
    { code: "sek", name: "Swedish Krona" },
    { code: "sgd", name: "Singapore Dollar" },
    { code: "shp", name: "Saint Helena Pound" },
    { code: "sll", name: "Sierra Leonean Leone" },
    { code: "sos", name: "Somali Shilling" },
    { code: "srd", name: "Surinam Dollar" },
    { code: "ssp", name: "South Sudanese Pound" },
    { code: "stn", name: "Dobra" },
    { code: "svc", name: "El Salvador Colon" },
    { code: "syp", name: "Syrian Pound" },
    { code: "szl", name: "Lilangeni" },
    { code: "thb", name: "Baht" },
    { code: "tjs", name: "Somoni" },
    { code: "tmt", name: "Turkmenistan New Manat" },
    { code: "tnd", name: "Tunisian Dinar" },
    { code: "top", name: "Pa’anga" },
    { code: "try", name: "Turkish Lira" },
    { code: "ttd", name: "Trinidad and Tobago Dollar" },
    { code: "twd", name: "New Taiwan Dollar" },
    { code: "tzs", name: "Tanzanian Shilling" },
    { code: "uah", name: "Hryvnia" },
    { code: "ugx", name: "Uganda Shilling" },
    { code: "usd", name: "US Dollar" },
    { code: "uyu", name: "Peso Uruguayo" },
    { code: "uzs", name: "Uzbekistan Sum" },
    { code: "vef", name: "Bolívar Soberano" },
    { code: "vnd", name: "Dong" },
    { code: "vuv", name: "Vatu" },
    { code: "wst", name: "Tala" },
    { code: "xaf", name: "CFA Franc BEAC" },
    { code: "xcd", name: "East Caribbean Dollar" },
    { code: "xof", name: "CFA Franc BCEAO" },
    { code: "xpf", name: "CFP Franc" },
    { code: "yer", name: "Yemeni Rial" },
    { code: "zar", name: "Rand" },
    { code: "zmw", name: "Zambian Kwacha" },
  ];

  return (
    <div className="btn-group input-group-addon">
      <a type="button" className="color-1 dropdown-toggle caps" data-toggle="dropdown">
        {selectedCurrency || "Currency"} <span className="caret"></span>
      </a>

      <ul className="dropdown-menu" role="menu" style={{height: "250px", overflow: "auto"}}>
        {currencyList.map(currency => (
          <li key={currency.code}>
            <a className="caps" href="#" onClick={() => handleCurrencyChange(currency.code)}>
              {currency.code} - {currency.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CurrencyPicker;
