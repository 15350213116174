import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { useTranslation } from 'react-i18next';

const DeletedEmailedTasks = ({refetchData, closeModal}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [emailedTasks, setEmailedTasks] = useState([]);


  useEffect(() => {
    fetchEmailedTasks();
  }, []);

  const fetchEmailedTasks = () => {
    axios.get(`/api/o/${match.params.organization_id}/deleted_emailed_tasks.json`)
    .then(function(response){
    //   console.log(response);
      if(response.data.success){
        setEmailedTasks(response.data.emailed_tasks);
        setLoaded(true);
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const emailed_tasks_list = emailedTasks.map(emailedTask => {
    const restore = () => {
      setDisabled(true);

      axios.post(`/api/o/${match.params.organization_id}/emailed_tasks/${emailedTask.token}/restore`)
      .then(function(response){
        // console.log(response);
        if(response.data.success){
          fetchEmailedTasks();
          refetchData();
        } else {
          response.data.errors.forEach((error) => {
            notice(error);
          });
        }
      })
      .catch(function(error){
        console.log(error)
        notice("An error occured");
        reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
      })
      .then(function () {
        setDisabled(false);
      });
    }

    return(
      <tr className="border-bottom">
        <td className="deleted-emailed-tasks-subject">
          {emailedTask.subject}
        </td>
        <td>{emailedTask.created_at}</td>
        <td className="text-right">
          <a disabled={disabled} onClick={() => restore()} className="btn background-hover color-1 btn-small"><i class="fal fa-undo mr-8"></i>Restore</a>
        </td>
      </tr>
    )
  })

  return(
    <React.Fragment>
      {loaded ? (
        <table class="table">
          <tbody>
            {emailed_tasks_list}
          </tbody>
        </table>
      ):(
        <div className="text-center large-padding">
          <i class="fal fa-spinner fa-spin"></i> Loading...
        </div>
      )}
    </React.Fragment>
  )
}

export default DeletedEmailedTasks;