import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import {UserContext} from "../../Shared/UserContext.js";
import DashboardSkeleton from "./DashboardSkeleton.js";
import Analytics from "./Analytics.js";
import KanbanBoard from "../../Dashboard/KanbanBoard.js";
import ReactTooltip from 'react-tooltip';
import People from "../../Users/People.js";

const Dashboard = ({projectAuthorizations}) => {
  const match = useRouteMatch();
  const currentUser = useContext(UserContext);

  return(
    <React.Fragment>
      <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
      <title>Dashboard</title>
      <div className="container-fluid pb-0" style={{paddingTop: "15px"}}>
        {/* <div className="row">
          <ul className="bootstrap-nav background-3 default-padding border-radius border-all no-margin">
            <li class="bootstrap-nav-item">
              <NavLink aria-current="page" className="bootstrap-nav-tab background-hover" exact activeClassName="active-tab" to={`/o/${match.params.organization_id}/dashboard`}>Tasks</NavLink>
            </li>

            {projectAuthorizations.organization_user &&
              <>
                <li class="bootstrap-nav-item">
                  <NavLink aria-current="page" className="bootstrap-nav-tab background-hover" activeClassName="active-tab" to={`/o/${match.params.organization_id}/dashboard/analytics`}>Analytics</NavLink>
                </li>

                <li class="bootstrap-nav-item">
                  <NavLink aria-current="page" className="bootstrap-nav-tab background-hover" activeClassName="active-tab" to={`/o/${match.params.organization_id}/dashboard/people`}>People</NavLink>
                </li>
              </>
            }
          </ul>
        </div> */}
        
        <Switch>
          <Route exact path={`/o/:organization_id/dashboard`} render={() => <KanbanBoard projectAuthorizations={projectAuthorizations} fetchPath={"dashboard_columns"}/> } />
          <Route exact path={`/o/:organization_id/dashboard/my-tasks`} render={() => <KanbanBoard projectAuthorizations={projectAuthorizations} fetchPath={"my_tasks"}/> } />
          <Route exact path={`/o/:organization_id/dashboard/analytics`} render={() => <Analytics projectAuthorizations={projectAuthorizations}/> } />
          <Route exact path={`/o/:organization_id/dashboard/people`} render={() => <People projectAuthorizations={projectAuthorizations}/> } />
        </Switch>
      </div>
    </React.Fragment>
  )
}

export default Dashboard