import React , { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom'
import {ProjectContext} from "../../Projects/ProjectContext.js";
import axios from "axios"; 
import ReactTooltip from 'react-tooltip';

const OrganizationNotification = (props) => {
  const {fetchChatroomsUnread} = useContext(ProjectContext);
  const markNotificationRead = () => {
    updateNotificationReadAt(props.notification.id, true);

    axios.post(`/api/notifications/mark_read`, {
      notification_id: props.notification.id
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        // props.refetchData();
        checkLogin();
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      // notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const markNotificationUnread = () => {
    updateNotificationReadAt(props.notification.id, null);
    
    axios.post(`/api/notifications/mark_unread`, {
      notification_id: props.notification.id
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        // props.refetchData();
        checkLogin();
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      updateNotificationReadAt(props.notification.id, true);
      notice("Notifications marked as read before August 22, 2024 cannot be marked as unread.")
    })
    .then(function () {
      // always executed
    });
  }

  const updateNotificationReadAt = (id, boolean) => {
    props.setNotifications((prevNotifications) =>
      prevNotifications.map((notification) =>
        notification.id === id
          ? { ...notification, read_at: boolean }
          : notification
      )
    );
  };

  const deleteNotification = () => {
    props.setNotifications(props.notifications.filter(notification => notification.id !== props.notification.id));
    axios.delete(`/api/notifications/${props.notification.id}`, {
      notification_id: props.notification.id
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        // props.refetchData();
        checkLogin();
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const handleIconClick = (event, action) => {
    event.preventDefault();
    event.stopPropagation();
    action();
  };

  return(
    <>
    <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
      <Link onClick={() => {props.notification.read_at == null && markNotificationRead();}} to={props.notification.link} className={`notif block color-black-always row mb-5 hover-parent-opacity`} style={{padding: "10px 5px"}}>
        <div className="col-1 mr-15">
          <img src={props.notification.avatar} className="avatar" />
        </div>
        <div className="col-10 display-column">
          {/* Task */}
          <div>
            {props.notification.title}
            {props.notification.read_at === null && 
              <span className="notification-dot-small"></span>
            }
          </div>
          {props.notification.name != null && 
            <label className="font-12">{props.notification.name}</label>
          }
          <div className="font-12 opacity-6 div-image-width" style={{wordBreak: "break-word"}} dangerouslySetInnerHTML={{__html: props.notification.message}} />

          <div className="display-flex justify-content-between align-items-center opacity-6">
            <div className="font-10">
              {props.notification.sender.username} • {props.notification.created_at}
            </div>

            <div className="hover-child-opacity">
              {props.notification.read_at != null ? (
                <i class="fal fa-bell-on btn btn-small disable-dropdown-close mr-5 btn-hover-white" data-tip="Mark as unread" onClick={(e) => handleIconClick(e, markNotificationUnread)}></i>
              ):(
                <i class="fal fa-bell-slash btn btn-small disable-dropdown-close mr-5 btn-hover-white" data-tip="Mark read" onClick={(e) => handleIconClick(e, markNotificationRead)}></i>
              )}
              <i class="fal fa-trash btn btn-small disable-dropdown-close btn-hover-white" data-tip="Archive" onClick={(e) => handleIconClick(e, deleteNotification)}></i>
            </div>
          </div>
        </div>   
      </Link> 
    </>
  )
}

export default OrganizationNotification