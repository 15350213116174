import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import Service from "./Service.js";
import Form from "./Form.js"
import { Modal } from 'react-responsive-modal';
import Stripe from "../Stripe/Stripe.js";
import {UserContext} from "../../Shared/UserContext.js";
import VideoGuide from "../../Shared/VideoGuide.js";
import DashboardSkeleton from "../Seller/DashboardSkeleton.js";
import KeyBinding from 'react-keybinding-component';
import {OrganizationContext} from "../../Shared/OrganizationContext.js";

const Services = () => {
  const history = useHistory();
  const organizationAuthorizations = useContext(OrganizationContext);
  const currentUser = useContext(UserContext);
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [services, setServices] = useState([]);
  const [stripeAccount, setStripeAccount] = useState(null);
  const [paypalAccount, setPaypalAccount] = useState(organizationAuthorizations.organization.paypal_account);
  
  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = () => {
    axios.get(`/api/o/${match.params.organization_id}/services.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setServices(response.data.services); 
        setStripeAccount(response.data.stripe_account)
        setLoaded(true)
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
    });
  }

  const services_list = services.map(service => {
    return (
      <Service services={services} service={service} refetchData={fetchServices}/>
    )
  })

  return(
    loaded ? (

      <React.Fragment>
        {/* <KeyBinding onKey={ (e) => { e.keyCode === 67 && setOpen(true) } } /> */}
        <title>Services</title>

        <div className="row no-margin">
          <div className="header-page">
            <div>

            </div>

            <div>
              <a onClick={() => setOpen(true)} className="btn btn-primary" disabled={!(stripeAccount || paypalAccount)}>
                <i className="fas fa-plus mr-5"></i>Add service
              </a>  
            </div>
          </div>

          {/* CTA */}
          <div className="row no-margin">
            <div className="col-12 text-right">
              {/* <a className="btn btn-primary-outline mr-10">Add coupons</a> */}
              {/* <a className="btn btn-primary-outline mr-10">View service listing</a> */}

              {stripeAccount != null && 
                <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
                  <Form closeModal={() => setOpen(false)} refetchData={fetchServices} currency={stripeAccount.stripe_account_data.default_currency} addon={false}/>
                </Modal>
              }

              {paypalAccount != null && 
                <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
                  <Form closeModal={() => setOpen(false)} refetchData={fetchServices} currency={"usd"} addon={false}/>
                </Modal>
              }
            </div>
          </div>

          {/* Services */}
          <div className="row services-list">
            <div className="col-12 no-padding">
              {(stripeAccount == null && paypalAccount == null) ?(
                <div className="stripe-flex-container">
                  <Stripe title="Create subscriptions and services" description="Connect your Stripe account to get started" refetchData={fetchServices}/>
                </div>
              ):(
                <React.Fragment>
                  {services.length > 0 ? (
                    <table class="table table-bordered table-responsive background-3">
                      <thead className="background-3">
                        <tr>
                          <th className="service-item-td" scope="col">Catalog</th>
                          <th className="service-item-td" scope="col">Service</th>
                          <th className="service-item-td" scope="col">Pricing</th>
                          <th className="service-item-td" scope="col">Upgrades</th>
                          <th className="service-item-td" scope="col">Add-ons</th>
                          <th className="service-item-td" scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {services_list}
                      </tbody>
                    </table>
                  ):(
                    <div className="information-wrapper">
                      <lottie-player class="information-lottie" autoplay loop mode="normal" src="https://assets9.lottiefiles.com/packages/lf20_ssrGyJ7BiX.json"></lottie-player>
                      Add services with subscriptions or one-time payment options
                      <VideoGuide src="https://www.loom.com/embed/212b35c9bbe34e54a66cc9df43f5b72a?sid=62df94bc-7b92-4bac-bf8c-e89a599caf16"/>
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    ):(
      <DashboardSkeleton />
    )
  )
}

export default Services