import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import EmailedTask from "./EmailedTask.js";
import Pusher from 'pusher-js';
import HowTo from "./HowTo.js";
import DeletedEmailedTasks from "./DeletedEmailedTasks.js";

const EmailedTasks = ({}) => {
  // const organizationAuthorizations = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [emailedTasks, setEmailedTasks] = useState([]);
  const [openArchive, setOpenArchive] = useState(false);
  const [show, setShow] = useState(true);

  useEffect(() => { 
    setLoaded(false);
    fetchEmailedTasks();
  }, []);
  
  useEffect(() => {
    const pusher = new Pusher('a5c19937d10212cddb4e', {
      cluster: 'us2',
      authEndpoint: '/your-auth-endpoint'
    });

    const pusherChannel = pusher.subscribe(`emailed-tasks-${match.params.organization_id}`);
    pusherChannel.bind('event', (data) => {
      if (data.event === "fetchEmailedTasks") {
        fetchEmailedTasks();
      }
    });

    return () => {
      pusherChannel.unbind_all();
      pusherChannel.unsubscribe();
    };
  }, [match.params.organization_id]);

  useEffect(() => {
    fetchEmailedTasks();
  }, []);

  const fetchEmailedTasks = () => {
    axios.get(`/api/o/${match.params.organization_id}/emailed_tasks.json`)
    .then(function(response){
    //   console.log(response);
      if(response.data.success){
        setEmailedTasks(response.data.emailed_tasks);
        setLoaded(true);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const emailed_tasks_list = emailedTasks.map(emailed_task => {
    return(
      <EmailedTask emailed_task={emailed_task} refetchData={fetchEmailedTasks} />
    )
  })

  return(
    <React.Fragment> 
        <>
          <div className="display-flex align-items-center justify-content-between">
            <div class="dropdown show">
              <a class="btn color-1 btn-small background-hover opacity-6 opacity-10-hover margin-left-5px-negative" onClick={() => setShow(!show)}>
                Emailed requests
                <i class={`fal ${show ? "fa-angle-down" : "fa-angle-right"} ml-10`}></i>
              </a>
            </div>

            <div class="btn-group pull-right">
              <a type="button" class="btn btn-small color-1 background-hover dropdown-toggle opacity-6 opacity-10-hover" data-toggle="dropdown">
                <i class="fas fa-ellipsis-v"></i>
              </a>

              <ul class="dropdown-menu animated fadeInUp" role="menu">
                <li>
                  <a class="dropdown-item" onClick={() => setOpenArchive(true)}><i class="fal fa-archive mr-5"></i>Closed emailed requests</a>
                </li>
              </ul>
            </div>
          </div>
          
          {show && 
            <>
              {loaded ? (
                <>
                  {emailedTasks.length > 0 ? (
                    emailed_tasks_list
                  ):(
                    <div className="text-center default-padding opacity-4">
                      No emailed requests
                    </div>
                  )}
                </>
              ):(
                <div className="text-center default-padding opacity-4">
                  <i class="fal fa-spinner fa-spin mr-15"></i>
                  Loading emailed requests...
                </div>
              )}
            </>
          }

          <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
            <HowTo />
          </Modal>

          <Modal open={openArchive} onClose={() => setOpenArchive(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
            <DeletedEmailedTasks refetchData={fetchEmailedTasks} closeModal={() => setOpenArchive(false)}/>
          </Modal>
        </>
    </React.Fragment>
  )
}

export default EmailedTasks;