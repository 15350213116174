import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import ClientInfo from "./ClientInfo.js";
import { Modal } from 'react-responsive-modal';
import {UserContext} from "../Shared/UserContext.js";
import BankInfo from "../Daas/Stripe/BankInfo.js";
import Currency from "../Daas/Discounts/Currency.js";
import ReactTooltip from 'react-tooltip';
import Services from "./Services.js";
import {OrganizationContext} from "../Shared/OrganizationContext.js";
import { v4 as uuidv4 } from 'uuid';

const Form = ({project_id, refetchData, closeModal, invoice}) => {
  const organizationAuthorizations = useContext(OrganizationContext);
  const history = useHistory();
  const match = useRouteMatch();
  const currentUser = useContext(UserContext);
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [organization, setOrganization] = useState(null);
  const [services, setServices] = useState([]);
  const [projects, setProjects] = useState([]);
  const [invoiceItems, setInvoiceItems] = useState(invoice ? invoice.invoice_items : [{token: uuidv4(), amount: null, description: "", quantity: 1, interval: null, frequency: null}]);
  const [invoiceNumber, setInvoiceNumber] = useState(invoice ? invoice.invoice_number : null);
  const [selectedProject, setSelectedProject] = useState(invoice ? invoice.project.token : null);
  const [showTerms, setShowTerms] = useState(false);
  const [paymentTerms, setPaymentTerms] = useState(invoice?.payment_terms ?? "");
  const [totalDue, setTotalDue] = useState(0);
  const [tax, setTax] = useState(invoice?.tax ?? 0);
  const [openBankInfo, setOpenBankInfo] = useState(false);
  const [openClientInfo, setOpenClientInfo] = useState(false);
  const [clientAddress, setClientAddress] = useState(invoice ? invoice.address : "");
  const [clientEmail, setClientEmail] = useState(invoice ? invoice.bill_to : "");
  const [clientName, setClientName] = useState(invoice ? invoice.name : null);
  const [paymentMethod, setPaymentMethod] = useState(invoice ? invoice.payment_method : "wire");
  const [due, setDue] = useState(invoice ? invoice.due_raw : new Date(new Date().setDate(new Date().getDate() + 3)).toISOString().split('T')[0]);
  const [issued, setIssued] = useState(invoice ? invoice.issued_raw : new Date().toISOString().split('T')[0]);
  const [selectedCurrency, setSelectedCurrency] = useState(invoice? invoice.currency : 'usd');
  const [creditsForm, setCreditsForm] = useState(false);
  const [credits, setCredits] = useState(null);
  const [creditsName, setCreditsName] = useState(null);
  const [openServices, setOpenServices] = useState(false);
  const [selectedInterval, setSelectedInterval] = useState(null);

  useEffect(() => {
    fetchProjects();
    fetchOrganization();
  }, []);

  const fetchOrganization = () => {
    axios.get(`/api/o/${match.params.organization_id}.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setOrganization(response.data.organization);
        setLoaded(true);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  useEffect(() => {
    calculateTotalDue(); // Calculate total due whenever invoiceItems changes
  }, [invoiceItems, tax]);

  const fetchProjects = () => {
    axios.get(`/api/o/${match.params.organization_id}/projects.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setProjects(response.data.projects);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    const invoiceData = {
      invoice_number: invoiceNumber,
      due: due, 
      issued: issued,
      payment_terms: paymentTerms,
      invoice_items: invoiceItems,
      tax: tax,
      payment_method: paymentMethod,
      price: totalDue,
      bill_to: clientEmail,
      currency: selectedCurrency,
      name: clientName,
      address: clientAddress,
      credits_name: creditsName,
      credits: credits,
      credit_type: creditsForm
    };
    
    if (invoice) {
      axios.put(`/api/o/${match.params.organization_id}/projects/${project_id || selectedProject}/invoices/${invoice.token}`, invoiceData)
        .then(response => {
          // console.log('Invoice updated successfully', response.data);
          refetchData(); 
          closeModal();
          notice("Invoice updated")
        })
        .catch(error => {
          console.error('Error updating invoice', error);
        });
    } else {
      axios.post(`/api/o/${match.params.organization_id}/projects/${project_id || selectedProject}/invoices`, invoiceData)
        .then(response => {
          console.log('Invoice created successfully', response.data);
          refetchData(); 
          closeModal();
        })
        .catch(error => {
          console.error('Error creating invoice', error);
        });
    }
    
  }

  // const services_list = services.map(service => {
  //   return(
  //     <option value={service.token}>{service.title}</option>
  //   )
  // });

  const projects_list = projects.map(project => {
    return(
      <option value={project.token}>{project.title}</option>
    )
  });

  // Function to add a new invoice item
  const addInvoiceItem = () => {
    const newItem = {token: uuidv4(), description: '', quantity: 1, amount: null, interval: null, frequency: null };
    setInvoiceItems([...invoiceItems, newItem]);
  };

  // Function to remove an invoice item
  const removeInvoiceItem = (index) => {
    const newItems = invoiceItems.filter((_, i) => i !== index);
    setInvoiceItems(newItems);
  };

  // Function to handle change in invoice items
  const handleInvoiceItemChange = (index, field, value) => {
    const newItems = invoiceItems.map((item, i) => {
      if (i === index) {
        return { ...item, [field]: value };
      }
      return item;
    });
    setInvoiceItems(newItems);
  };

  const handleProjectChange = (event) => {
    setSelectedProject(event.target.value); // Step 2: Update selected project
  };

  // Function to calculate total due
  const calculateTotalDue = () => {
    let total = 0;
    invoiceItems.forEach(item => {
      total += item.quantity * item.amount;
    });
    // Add tax to the total
    total *= (1 + tax / 100);
    setTotalDue(total.toFixed(2)); // Round to two decimal places
  };


  return(
    loaded && 
    <React.Fragment>
      <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>    
      <form className="invoice-form-wrapper" onSubmit={handleSubmit}>
        <h2 className="mb-25 mt-0">INVOICE</h2>
        <div className="row">
          <div className="col-md-10 col-12 pr-15">
            <div className="row">
              <div className="col-4 pr-15">
                <label>Invoice Number</label>
                <input className="form-control" placeholder="1234" required="true" onChange={(e) => setInvoiceNumber(e.target.value)} defaultValue={invoiceNumber}/>
              </div>

              <div className="col-md-4 col-12 pr-15">
                <div className="field">
                  <label>Issue date</label>
                  <input className="form-control" type="date" onChange={(e) => setIssued(e.target.value)} defaultValue={issued}/>
                </div>
              </div>

              <div className="col-md-4 col-12">
                <div className="field">
                  <label>Due date</label>
                  <input className="form-control" type="date" onChange={(e) => setDue(e.target.value)} defaultValue={due}/>
                </div>
              </div>
            </div>

            <div className="row field">
              {project_id == null && 
                <div className="col-8 pr-15">
                  <label>Project</label>
                  {invoice ? (
                    <select className="form-control" onChange={handleProjectChange} value={selectedProject}>
                      <option>{invoice.project.title}</option>
                      {projects_list}
                    </select>
                  ):(
                  <select className="form-control" onChange={handleProjectChange} value={selectedProject}>
                    <option>Choose project</option>
                    {projects_list}
                  </select>
                  )}
                </div>   
              }

              <div className="col-4">
                <Currency setSelectedCurrency={setSelectedCurrency} selectedCurrency={selectedCurrency} edit={false}/>
              </div>           
            </div>
          </div>
          

          <div className="col-md-2 col-12">
            <label>Logo</label>
            <img className="invoice-form-logo" src={organization.logo} />
          </div>
        </div>

        <hr />

        {/* Organization info */}
        <div className="row" style={{paddingBottom: "25px"}}>
          <div className="col-6 pr-15">
            <label>Bill from</label>
            <div className="border-all background-3 default-padding border-radius height-100p">
              <div className="invoice-box-edit-wrapper">
                <label>
                  {organization.title}
                </label>

                <a className="font-12" onClick={() => setOpenBankInfo(true)}>
                  Edit
                </a>
              </div>

              <p className="opacity-7">
                {organization.address}
              </p>

              <p className="opacity-7">
                {currentUser.email}
              </p>
            </div>
          </div>

           {/* client info */}
          <div className="col-6">
            <label>Bill to (optional)</label>
            <div className="border-all background-3 default-padding border-radius height-100p">
              <div className="invoice-box-edit-wrapper">
                <label>
                  {clientName}
                </label>

                <a className="font-12" onClick={() => setOpenClientInfo(true)}>
                  Edit
                </a>
              </div>

              <p className="opacity-7">
                {clientAddress}
              </p>

              <p className="opacity-7">
                {clientEmail}
              </p>
            </div>
          </div>
        </div>
        
        <hr />

        {/* Items */}
        <div className="row mb-10">
          <div className="col-5">
            Item Description
          </div>

          <div className="col-1">
            Qty
          </div>

          <div className="col-4">
            Rate
          </div>

          <div className="col-2">
            Price
          </div>
        </div>

        {/* Invoice item */}
        <div className="row invoice-form-item mt-25">
          {invoiceItems.map((item, index) => (
            <div className="row invoice-form-item animated fadeInUp" key={index}>
              <div className="col-5 pr-15">
                <input 
                  className="form-control" 
                  placeholder="Description of item"
                  value={item.description}
                  onChange={(e) => handleInvoiceItemChange(index, 'description', e.target.value)}
                  required="true"
                />
              </div>

              <div className="col-1 pr-15">
                <input 
                  className="form-control" 
                  type="number" 
                  placeholder="0"
                  value={item.quantity}
                  onChange={(e) => handleInvoiceItemChange(index, 'quantity', e.target.value)}
                  required="true"
                />
              </div>

              <div className="col-4 pr-15">
                <div class="input-group">
                  <input 
                    className="form-control" 
                    type="number" 
                    placeholder="0.00"
                    value={item.amount}
                    onChange={(e) => handleInvoiceItemChange(index, 'amount', e.target.value)}
                    required="true"
                  />
                  <div class="input-group-btn dropup">
                    <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown">{item.interval ? `per ${item.interval}` : ""} <i className="fas fa-angle-up ml-5"></i></button>
                    <ul class="dropdown-menu pull-right animated fadeInUp">
                      <li><a onClick={() => handleInvoiceItemChange(index, 'interval', null)}>Flat</a></li>
                      <li><a onClick={() => handleInvoiceItemChange(index, 'interval', "day")}>Per Day</a></li>
                      <li><a onClick={() => handleInvoiceItemChange(index, 'interval', "week")}>Per Week</a></li>
                      <li><a onClick={() => handleInvoiceItemChange(index, 'interval', "month")}>Per Month</a></li>
                      <li><a onClick={() => handleInvoiceItemChange(index, 'interval', "year")}>Per Year</a></li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-2" style={{display: "flex", alignItems: "center"}}>
                <input required="true" value={item.amount * item.quantity} className="form-control" disabled={true} placeholder="0.00"/>
                <i className="fas fa-times cursor-pointer default-padding background-hover color-red opacity-4 opacity-10-hover ml-5" onClick={() => removeInvoiceItem(index)}></i>
              </div>
            </div>
          ))}

          <div className="row invoice-form-item">
            <div class="btn-group">
              <a className="animate opacity-4 opacity-10-hover cursor-pointer pl-0 dropdown-toggle color-1" data-toggle="dropdown">
                <i class="far fa-plus mr-5"></i>Add new item
              </a>
              <ul class="dropdown-menu animated fadeInUp" role="menu">
                <li><a onClick={() => setOpenServices(true)}><i class="far fa-list mr-5"></i>Choose from services</a></li>
                <li><a onClick={addInvoiceItem}><i class="far fa-plus mr-5"></i>Custom item</a></li>
              </ul>
            </div>
          </div>
        </div>
        
        {/* Payment method and final amounts */}
        <div className="row mt-25">
          <div className="col-6 pr-15">
            {/* Terms */}
            <div className="field">
              <a className="color-1" onClick={() => {setShowTerms(!showTerms); setPaymentTerms(null)}}>Note</a>
              <textarea defaultValue={paymentTerms} className="form-control mt-5 animated fadeInUp border-all" placeholder="Add your notes or terms of service here (optional)" rows="5" onChange={(e) => setPaymentTerms(e.target.value)}></textarea>
            </div>
            
            {/* Credits tracking */}
            <div className="field">
              {/* <div className="service-form-disable-pause-wrapper field">
                <a onClick={() => {setCreditsForm(!creditsForm); setCredits(null); setCreditsName(null)}} className="cursor-pointer font-13 color-1">
                  <i class="far fa-coins mr-8"></i>Track credits
                </a>
                <i className="far fa-question-circle ml-5" data-tip="Add the number of credits this service offers and track them easily."></i>
              </div> */}

              {/* CREDITS */}
              {creditsForm && 
                <div className="row animated fadeInUp default-padding border-radius background-3">
                  <div className="col-6 pr-15">
                    <label>Label</label>
                    <input className="form-control-small form-control" placeholder="ie Credits, Hours, or Tasks" value={creditsName} onChange={(e) => setCreditsName(e.target.value)}/>
                  </div>

                  <div className="col-6">
                    <label>Quantity</label>
                    <input className="form-control-small form-control" placeholder="Quantity of credits to track" min="1" type="number" onChange={(e) => setCredits(e.target.value)} value={credits}/>
                  </div>
                </div>
              }
            </div>
            
            {/* Payment method */}
            {/* <div className="field">
              <div class="btn-group">
                <a class="dropdown-toggle color-1" data-toggle="dropdown">
                  <i class="far fa-money-check-alt mr-8"></i>Payment method <span class="caret"></span>
                </a>
                <ul class="dropdown-menu animated fadeInUp" role="menu">
                  <li><a onClick={() => setPaymentMethod("stripe")}><i class="fab fa-cc-stripe mr-5"></i>Stripe</a></li>
                  <li><a onClick={() => setPaymentMethod("wire")}><i class="far fa-university mr-5"></i>Wire transfer</a></li>
                </ul>
              </div>

              {(paymentMethod == "wire") && 
                <div className="field default-padding border-all border-radius background-3 animated fadeInUp">
                  <p className="opacity-6">
                    {organization.bank_info}
                  </p>
                  <a className="font-12" onClick={() => setOpenBankInfo(true)}>
                    Edit
                  </a>
                </div>
              }

              {(paymentMethod == "stripe") && 
                <div className="field default-padding border-all border-radius text-center animated fadeInUp background-3">
                  {stripeAccount != null ? (
                    <p className="opacity-6 margin-none"><i class="far fa-check mr-5"></i>Checkout with Stripe</p>
                  ):(
                    <a href={`/o/${match.params.organization_id}/settings`}>Finish Stripe setup</a>
                  )}
                </div>
              }
            </div>  */}
            
            <div className="field">
              <label>Payment method</label>
              <div className="radio-group">
                <label className="row default-padding border-all border-radius background-hover cursor-pointer animate">
                  <div className="col-1">
                    <input
                      type="radio"
                      value="wire"
                      name="paymentMethod"
                      onChange={() => setPaymentMethod("wire")}
                      checked={paymentMethod === "wire"}
                      style={{display: "unset"}}
                    />
                  </div>

                  <div className="col-11">
                    <label className="display-flex justify-content-between align-content-center">
                      Payment instructions
                      <a className="font-12" onClick={() => setOpenBankInfo(true)}>
                        Edit
                      </a>
                    </label>
                    <div className="payment-method-info">
                      <p className="opacity-6 margin-none font-weight-400">
                        {organization.bank_info}
                      </p>
                    </div>

                  </div>
                </label>

                <label className="row default-padding border-all border-radius background-hover cursor-pointer animate">
                  <div className="col-1">
                    <input
                      type="radio"
                      value="stripe"
                      name="paymentMethod"
                      onChange={() => {setPaymentMethod("stripe"); setTax(null)}}
                      checked={paymentMethod === "stripe"}
                      style={{display: "unset"}}
                      disabled={organizationAuthorizations.organization.stripe_account == null}
                    />
                  </div>

                  <div className="col-11">
                    <label>Stripe</label>

                    <div className="payment-method-info">
                      {organizationAuthorizations.organization.stripe_account != null ? (
                        <p className="opacity-6 margin-none font-weight-400">
                          Clients can pay with cards or any method enabled in your Stripe account.
                        </p>
                      ) : (
                        <a target="_blank" href={`/o/${match.params.organization_id}/settings`}>Finish Stripe setup to allow checkout using this method.</a>
                      )}
                    </div>
                  </div>
                </label>
                
                {/* PayPal */}
                <label className="row default-padding border-all border-radius background-hover cursor-pointer animate">
                  <div className="col-1">
                    <input
                      type="radio"
                      value="stripe"
                      name="paymentMethod"
                      onChange={() => {setPaymentMethod("paypal"); setTax(null)}}
                      checked={paymentMethod === "paypal"}
                      style={{display: "unset"}}
                      disabled={organizationAuthorizations.organization.paypal_account == null}
                    />
                  </div>

                  <div className="col-11">
                  <label><i class="fab fa-paypal mr-8"></i>Paypal</label>

                    <div className="payment-method-info">
                      {organizationAuthorizations.organization.paypal_account != null ? (
                        <p className="opacity-6 margin-none font-weight-400">
                          Clients can pay with PayPal
                        </p>
                      ) : (
                        <a target="_blank" href={`/o/${match.params.organization_id}/settings`}>Finish PayPal setup to allow checkout using this method.</a>
                      )}
                    </div>
                  </div>
                </label>
              </div>
            </div>


          </div>

          <div className="col-6 border-left ">
            <div className="row field display-flex align-items-center">
              <div className="col-6">
                
              </div>
              <div className="col-6">
                <div className="field">
                  <label>VAT / TAX</label>
                  {paymentMethod === "stripe" ? (
                    <p className="opacity-4 font-12">
                      Taxes will be automatically calculated
                    </p>
                  ):(
                    <div class="input-group">
                      <input className="form-control" onChange={(e) => setTax(e.target.value)} type="number" defaultValue={tax}/>
                      <span class="input-group-addon">%</span>
                    </div>
                  )}
                </div>

                <div className="field">
                  <label>Total due</label>
                  <input className="form-control" disabled="true" value={totalDue}/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-right">
            <button disabled={disabled || (selectedProject == null && project_id == null) || ((selectedProject == ""))} className="btn btn-primary" type="submit">Submit</button>
          </div>
        </div>
      </form>
      
      <Modal open={openServices} onClose={() => setOpenServices(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <Services invoiceItems={invoiceItems} setInvoiceItems={setInvoiceItems} organization={organization} refetchData={refetchData} closeModal={() => setOpenServices(false)}/>
      </Modal>

      <Modal open={openBankInfo} onClose={() => setOpenBankInfo(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <BankInfo organization={organization} refetchData={refetchData} closeModal={() => setOpenBankInfo(false)}/>
      </Modal>

      <Modal open={openClientInfo} onClose={() => setOpenClientInfo(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <ClientInfo organization={organization} refetchData={refetchData} closeModal={() => setOpenClientInfo(false)} setClientAddress={setClientAddress} clientAddress={clientAddress} setClientEmail={setClientEmail} clientEmail={clientEmail} setClientName={setClientName} clientName={clientName}/>
      </Modal>
    </React.Fragment>
  )
}

export default Form;