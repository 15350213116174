import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import Filestack from "./Filestack.js";
import ReactTooltip from 'react-tooltip';
import { MultiSelect } from "react-multi-select-component";
import TipTap from "../Shared/TipTap.js";

const ClientOnboardingFormItem = ({index, form, forms, setForms, services, refetchData, loaded}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false);
  const [openFormIndex, setOpenFormIndex] = useState(0);
  const [selected, setSelected] = useState(form.selected_services || []);
  const [open, setOpen] = useState(false);
  const [comments, setComments] = useState(form.comments || null);

  const handleChange = (event, token) => {
    const { name, value } = event.target;
    setForms((prevForms) => {
      const updatedForms = prevForms.map((form) => {
        if (form.token === token) {
          if (name === 'kind') {
            return {
              ...form,
              kind: value,
              link: null,
              video_link: null,
              page: null,
            };
          } else {
            return {
              ...form,
              [name]: value,
            };
          }
        }
        return form;
      });
      return updatedForms;
    });
  };

  function updateFormById(id, update) {
    setForms((prevForms) =>
      prevForms.map((form) =>
        form.id === id ? { ...form, ...update } : form
      )
    );
  }  

  const toggleForm = (index) => {
    if (openFormIndex === index) {
      // Clicking on an already open form, so close it
      setOpenFormIndex(null);
    } else {
      // Clicking on a closed form, so open it and close others
      setOpenFormIndex(index);
    }
  };

  const removeForm = (index, client_onboarding_id) => {
    axios.delete(`/api/o/${match.params.organization_id}/client_onboardings/${client_onboarding_id}`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        const updatedForms = [...forms];
        updatedForms.splice(index, 1); // Remove the form at the specified index
        setForms(updatedForms);
        if (openFormIndex === index) {
          setOpenFormIndex(null); // Close the form if it's open
        }
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  };

  const handleSubmit = (event, form_id) => {
    event.preventDefault();
    const form_data = formData(form_id)
    console.log(form_data);

    axios.put(`/api/o/${match.params.organization_id}/client_onboardings/${form_id}`, {
      title: form_data.title, 
      description: form_data.description,
      link: form_data.link, 
      video_link: form_data.video_link, 
      kind: form_data.kind,
      page: form_data.page,
      selected_services: selected,
      comments: comments
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        notice("Updated successfully");
        refetchData();
      } else {
        errors_list = response.data.errors;
        errors_list.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
    // Handle the submission of all forms in the `forms` array
  };

  const serviceOptions = services.map((item) => ({
    value: item.id,
    label: item.title,
  }));

  return(
    <React.Fragment>
      <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
      <div class="panel-heading background-3 background-hover animate hover-parent-opacity">
        <h4 class="panel-title width-100-percent row">
          <div className="col-11">
            <a className="client-onboarding-list-title color-1" data-toggle="collapse" data-parent="#accordion" onClick={() => toggleForm(index)}>
              <i className="fas fa-grip-lines mr-10"></i> {form.title}
            </a>
          </div>
          
          <div className="col-1 text-right hover-child-opacity">
            <i class="far fa-trash opacity-6 cursor-pointer opacity-hover-1 border-radius background-hover animate border-all-hover" style={{padding: "8px 10px"}} onClick={() => removeForm(index, form.token)}></i>
          </div>
        </h4>
      </div>
      <div class={`panel-collapse collapse ${openFormIndex === index ? 'in' : ''}`}>
        <div class="panel-body border-top">
          <form id={form.token} key={index} onSubmit={(e) => handleSubmit(e, form.token)}>
            <div className="field">
              <label className="color-1">Title</label>
              <input
                className="form-control form-control-dark"
                type="text"
                name="title"
                value={form.title}
                onChange={(e) => handleChange(e, form.token)}
              />
            </div>

            <div className="field">
              <label className="color-1">Description</label>
              <input
                className="form-control form-control-dark"
                type="text"
                name="description"
                value={form.description}
                onChange={(e) => handleChange(e, form.token)}
              />
            </div>

            {/* Services */}
            <div className="field">
              <label className="color-1">Only on these purchases (optional) <i class="far fa-question-circle ml-8" data-tip="Choosing a service will cause this item to only appear if client purchases it."></i></label>
              <MultiSelect
                options={serviceOptions}
                value={selected}
                onChange={setSelected}
                labelledBy="Select"
                isLoading={!loaded}
                isCreatable={true}
              />
            </div>

            <div className="field">
              <label className="color-1">Type</label>
              <select className="form-control form-control-dark" name="kind" value={form.kind} onChange={(e) => handleChange(e, form.token)}>
                <option value="">Choose type</option>
                <option value="Link">Link</option>
                <option value="Video">Video</option>
                <option value="Text">Secured text response</option>
                <option value="File">Files</option>
                <option value="Comments">Notes</option>
                {/* <option disabled value="Page">Visit Page (soon)</option> */}
              </select>
            </div>

            {/* Type */}
            <div className="field">
              {form.kind == "Link" && 
                <React.Fragment>
                  <label className="color-1">URL</label>
                  <input value={form.link} name="link" className="form-control form-control-dark" type="url" placeholder="https://" onChange={(e) => handleChange(e, form.token)}/>
                </React.Fragment>
              }

              {form.kind == "Page" && 
                <React.Fragment>
                  <label className="color-1">Page</label>
                  <select className="form-control form-control-dark" name="page" value={form.page} onChange={(e) => handleChange(e, form.token)}>
                    <option value="">Choose page</option>
                    <option value="Task Board">Task Board</option>
                    <option value="Brand Assets">Brand Assets</option>
                  </select>
                </React.Fragment>
              }

              {form.kind == "Video" && 
                <React.Fragment>
                  <input type="hidden" name="video_link" value={form.video_link} />
                  <Filestack forms={forms} id={form.id} updateFormById={updateFormById}/>
                  {form.video_link != null && 
                    <>
                      <a className="color-1 opacity-4 opacity-10-hover animate" onClick={() => setOpen(true)}><i class="far fa-video ml-8"></i></a>
                      <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'modal-video-guide'}} center>
                        <video controls src={form.video_link} style={{width: "100%", height: "100%", borderRadius: "5px"}} />
                      </Modal>
                    </>
                  }
                </React.Fragment>
              }

              {form.kind == "Comments" && 
                <React.Fragment>
                  <label className="color-1">Notes</label>
                  <textarea rows="5" defaultValue={form.comments} onChange={e => setComments(e.target.value)} placeholder="Type your notes here" className="form-control"/>
                </React.Fragment>
              }
            </div>

            <div className="field text-right">
              <button className="btn btn-primary btn-small" type="submit">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ClientOnboardingFormItem