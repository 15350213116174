import React, { useState, useEffect, useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import _ from 'lodash';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ProjectContext } from "../Projects/ProjectContext.js";
import Select from 'react-select';

const HowTo = ({ project_id }) => {
  const match = useRouteMatch();
  const { projects } = useContext(ProjectContext);
  const [selected, setSelected] = useState(null);
  const [emailUrl, setEmailUrl] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (project_id && projects.length > 0) {
      const selectedProject = projects.find(project => project.token === project_id);
      if (selectedProject) {
        setSelected({ value: selectedProject.token, label: selectedProject.title });
      }
    }
  }, [project_id, projects]);

  useEffect(() => {
    setDisabled(true);
    setEmailUrl(`f2a8bd863583b29db828e971d131552f+organization_id+${match.params.organization_id}${selected ? `+project_id+${selected.value}` : ''}@inbound.postmarkapp.com`);
    setDisabled(false);
    
    setIsVisible(false);
    
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 300);

    return () => clearTimeout(timer);
  }, [selected, match.params.organization_id]);

  const projectOptions = projects.map(project => ({
    value: project.token,
    label: project.title
  }));

  const handleChange = selectedOption => {
    console.log(selectedOption);
    setSelected(selectedOption);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
    }),
  };

  return (
    <React.Fragment>
      <h4 className="color-black-always modal-title">Create tasks via email</h4>
      <div className="row no-margin">
        <div className="field">
          <label>Send to a project (optional)</label>
          <Select
            options={projectOptions}
            value={selected}
            onChange={handleChange}
            className="color-1"
            classNamePrefix="react-select"
            placeholder="Select..."
            styles={customStyles}
            isClearable
          />
        </div>
        <div className={`field animated ${isVisible ? 'fadeIn' : 'fadeOut'}`}>
          <label>
            Email
            {selected && 
              <span className="badge badge-blue ml-8">{selected.label}</span>
            }
          </label>
          <div className="input-group">
            <input type="text" className="form-control" disabled={true} value={emailUrl} readOnly />
            <span className="input-group-btn">
              <CopyToClipboard text={emailUrl} onCopy={() => {notice("Copied")}}>
                <button className="btn btn-default" type="button">Copy</button>
              </CopyToClipboard>
            </span>
          </div>
          <p className="font-12 text-center opacity-4 mt-15 mb-0">Share this with clients to send new requests via email.</p>
          <p className="font-12 text-center opacity-4 mt-0">(Expect a 2-5 min delay for incoming tasks.)</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HowTo;