import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import Btn from "../../Shared/Btn.js";
import PrivateForm from "../../Daas/Portal/Projects/TaskForms/PrivateForm.js";

const EmbedForm = ({closeModal, refetchData, embedData, sidenav}) => {
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false);
  const [kind, setKind] = useState(embedData != null ? (embedData.iframe != null ? "embed" : "url") : "embed");
  const [url, setUrl] = useState(embedData != null ? embedData.url : null);
  const [embed, setEmbed] = useState(embedData != null ? embedData.iframe : null)
  const [title, setTitle] = useState(embedData != null ? embedData.title : "");
  const [isPrivate, setIsPrivate] = useState(embedData != null ? embedData.private : false);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/o/${match.params.organization_id}/embeds`, {
      project_id: match.params.project_id,
      title: title,
      iframe: embed, 
      url: url, 
      private: isPrivate,
      sidenav: sidenav
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData(); 
        notice("Created embed");
        closeModal();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }
  
  const handleUpdate = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.put(`/api/o/${match.params.organization_id}/embeds/${embedData.token}`, {
      project_id: match.params.project_id,
      title: title,
      iframe: embed, 
      url: url, 
      private: isPrivate
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData(); 
        notice("Updated embed successfully")
        closeModal();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  return(
    <React.Fragment>
      <form onSubmit={embedData != null ? handleUpdate : handleSubmit}>
        <div className="row" style={{height: "90vh", overflow: "auto"}}>
          <div className="col-3 embed-iframe-left">
            <div className="field">
              <label>Title</label>
              <input className="form-control" required="true" placeholder="Short title to put as a tab" value={title} onChange={(e) => setTitle(e.target.value)} />
            </div>

            <div className="field">
              <div class="btn-group mb-25" style={{display: "flex", justifyContent: "space-around"}}>
                <button onClick={() => {setKind("url"); setEmbed(null)}} type="button" class={`width-100-percent btn btn-small ${kind == "url" ? "btn-primary" : "btn-primary-modal-outline"}`}>
                  URL
                </button>

                <button onClick={() => {setKind("embed"); setUrl(null)}} type="button" class={`width-100-percent btn btn-small ${kind == "embed" ? "btn-primary" : "btn-primary-modal-outline"}`}>
                  Embed code
                </button>
              </div>
            </div>

            <div className="field">
              {/* URL */}
              {kind === "url" && 
                <div className="field">
                  <label>URL</label>
                    <input type="url" class="form-control" value={url} onChange={(e) => setUrl(e.target.value)} required="true"/>
                </div>
              }

              {/* Embed */}
              {kind === "embed" && 
                <div className="field">
                  <label>Embed code</label>
                  <textarea class="form-control" value={embed} onChange={(e) => setEmbed(e.target.value)} required="true" rows="3"></textarea>
                </div>
              }
            </div>

            <div className="field text-right">
              <PrivateForm isPrivate={isPrivate} setIsPrivate={setIsPrivate}/>
              <button className="btn btn-primary" disabled={disabled} type="submit">Submit</button>
            </div>
          </div>

          <div className="col-9 default-padding border-all border-radius">
            {kind === "embed" && 
              <React.Fragment> 
                <div dangerouslySetInnerHTML={{ __html: embed }} />
              </React.Fragment>
            }

            {kind === "url" && 
              <React.Fragment>
                <iframe src={url} className="embed-iframe"></iframe>
              </React.Fragment>
            }

            {(kind === null || kind === "")&& 
              <div className="text-center large-padding">
                Preview window
              </div>
            }
          </div>
        </div>
      </form>
    </React.Fragment>
  )
}

export default EmbedForm;