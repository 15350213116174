import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import Services from "./Services.js";
import Discounts from "../Discounts/Discounts.js";
import Addons from "../Addons/Addons.js";
import TermsAndConditions from "../Stripe/TermsAndConditions.js";
import Invoices from "../../Invoices/Invoices.js";
import CheckoutSettings from "../Stripe/CheckoutSettings.js";

const ServicesWrapper = () => {
  const match = useRouteMatch();
  const [organization, setOrganization] = useState(null);
  const [loaded, setLoaded] = useState(false);
  
  useEffect(() => {
    fetchOrganization()
  }, [])

  const fetchOrganization = () => {
    axios.get(`/api/o/${match.params.organization_id}.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setOrganization(response.data.organization);
        setLoaded(true);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  return(
    loaded && 
    <React.Fragment>
      <div className="container mt-25 mb-25">
        <div className="row">
          <ul class="bootstrap-nav background-3 default-padding border-radius border-all">
            <li class="bootstrap-nav-item">
              <NavLink exact activeClassName="background-active" className="bootstrap-nav-tab background-hover" to={`/o/${match.params.organization_id}/services`}>Services</NavLink>
            </li>
            
            <li class="bootstrap-nav-item">
              <NavLink activeClassName="background-active" className="bootstrap-nav-tab background-hover" to={`/o/${match.params.organization_id}/services/discounts`}>Discounts</NavLink>
            </li>

            <li class="bootstrap-nav-item">
              <NavLink activeClassName="background-active" className="bootstrap-nav-tab background-hover" to={`/o/${match.params.organization_id}/services/invoices`}>
                Invoices
              </NavLink>
            </li>

            <li class="bootstrap-nav-item">
              <NavLink activeClassName="background-active" className="bootstrap-nav-tab background-hover" to={`/o/${match.params.organization_id}/services/terms`}>Terms of Service</NavLink>
            </li>

            <li class="bootstrap-nav-item">
              <NavLink activeClassName="background-active" className="bootstrap-nav-tab background-hover" to={`/o/${match.params.organization_id}/services/settings`}>
                Checkout settings
              </NavLink>
            </li>

            <li class="bootstrap-nav-item">
              <a href="/services" target="blank" className="bootstrap-nav-tab">
                <i className="fas fa-external-link mr-5"></i>View Catalog
              </a>
            </li>
          </ul>
        </div>

        <div className="mt-15">
          <Switch>
            <Route exact path="/o/:organization_id/services/discounts" render={() => <Discounts/>} />
            <Route exact path="/o/:organization_id/services/addons" render={() => <Addons/>}/>
            <Route exact path="/o/:organization_id/services" render={() => <Services />} />
            <Route exact path="/o/:organization_id/services/terms" render={() => <TermsAndConditions />} />
            <Route exact path="/o/:organization_id/services/terms" render={() => <StripeAccount />} />
            <Route exact path="/o/:organization_id/services/invoices" render={() => <Invoices />} />
            <Route exact path="/o/:organization_id/services/settings" render={() => <CheckoutSettings organization={organization} refetchData={fetchOrganization}/>} />
          </Switch>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ServicesWrapper