import React , { useState } from 'react';
import { useRouteMatch } from 'react-router-dom'
import { Modal } from 'react-responsive-modal';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { monokai } from 'react-syntax-highlighter/dist/esm/styles/hljs'; // You can choose a different style if desired


const Snippet = ({snippet}) => {
  const match = useRouteMatch();
  const [open, setOpen] = useState(false);
  
  return(
    <React.Fragment>
      <i onClick={() => setOpen(true)} className="fal fa-eye mr-5 opacity-4 opacity-10-hover cursor-pointer animate"></i>

      <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <SyntaxHighlighter language="javascript" style={monokai}>
          {snippet.code}
        </SyntaxHighlighter>
      </Modal>
    </React.Fragment>
  )
}

export default Snippet