import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 

const OrganizationTheme = ({organization_theme, refetchData}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);

  const makePrimaryOrganizationTheme = () => {
    setDisabled(true);

    axios.put(`/api/o/${match.params.organization_id}`, {
      organization_theme_id: organization_theme.id
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  };

  const deleteOrganizationTheme = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.delete(`/api/o/${match.params.organization_id}/organization_themes/${organization_theme.token}`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        notice("Deleted");
        refetchData();
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }


  return(
    <React.Fragment>
      <div className="row border-top" style={{paddingTop: "20px"}}>
        <div className="col-1 pr-15">
          <img className="themes-library-image" src={organization_theme.theme.preview_image}/>
        </div>

        <div className="col-4">
          <label>
            {organization_theme.theme.title}
          </label>
          <p className="opacity-4">
            Added: {organization_theme.created_at}
          </p>
        </div>

        <div className="col-7 text-right">
          <a disabled={disabled || organization_theme.primary} onClick={makePrimaryOrganizationTheme} className="btn btn-primary-outline mr-5">
            <i class="fal fa-home mr-8"></i>
            {organization_theme.primary ? (
              "Primary"
            ):(
              "Make primary"
            )}
          </a>
          <a href={`/organization_themes/${match.params.organization_id}/themes/${organization_theme.token}`} target="_blank" className="btn btn-primary-outline"><i class="fal fa-pencil-paintbrush mr-8"></i>Customize</a>

          <div class="btn-group ml-8" style={{height: "20px"}}>
            <a type="button" class="task-dropdown-btn dropdown-toggle color-1 pull-right" data-toggle="dropdown" >
              <i class="fas fa-ellipsis-v"></i>
            </a>

            <ul class="dropdown-menu animated fadeInUp" role="menu">
              <li><a onClick={deleteOrganizationTheme}><i class="fal fa-trash"></i>Delete</a></li>
            </ul>
          </div>
        </div>

      </div>
    </React.Fragment>
  )
}

export default OrganizationTheme;