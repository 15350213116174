import React , { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import VideoGuide from "../../Shared/VideoGuide.js";
import Taskboard from "./Taskboard.js";
import "@lottiefiles/lottie-player";

const TaskBoards = () => {
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [taskboards, setTaskboards] = useState([]);

  useEffect(() => {
    fetchTaskboards();
  }, []);

  const fetchTaskboards = () => {
    axios.get(`/api/o/${match.params.organization_id}/taskboards.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setTaskboards(response.data.taskboards);
        setLoaded(true);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const taskboards_list = taskboards.map(taskboard => {
    return(
      <Taskboard taskboard={taskboard} refetchData={fetchTaskboards}/>
    )
  })

  return(
    loaded &&
    <React.Fragment>
      <title>Taskboard templates</title>
      <div className="container mt-25">
        <div className="header-page">
          <div>
            <h4 className="font-weight-600 no-margin font-16">Taskboard templates</h4>
            <p className="font-12 opacity-7">
              Taskboard templates lets you save custom taskboards for specific services. This is in beta, so give us feedback. 
            </p>
          </div>
        </div>

        <div className="row" style={{marginLeft: "-15px", marginRight: "-15px"}}>
          {taskboards.length > 0 ? (
            taskboards_list
          ):(
            <React.Fragment>
              <div className="information-wrapper">
                <lottie-player autoplay loop mode="normal" src="https://lottie.host/e9405e33-b28c-493b-91bd-a8b1db1ce745/pioVL1KzhR.json" style={{height: "300px"}}></lottie-player>
                  <p className="color-white text-center" style={{marginTop: "-30px"}}>
                    Go to an existing projects taskboard to save it as a template. 
                  </p>

                  <p className="color-white text-center">
                    <VideoGuide src="https://www.loom.com/embed/8bd2b7c197044908ba9697f1ded85c36?sid=1e7c1ed8-28f0-4e8a-9c58-8223214edaf5" />
                  </p>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default TaskBoards