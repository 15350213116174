import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import Linkify from 'react-linkify';

const Message = ({message}) => {
  const match = useRouteMatch();

  return(
    <React.Fragment>
        <div className="widget-chat-message animated fadeInUp mb-15 width-100-percent" key={message.id}>
          <div className={message.owner ? "widget-chat-to" : "widget-chat-from"}>
            {!message.owner && 
              <label className={`width-100-percent`}>{message.user.username}</label>
            }

            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>{decoratedText}</a>)}>
              {message.body}
            </Linkify>
          </div>
        </div>
      </React.Fragment>
  )
}

export default Message 