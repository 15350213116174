import React, {useCallback} from 'react';
import { BubbleMenu } from '@tiptap/react'

const TipTapBubbleMenu = ({editor}) => {

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    // cancelled
    if (url === null) {
      return
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink()
        .run()

      return
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url })
      .run()
  }, [editor])
  
  return(
    <React.Fragment>
      <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
        <div className="editor-button-menu border-all box-shadow background-3">
          <a
            onClick={() => editor.chain().focus().toggleBold().run()}
            className={`${editor.isActive('bold') ? 'background-active' : ''} border-right color-1 editor-button-menu-button background-hover btn btn-small`}
            style={{fontWeight: "700"}}
          >
            B
          </a>
          <a
            onClick={() => editor.chain().focus().toggleItalic().run()}
            style={{fontStyle: "italic"}}
            className={`${editor.isActive('italic') ? 'background-active' : ''} border-right color-1 editor-button-menu-button background-hover btn btn-small`}
          >
            i
          </a>
          <a
            onClick={() => editor.chain().focus().toggleStrike().run()}
            style={{textDecoration: "line-through"}}
            className={`${editor.isActive('strike') ? 'background-active' : ''} border-right color-1 editor-button-menu-button background-hover btn btn-small`}
          >
            S
          </a>
          <a
            onClick={setLink}
            style={{textDecoration: "line-through"}}
            className={`${editor.isActive('link') ? 'background-active' : ''} border-right color-1 editor-button-menu-button background-hover btn btn-small`}
          >
            <i class="far fa-link"></i>
          </a>

          <a
            onClick={() => editor.chain().focus().toggleHighlight({ color: '#999940' }).run()}
            style={{textDecoration: "highlight"}}
            className={`${editor.isActive('highlight') ? 'background-active' : ''} color-1 editor-button-menu-button background-hover btn btn-small`}
          >
            <i class="fal fa-highlighter"></i>
          </a>

          <a
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            style={{textDecoration: "underline"}}
            className={`${editor.isActive('highlight') ? 'background-active' : ''} color-1 editor-button-menu-button background-hover btn btn-small`}
          >
            <i class="fal fa-underline"></i>
          </a>
        </div>
      </BubbleMenu>
    </React.Fragment>
  )
}

export default TipTapBubbleMenu;