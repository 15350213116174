import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import Video from "./Video.js";
import ClientOnboardingReadForm from "./ClientOnboardingReads/ClientOnboardingReadForm.js";

const ClientOnboarding = ({form, refetchData, owner}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);

  const clientOnboardingRead = () => {
    axios.post(`/api/o/${match.params.organization_id}/client_onboardings/${form.token}/client_onboarding_reads`, {
      project_id: match.params.project_id
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData();
      } else {
        errors_list = response.data.errors;
        errors_list.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      // notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const deleteClientOnboardingRead = (client_onboarding_read) => {
    axios.delete(`/api/o/${match.params.organization_id}/client_onboardings/${form.token}/client_onboarding_reads/${client_onboarding_read.token}`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData();
      } else {
        errors_list = response.data.errors;
        errors_list.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  return(
    <React.Fragment>
      <div className={`client-onboarding-checklist-item-wrapper background-hover animate`} onClick={() => {
        // if(!owner){
          if (form.kind === "Text" || form.kind === "File") {
            // setOpen(true);
          } else {
            if(form.client_onboarding_reads?.length === 0){
              clientOnboardingRead();
            } else {
              deleteClientOnboardingRead(form.client_onboarding_reads[0]);
            }
          }
        // }
      }}>
        {(form?.client_onboarding_reads?.length > 0) ?(
          <i class="fas fa-check-circle client-onboarding-checklist-item-icon color-blue"></i> 
          ):(
          <i class="far fa-circle client-onboarding-checklist-item-icon"></i> 
        )}
        
        <div style={{width: "100%"}}>
          {(form.kind == null || form.kind == "") &&
            <div className="client-onboarding-checklist-item-link">
              {form.title}
            </div>
          }

          {/* {(form.kind == "Video")&&
            <div className="client-onboarding-checklist-item-link">
              {form.title}
            </div>
          } */}

          {form.kind == "Link" &&
            <a className="client-onboarding-checklist-item-link" href={form.link} target="_blank">
              {form.title} <i class="fal fa-link ml-10"></i>
            </a>
          }

          {form.kind == "Video" && 
            <Video src={form.video_link} title={form.title} />
          }

          {form.kind == "Text" && 
            <>
              <a className="client-onboarding-checklist-item-link" onClick={() => setOpen(true)}>
                {form.title} <i class="fal fa-text ml-10"></i>
              </a>
            </>
          }

          <Modal open={open} onClose={() => setOpen(false)} closeOnOverlayClick={true} className="modal-body-white" classNames={{modal: 'width-800'}} center>
            <ClientOnboardingReadForm form={form} refetchData={refetchData} closeModal={() => setOpen(false)}/>
          </Modal>

          {form.kind == "File" && 
            <>
              <a className="client-onboarding-checklist-item-link" onClick={() => setOpen(true)}>
                {form.title} <i class="fal fa-upload ml-10"></i>
              </a>
            </>
          }

          {form.kind == "Comments" && 
            <>
              <a className="client-onboarding-checklist-item-link" onClick={() => setOpen(true)}>
                {form.title} <i class="fal fa-sticky-note ml-10"></i>
              </a>
            </>
          }

          {(form.description != null && form.description != "") &&
            <>
              {form.client_onboarding_reads != null && 
                <>
                  <p className="font-12 opacity-6 dark-color-black" style={{marginTop: "-15px"}}>{form.description}</p>
                  {(form.client_onboarding_reads.length > 0 && (form.kind === "File" || form.kind === "Text")) && 
                    <p className="font-12 opacity-6 dark-color-black" style={{marginTop: "-15px"}}>{form.client_onboarding_reads.length} response</p>
                  }
                </>
              }
            </>
          }
        </div>
      </div>
    </React.Fragment>
  )
}

export default ClientOnboarding;