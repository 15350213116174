import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import Review from "./Review.js";
import "@lottiefiles/lottie-player";

const Reviews = (props) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [reviewsData, setReviewsData] = useState([]);

  useEffect(() => {
    fetchReviews();
  }, []);

  const fetchReviews = () => {
    axios.get(`/api/o/${match.params.organization_id}/reviews.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setReviews(response.data.reviews);
        setReviewsData(response.data.reviews_data);
        setLoaded(true);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const reviews_data_list = reviewsData.map(review_data => {
    return(
      <div className="col-12 reviews-data-wrapper">
        <div style={{width: "80px"}}>
          {review_data.name}
        </div>

        <div class="progress width-100-percent background-3">
          <div class="progress-bar" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{width: `${review_data.percentage}%`}}>
            <span class="sr-only">{review_data.percentage}% Complete</span>
          </div>
        </div>

        <div className="pl-15">
          {review_data.percentage}%
        </div>
      </div>
    )
  })

  const reviews_list = reviews.map(review => {
    return(
      <Review review={review} />
    )
  })

  return(
    loaded &&
    <React.Fragment>
      <div className="container mt-25">
        <div className="row">
          <div className="header-page">
            <div>
              <h4 className="font-weight-600 no-margin">Task Reviews</h4>
              <p className="font-12 opacity-7">Find out how happy your clients are with Task Reviews.</p>
            </div>
        
            {/* Toggle to disable it */}
          </div>
        </div>

        {reviews.length > 0 ? (
          <div className="row mt-15" style={{alignItems: "flex-start"}}>
            <div className="col-md-4 medium-padding background-3 border-radius border-all" style={{paddingTop: "0px"}}>
              <h4>Client reviews ({reviews.length})</h4>
              {reviews_data_list}
            </div>
            
            <div className="col-md-8 row default-padding" style={{marginTop: "-15px"}}>
              {reviews_list}
            </div>
          </div>
        ):(
          <div className="row mt-15 reviews-index-empty">
            <div className="reviews-index-wrapper">
              <lottie-player autoplay loop mode="normal" src="https://lottie.host/a2a034a7-290a-4ca3-893d-391e6b0a1826/XbVuLMWWJN.json" style={{width: "300px", margin: "auto"}}></lottie-player>
              <h4>Find out what your customers think</h4>
              <p className="opacity-7">Reviews let your clients review tasks that you finished. Find out what they think, fix it, and increase your retention.</p>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default Reviews;