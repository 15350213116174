import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import "@lottiefiles/lottie-player";

const NoAffiliate = ({refetchData}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);

  const handleSubmit = () => {
    setDisabled(true);

    axios.post(`/api/o/${match.params.organization_id}/affiliates`, {
      sender_commission_kind: "percentage", 
      recipient_commission_kind: "percentage"
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData();
        notice("Add the rewards now")
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }


  return(
    <React.Fragment>
      <lottie-player autoplay loop mode="normal" src="https://queue.nyc3.cdn.digitaloceanspaces.com/animation_lo3brjdy.json" style={{width: "300px", margin: "auto"}}></lottie-player>
      <p className="text-center">Create an affiliate program to get more clients by incentivising your referrer and referee.</p>

      <div className="mt-25">
        <button className="btn btn-primary" onClick={handleSubmit} disabled={disabled}>Create program</button>
      </div>
    </React.Fragment>
  )
}

export default NoAffiliate