import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';

const Emoji = ({task, taskComment, reaction, taskComments, setTaskComments, project_id}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);

  const handleSubmit = () => {
    setDisabled(true);

    axios.delete(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}/task_comments/${taskComment.token}/reactions/${reaction.token}`)
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        // Remove reaction from taskComment.reactions array
        const updatedReactions = taskComment.reactions.filter(r => r.token !== reaction.token);
        // Update taskComment with the filtered reactions
        const updatedTaskComment = { ...taskComment, reactions: updatedReactions };
        // Find index of taskComment in taskComments array
        const index = taskComments.findIndex(tc => tc.token === taskComment.token);
        // Update taskComments array with the modified taskComment
        const updatedTaskComments = [...taskComments];
        updatedTaskComments[index] = updatedTaskComment;
        setTaskComments(updatedTaskComments);
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }


  return(
    <div onClick={reaction.owner ? handleSubmit : console.log("Not the owner")} className={`btn-icon ml-5 ${reaction.owner ? "background-red-hover cursor-pointer" : "background-none cursor-unset"}`}>{reaction.emoji}</div>
  )
}

export default Emoji;