import React from 'react';
import Linkify from 'react-linkify';

const TextInput = ({ field, handleFieldChange, removeField, handleLabelChange, taskForm, handleValueChange }) => {
  return (
    <React.Fragment>
      <div className="field">
        <div className="daas-task-form-label">
          {!taskForm ? (
            <label className={`task-form-label animate ${!taskForm ? "border-all-blue-hover" : ""}`} style={{cursor: "text"}} contentEditable={!taskForm} onBlur={(e) => handleLabelChange(field.id, e.target.textContent)}>
              {field.label}
            </label>
          ):(
            <label>
              <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                <a href={decoratedHref} key={key} target="_blank">{decoratedText}</a>
              )}>
                {field.label}
              </Linkify>
            </label>
          )}

          {!taskForm && 
            <div>
              <i onClick={() => removeField()} className="fas fa-times color-red opacity-4 opacity-10-hover animate padding-8 cursor-pointer"></i>
              {/* <i class="fas fa-grip-vertical ml-10"></i> */}
            </div>
          }
        </div>
        
        <input 
          className="form-control placeholder-text" 
          type={field.kind} 
          onChange={(e) => {!taskForm ? handleFieldChange(field.id, e.target.value) : handleValueChange(field.id, e.target.value)}} 
          placeholder={taskForm ? field.placeholder : (field.placeholder != null ? field.placeholder : "Type here to add placeholder text.")}
          maxLength={field.maxCharacters}
          required={field.isRequired}
        />
      </div>
    </React.Fragment>
  );
};

export default TextInput;
