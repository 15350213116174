import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 

const Theme = ({theme, refetchData}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/o/${match.params.organization_id}/organization_themes`, {
      theme_id: theme.token
    })
    .then(function(response){
      if(response.data.success){
        refetchData();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }


  return(
    <React.Fragment>
      <form onSubmit={handleSubmit} className="default-padding" style={{width: 300}}>
        <div className="border-all border-radius">
          <img className="themes-available-image" src={theme.preview_image}/>
          <div className="display-flex justify-content-between default-padding">
            <div>
              <label>{theme.title}</label>
              <div className="opacity-4">By {theme.created_by}</div>
            </div>

            <div>
              <i class="fal fa-eye mr-8 cursor-pointer btn btn-small background-hover"></i>
              <button type="submit" className="btn btn-primary btn-small" disabled={disabled}><i class="fal fa-plus mr-8"></i>Add</button>
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  )
}

export default Theme;