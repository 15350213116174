import React , { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import axios from "axios"; 
import VerifyCode from "./VerifyCode.js";
import CreateAccount from "../Registrations/CreateAccount.js";
import { HTML5Backend } from 'react-dnd-html5-backend';

const Signin = ({refetchData, className}) => {
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [email, setEmail] = useState("");
  const [step, setStep] = useState("Check");
  const [subdomainPresent, setSubdomainPresent] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [register, setRegister] = useState(window.location.href.includes("/register"));

  useEffect(() => {
    fetchCheckLogin();
    localStorage.removeItem('organization_path');
  }, []);

  const fetchCheckLogin = () => {
    axios.get(`/api/check_login.json`)
    .then(function(response){
      console.log(response);
      setSubdomainPresent(response.data.subdomain_present);
      setOrganization(response.data.subdomain_organization); 
      setLoaded(true);
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/check_account`, {
      email: email
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setStep("Login")
      } else {
        setStep("Signup")
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }


  return(
    loaded &&
    <React.Fragment>
      <div class={className != null ? className : "container col-md-6 col-md-offset-2 login-main"}>
        <div class="login-box no-padding">
          <div class="background-3 border-radius login-box-info border-all animated fadeInUp">
              {step === "Login" && 
                <VerifyCode setStep={setStep} email={email} refetchData={refetchData != null ? refetchData : null} organization={organization}/>
              }

              {step === "Signup" && 
                <CreateAccount setStep={setStep} email={email} setEmail={setEmail} refetchData={refetchData != null ? refetchData : null} subdomainPresent={subdomainPresent} organization={organization}/>
              }

              {step === "Check" && 
                <form onSubmit={handleSubmit}>
                  <div class="field text-center mb-25">
                    {organization != null && 
                      <img src={organization.avatar} className="avatar-md mb-10" />
                    }
                    {register ? (
                      <>
                        <h3 class="font-weight-600">Welcome</h3>
                        <p className="opacity-7">Enter your email to get started.</p>
                      </>
                    ):(
                      <>
                        <h3 class="font-weight-600">Welcome back</h3>
                        <p className="opacity-7">Enter your email to login</p>
                      </>
                    )}
                  </div>
                  
                  <div class="field">
                    <div class="input-group addon">
                      <span class="input-group-addon input-group-dark" id="basic-addon1">
                        <i class="fas fa-envelope"></i>
                      </span>
                      <input autoFocus onChange={(e) => setEmail(e.target.value)} placeholder="Email" class="form-control form-control-dark" required="required" autocomplete="off" type="email" value={email}/>
                    </div>
                  </div>

                  <div id="signupError" class="field color-red">
                    {/* <!-- Sorry but we couldn't create your account. --> */}
                  </div>

                  <div class="field">
                    {/* <%= f.hidden_field :redirect_url, :value => params[:redirect_url] %> */}
                    {/* <%= submit_tag "Login", class: "btn btn-primary btn-block", style: "padding: 16px; margin-top: 20px;" %> */}
                    <button disabled={disabled || email === ""} className="btn btn-primary display-block width-100-percent" type="submit">Submit</button>
                  </div>

                  {register ?(
                    <div className="text-center">
                      <Link className="text-center mt-8 color-1 opacity-4 btn btn-small background-hover" to={`/login`}>Login instead</Link>
                    </div>
                  ):(
                    <div className="text-center">
                      <Link className="text-center mt-8 color-1 opacity-4 btn btn-small background-hover" to={`/register`}>New account</Link>
                    </div>
                  )}
                </form>
              }
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Signin;