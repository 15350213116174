import React , { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import Select from 'react-select';
import Editor from 'react-simple-wysiwyg';

const Edit = ({taskboard, closeModal, refetchData}) => {
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [services, setServices] = useState([]);
  const [title, setTitle] = useState(taskboard.title);
  const [description, setDescription] = useState(taskboard.description);
  const [selectedServices, setSelectedServices] = useState(taskboard.services);

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = () => {
    axios.get(`/api/o/${match.params.organization_id}/taskboards.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setServices(response.data.services);
        setLoaded(true);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const updateTaskboard = (e) => {
    e.preventDefault();

    axios.put(`/api/o/${match.params.organization_id}/taskboards/${taskboard.token}`, {
      title: title, 
      description: description
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData();
        closeModal(true);
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const serviceOptions = services.map((item) => ({
    value: item.token,
    label: item.title,
  }));

  const handleServiceSelection = (newValue) => {
    console.log(newValue)
    if (!newValue) newValue = []; // handle null value when all selections are cleared

    const addedItems = newValue.filter(item => !selectedServices.includes(item));
    const removedItems = selectedServices.filter(item => !newValue.includes(item));

    if (addedItems.length > 0) {
      console.log('Added:', addedItems[0].value);
      axios.post(`/api/o/${match.params.organization_id}/taskboards/${taskboard.token}/taskboard_services`, {
        service_id: addedItems[0].value
      })
      .then(function(response){
        console.log(response);
        if(response.data.success){
          notice("Success!");
          refetchData();
        } else {
          response.data.errors.forEach((error) => {
            notice(error);
          });
        }
      })
      .catch(function(error){
        console.log(error)
        notice("An error occured")
      })
      .then(function () {
        setDisabled(false);
      });
    }

    if (removedItems.length > 0) {
      console.log('Removed:', removedItems[0].value);
      axios.delete(`/api/o/${match.params.organization_id}/taskboards/${taskboard.token}/taskboard_services/${removedItems[0].taskboard_service.token}`)
      .then(function(response){
        console.log(response);
        if(response.data.success){
          notice("Success!"); 
          refetchData();
        } else {
          response.data.errors.forEach((error) => {
            notice(error);
          });
        }
      })
      .catch(function(error){
        console.log(error)
        notice("An error occured")
      })
      .then(function () {
        setDisabled(false);
      });
    }

    setSelectedServices(newValue);
};

  return(
    loaded && 
    <React.Fragment>
      <div className="row">
        <h4 className="color-black-always modal-title">
          Update taskboard
        </h4>

        <div className="row">
          <form onSubmit={updateTaskboard}>
            <div className="field">
              <label>Title</label>
              <input className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} required="true" />
            </div>

            <div className="field">
              <label>Description</label>
              <Editor value={description} onChange={(e) => setDescription(e.target.value)} containerProps={{ style: { minHeight: 150 } }}/>
            </div>

            <div className="field">
              <label>Apply to</label>
              <Select
                options={serviceOptions}
                isMulti
                value={selectedServices}
                onChange={handleServiceSelection}
              />
              <p className="font-12 opacity-4">
                Services that already has a template won't appear here
              </p>
            </div>

            <div className="field text-right">
              <button disabled={disabled || (selectedServices.length == 0)} className="btn btn-primary" type="submit">Update template</button>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Edit