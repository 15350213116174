import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
import NewOrganizationProjectUserProfile from "./OrganizationProjectUserProfiles/NewOrganizationProjectUserProfile.js";

const OrganizationUser = ({user, refetchData}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);

  const deleteOrganizationUser = (organization_id, self) => {
    setDisabled(true)
    axios.delete(`/api/o/${match.params.organization_id}/organization_users/${organization_id}`)
    .then(function(response){
      if(self){
        window.location.href("/")
      } else {
        refetchData();
      }
    })
  }

  return(
    <React.Fragment>
      <tr className="hover-parent-opacity">
        <td className="color-black-always">
          <img src={user.avatar} className="people-avatar"/>{user.username}
        </td>
        <td className="color-black-always">{user.email}</td>
        <td>
          {user.organization_project_user_profile ? (
            <>
              <span className="badge color-1">            
                {user.organization_project_user_profile.hourly_rate} / Hour
              </span>
              <a onClick={() => setOpen(true)} className="btn btn-small background-hover hover-child-opacity color-1 ml-5"><i className="fas fa-pencil"></i></a>
            </>
          ):(
            <a onClick={() => setOpen(true)} className="btn btn-small background-hover border-all hover-child-opacity color-1"><i className="fas fa-plus mr-8"></i>Add rate</a>
          )}
        </td>

        <td>
          {user.organization_project_user_profile ? (
            <>
              <span className="badge border-all color-1 background-active">{user.organization_project_user_profile.job_title}</span>
              <a onClick={() => setOpen(true)} className="btn btn-small background-hover hover-child-opacity color-1 ml-5"><i className="fas fa-pencil"></i></a>
            </>
          ):(
            <a onClick={() => setOpen(true)} className="btn btn-small background-hover border-all hover-child-opacity color-1"><i className="fas fa-plus mr-8"></i>Add job title</a>
          )}
        </td>

        <td className="text-right">
          {
            (user.owner) &&
            (!user.organization_owner) &&
              <a onClick={() => deleteOrganizationUser(user.organization_user_id, user.user_self)} className="color-red opacity-10-hover btn btn-small background-hover"><i class="fal fa-times"></i></a>  
          }
        </td>

        <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
          <h4 className="color-black-always modal-title">Workspace member profile</h4>

          <div className="row no-margin">
            <NewOrganizationProjectUserProfile user={user} refetchData={refetchData} closeModal={() => setOpen(false)}/>
          </div>
        </Modal>
      </tr>
    </React.Fragment>
  )
}

export default OrganizationUser;