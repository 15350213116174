import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
import ReactTooltip from 'react-tooltip';

const EmailLogs = ({emailable_type, emailable_id, closeModal}) => {
  // const organizationAuthorizations = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [emailLogs, setEmailLogs] = useState([]);
  const [email, setEmail] = useState(null);

  useEffect(() => {
    if(emailable_type != null){
      fetchEmailLogs();
    } else {
      fetchOrganizationEmailLogs();
    }
  }, []);

  const fetchEmailLogs = () => {
    axios.get(`/api/email_logs.json?emailable_type=${emailable_type}&emailable_id=${emailable_id}`)
    .then(function(response){
    //   console.log(response);
      if(response.data.success){
        setEmailLogs(response.data.email_logs);
        setLoaded(true)
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const fetchOrganizationEmailLogs = () => {
    axios.get(`/api/email_logs.json?organization_id=${match.params.organization_id}`)
    .then(function(response){
    //   console.log(response);
      if(response.data.success){
        setEmailLogs(response.data.email_logs);
        setLoaded(true)
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const email_logs_map = emailLogs.map(email_log => {
    return(
      <tr className="border-top background-3">
        <td>{email_log.recipient}</td>
        <td>{email_log.subject}</td>
        <td>{email_log.created_at}</td>
        <td>
          <i onClick={() => setEmail(email_log)} class="far fa-eye btn btn-small background-hover cursor-pointer"></i>
        </td>
      </tr>
    )
  })

  return(
    loaded && 
    <React.Fragment>
      <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
      {email ? (
        <div className="animated zoomIn">
          <a onClick={() => setEmail(null)} className="btn btn-small color-1 background-hover cursor-pointer">
            <i class="far fa-angle-left mr-8"></i>Back
          </a>
          
          <div className="email-log-iframe-wrapper">
            <div className="default-padding display-flex justify-content-between align-items-center mb-5 border-all border-radius mt-25 width-100-percent text-black-only" style={{background: "white"}}>
              {email.subject}
              <span className="font-12 opacity-6">{email.created_at}</span>
            </div>
            <iframe src={`/api/email_logs/${email.token}`} className="email-log-iframe border-all border-radius default-padding"/>
          </div>
        </div>
      ):(
        <>
          {emailLogs.length === 0 ? (
            <div className="display-flex justify-content-between flex-direction-column align-items-center medium-padding">
              <h4 className="text-center">No emails sent, yet</h4>
              <p className="opacity-6 max-width-500 text-center">Emails are typically sent within 5-10 minutes for optimal performance. Please check back later to confirm delivery.</p>
              <div className="text-center">
                <a href="#" target="_blank" className="btn btn-small color-1 background-hover"><i class="fal fa-play-circle mr-8"></i>Video guide</a>
              </div>
            </div>
          ):(
            <>
              <div className="display-flex align-items-center">
                <h4 className="pl-15">
                  Emails sent
                </h4>

                <a href="#" target="_blank" className="btn btn-small color-1 background-hover ml-8"><i class="fal fa-play-circle mr-8"></i>Video guide</a>
              </div>

              <table class="table border-all border-radius">
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Subject</th>
                    <th>Sent on</th>
                  </tr>
                </thead>

                <tbody>
                  {email_logs_map}
                </tbody>
              </table>
              
              <p className="font-12 opacity-4 text-center mt-15">Did we forget to add an email logger for something? Tell us!</p>
            </>
          )}
        </>
      )}
    </React.Fragment>
  )
}

export default EmailLogs;