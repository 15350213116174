import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import WidgetSetup from "./WidgetSetup.js";
import HowTo from "../EmailedTasks/HowTo.js";

const Index = (props) => {
  // const organizationAuthorizations = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [view, setView] = useState(null);

  useEffect(() => {
    // fetchSomething();
  }, []);

  const fetchSomething = () => {
    // axios.get(`/api/`)
    // .then(function(response){
    // //   console.log(response);
    //   if(response.data.success){

    //   } else {

    //   }
    // })
    // .catch(function(error){
    //   console.log(error)
    //   notice("An error occured")
    // })
    // .then(function () {
    //   // always executed
    // });
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/`)
    .then(function(response){
      // console.log(response);
      if(response.data.success){

      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }


  return(
    <React.Fragment>
      {view === null && 
        <div className="inbox-container">
          <div className="row max-width-500">
            <div className="col-12">
              <h4 className="text-center mb-15 animated fadeInUp">Setup your inbox</h4>
            </div>
            <div className="col-12">
              <a onClick={() => setView("chat")} className="background-3 border-all mb-25 pr-15 border-radius default-padding inbox-container-item background-hover color-1 display-flex justify-content-between align-items-center animated fadeInUp delay">
                <div className="display-flex">
                  <i className="fal fa-comments font-19 opacity-6" style={{paddingTop: '5px'}}></i>
                  <div className="pl-15 pr-15">
                    <h4 className="no-margin">Chat Widget</h4>
                    <p className="font-12 opacity-6 no-margin">Setup a chat widget for your website to answer questions from prospects.</p>
                  </div>
                </div>

                <i className="fa fa-chevron-right"></i>
              </a>
            </div>

            <div className="col-12">
              <a onClick={() => setView("emailed")} className="background-3 border-all mb-25 pr-15 border-radius default-padding inbox-container-item background-hover color-1 display-flex justify-content-between align-items-center animated fadeInUp delay-1">
                <div className="display-flex">
                  <i className="fal fa-envelope font-19 opacity-6" style={{paddingTop: '5px'}}></i>
                  <div className="pl-15 pr-15">
                    <h4 className="no-margin">Emailed Requests</h4>
                    <p className="font-12 opacity-6 no-margin">Turn incoming emails into task requests. Setup a forward easily to our email.</p>
                  </div>
                </div>

                <i className="fa fa-chevron-right"></i>
              </a>
            </div>
          </div>
        </div>
      }

      {view === "chat" && 
      <div className="default-padding">
        <a className="btn btn-small background-hover color-1 mb-15" onClick={() => setView(null)}><i className="fal fa-chevron-left mr-5"></i> Back</a>
        <WidgetSetup />
      </div>
      }

      {view === "emailed" && 
      <div className="default-padding">
        <a className="btn btn-small background-hover color-1" onClick={() => setView(null)}><i className="fal fa-chevron-left mr-5"></i> Back</a>
        <div className="display-flex justify-content-center align-items-center flex-direction-column" style={{height: "calc(100vh - 200px)"}}>
          <div className="max-width-500 background-3 border-radius border-all default-padding">
            <HowTo />
          </div>
        </div>
      </div>
      }
    </React.Fragment>
  )
}

export default Index;