import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import NewSnippet from "./SnippetForm.js";
import Snippet from "./Snippet.js";

const Snippets = (props) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [snippets, setSnippets] = useState([]);

  useEffect(() => {
    fetchSnippets();
  }, []);

  const fetchSnippets = () => {
    axios.get(`/api/o/${match.params.organization_id}/snippets.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setSnippets(response.data.snippets)
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const snippets_list = snippets.map(snippet => {
    const handleDelete = () => {
      axios.delete(`/api/o/${match.params.organization_id}/snippets/${snippet.token}`)
      .then(function(response){
        console.log(response);
        if(response.data.success){
          fetchSnippets();
        }
      })
    }

    return (
      <div className="border-all snippet-item background-3 mb-15">
        <div>
          <label className="no-margin dark-color-white">{snippet.title}</label>
          <p className="font-12 opacity-7 no-margin">Location: {snippet.location}</p>
        </div>

        <div>
          <Snippet snippet={snippet} />
          <i className="default-padding fal fa-trash opacity-4 opacity-10-hover color-red-hover cursor-pointer animate" onClick={handleDelete}></i>
        </div>
      </div>
    )
  })

  return(
    <React.Fragment>
      <div className="container">
        <div className="border-top snippet-block-wrapper">
          <div className="row">
            <div className="col-12 col-md-5">
              <h4><i class="far fa-code mr-5"></i> Snippets</h4>
              <p>Add custom JavaScript to your client portal.</p>
            </div>

            <div className="col-12 col-md-7">
              {snippets_list}
            </div>
          </div>

          <div className="row mt-15">
            <div className="col-5">

            </div>
            <div className="col-7">
              <button onClick={() => setOpen(true)} className="btn btn-primary btn-block"><i className="fas fa-plus-circle mr-5"></i>Add snippet</button>

              <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
                <NewSnippet refetchData={fetchSnippets} closeModal={() => setOpen(false)}/>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Snippets