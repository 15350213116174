import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import Form from "./Form.js";
import Invoice from "./Invoice.js";
import animationData from "../Shared/Lotties/lottie-empty.json";
import { Player } from "@lottiefiles/react-lottie-player";
import RecurringInvoices from "./RecurringInvoices/RecurringInvoices.js";

const Invoices = ({}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [openRecurringInvoices, setRecurringInvoices] = useState(false);
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    fetchInvoices();
  }, []);

  const fetchInvoices = () => {
    axios.get(`/api/o/${match.params.organization_id}/invoices.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setInvoices(response.data.invoices);
        setLoaded(true);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }
  
  const invoices_list = invoices.map(invoice => {
    return(
      <Invoice invoice={invoice} refetchData={fetchInvoices}/>
    )
  })

  return(
    loaded && 
    <React.Fragment>
      <div className="container">
        <div className="text-right">
          <a onClick={() => setRecurringInvoices(true)} className='btn btn-small color-1 background-hover mr-15'><i class="fal fa-calendar-day mr-8"></i>Recurring invoices</a>
          <Modal open={openRecurringInvoices} onClose={() => setRecurringInvoices(false)} className="modal-body-white" classNames={{ modal: "width-800" }} center>
            <RecurringInvoices refetchData={() => {fetchInvoices();}} closeModal={() => setRecurringInvoices(false)}/>
          </Modal>

          <a onClick={() => setOpen(true)} className="btn btn-primary"><i class="far fa-plus mr-8"></i> New invoice</a>
          <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{ modal: "width-1000" }} center>
            <Form refetchData={() => {fetchInvoices();}} project_id={null} closeModal={() => setOpen(false)}/>
          </Modal>
        </div>

        <div className="row mt-25">
          {invoices.length > 0 ? (
            <table class="table table-responsive border-all table-bordered">
              <thead>
                <tr className="border-bottom">
                  <th className="react-table-th" scope="col"><i class="fal fa-file-invoice"></i>#</th>
                  <th className="react-table-th" scope="col"><i class="fal fa-calendar-plus"></i>Issued on</th>
                  <th className="react-table-th" scope="col"><i class="fal fa-calendar-day"></i>Due Date</th>
                  <th className="react-table-th" scope="col"><i class="fal fa-layer-group"></i>Project</th>
                  <th className="react-table-th" scope="col"><i class="fal fa-envelope"></i>Send to</th>
                  <th className="react-table-th" scope="col"><i class="fal fa-file-invoice-dollar"></i>Total</th>
                  <th className="react-table-th" scope="col"><i class="fal fa-donate"></i>Paid</th>
                  <th className="react-table-th" scope="col"><i class="fal fa-bullseye-arrow"></i>Status</th>
                  <th className="react-table-th" scope="col"></th>
                  <th className="react-table-th" scope="col"></th>
                </tr>
              </thead>

              <tbody>
                {invoices_list}
              </tbody>
            </table>
          ):(
            <p className="font-12 large-padding opacity-4 text-center">
              <Player style={{width: "300px", margin: "auto"}} mode="normal" autoplay loop src={animationData} />
              Create custom invoices to get paid outside of Stripe checkout. 
            </p>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Invoices;