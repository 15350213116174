import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import ChatroomItem from "./ChatroomItem.js";
import Pusher from 'pusher-js';
import EmailedTasks from "../EmailedTasks/EmailedTasks.js";
import { Modal } from 'react-responsive-modal';
import HowTo from "../EmailedTasks/HowTo.js";

const ChatroomList = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [chatrooms, setChatrooms] = useState([]);
  const [chatroomTypes, setChatroomTypes] = useState("Open");
  const [archived, setArchived] = useState(null); 
  const [open, setOpen] = useState(false);
  const [showChatrooms, setShowChatrooms] = useState(true);

  useEffect(() => {
    fetchChatrooms();
  }, []);

  useEffect(() => {
    const pusher = new Pusher('a5c19937d10212cddb4e', {
      cluster: 'us2',
      authEndpoint: '/your-auth-endpoint'
    });

    const pusherChannel = pusher.subscribe(`chatroom-list-${match.params.organization_id}`);
    pusherChannel.bind('event', (data) => {
      if (data.event === "fetchChatrooms") {
        console.log(`chatroom-list-${match.params.organization_id}`)
        if(!window.location.pathname.includes(data.chatroom_id)){
          fetchChatrooms()
        }
      }
    });

    return () => {
      pusherChannel.unbind_all();
      pusherChannel.unsubscribe();
    };
  }, [match.params.organization_id]);

  const fetchChatrooms = () => {
    axios.get(`/api/o/${match.params.organization_id}/chatrooms.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setChatrooms(response.data.chatrooms);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const chatrooms_list = chatrooms.filter(chatroom => chatroom.archive === archived).map(chatroom => {
    return(
      <ChatroomItem chatroom={chatroom} refetchData={fetchChatrooms} />
    )
  })

  return(
    <React.Fragment>
      <div className="row chatrooms-list-wrapper">
        <EmailedTasks />

        <div className="mb-15 display-flex align-items-center justify-content-between opacity-6 opacity-10-hover">
          <div class="dropdown show">
            <a class="btn color-1 btn-small background-hover margin-left-5px-negative" onClick={() => setShowChatrooms(!showChatrooms)}>
              {archived ? "Closed" : "Conversations"} 
              <i class={`fal ${showChatrooms ? "fa-angle-down" : "fa-angle-right"} ml-10`}></i>
            </a>
          </div>

          <div class="btn-group pull-right">
            <a type="button" class="btn btn-small color-1 background-hover dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fas fa-ellipsis-v"></i>
            </a>

            <ul class="dropdown-menu animated fadeInUp" role="menu">
              <li>
                <a class="dropdown-item" onClick={() => setArchived(null)}><i class="fal fa-box-open mr-5"></i>Open</a>
              </li>
              <li>
                <a class="dropdown-item" onClick={() => setArchived(true)}><i class="fal fa-archive mr-5"></i>Closed</a>
              </li>
            </ul>
          </div>
        </div>

        {showChatrooms && (
          <React.Fragment>
            {chatrooms.length > 0 ?(
              <React.Fragment>
                <label className="width-100-percent pl-15 font-12">Conversations</label>
                {chatrooms_list}
              </React.Fragment>
            ):(
              <p className="text-center medium-padding opacity-6 font-12">No conversations</p>
            )}
          </React.Fragment>
        )}

      </div>

      <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <HowTo />
      </Modal>
    </React.Fragment>
  )
}

export default ChatroomList;