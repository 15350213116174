import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import Toggle from 'react-toggle';

const RecurringInvoice = ({recurring_invoice_item, refetchData}) => {
  // const organizationAuthorizations = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [toggle, setToggle] = useState(!recurring_invoice_item.disabled);

  const handleSubmit = (boolean) => {
    setToggle(boolean);
    setDisabled(true);

    axios.put(`/api/o/${match.params.organization_id}/recurring_invoice_items/${recurring_invoice_item.token}`, {
      disabled: !boolean
    })
    .then(function(response){
      if(response.data.success){
        refetchData();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const deleteThis = () => {
    const confirmThis = confirm("Are you sure?")
    if(confirmThis){
      axios.delete(`/api/o/${match.params.organization_id}/recurring_invoice_items/${recurring_invoice_item.token}`)
      .then(function(response){
        console.log(response);
        if(response.data.success){
          notice("Updated successfully");
          refetchData();
        } else {
          response.data.errors.forEach((error) => {
            notice(error);
          });
        }
      })
      .catch(function(error){
        console.log(error)
        notice("An error occured");
        reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
      })
      .then(function () {
        setDisabled(false);
      });
    }
  }


  return(
    <>
      <tr className="background-3">
        <td>
          <Toggle checked={toggle} onChange={() => handleSubmit(!toggle)} icons={false}/>
        </td>

        {/* <td>
          {recurring_invoice_item.start_date}
        </td> */}

        <td>
          {recurring_invoice_item.last_sent}
        </td>
        
        <td>
          {numberToCurrency(parseInt(recurring_invoice_item.invoice_item_data.amount), recurring_invoice_item.invoice_item_data.currency)} every {recurring_invoice_item.invoice_item_data.interval}
        </td>

        <td>
          {recurring_invoice_item.next_bill}
        </td>


        <td className="display-flex">
          <Link to={`/o/${match.params.organization_id}/projects/${recurring_invoice_item.project.token}/details`} className="btn btn-small background-hover color-1 mr-8">
            <i class="fal fa-eye"></i>
          </Link>

          <div className="dropdown">
            <i className="dropdown-toggle btn btn-small background-hover fas fa-ellipsis-v opacity-4 opacity-10-hover" data-toggle="dropdown"></i>
            <ul className="dropdown-menu animated fadeInUp pull-right">
              <i className="fas fa-caret-up navbar-dropdown-arrow "></i>

              <li>
                <a onClick={deleteThis}>
                  <i class="fal fa-trash"></i>Delete recurring invoice
                </a>
              </li>
            </ul>
          </div>
        </td>
      </tr>
    </>
  )
}

export default RecurringInvoice;