import React , { useState } from 'react';
import { useRouteMatch } from 'react-router-dom'
import { Modal } from 'react-responsive-modal';
import File from "../../../Replays/Forms/File.js";
import WebsiteForm from "../../../Replays/Website/Form.js";
import RecentReplays from "./RecentReplays.js";
import ReplayRequestsIndex from "../../../Projects/RequestFiles/Index.js";
import { useTranslation } from 'react-i18next';
import LinkForm from "../../../Replays/Forms/LinkForm.js";

const Upload = ({refetchData, task_id, project_id, task}) => {
  const match = useRouteMatch();
  const [uploadFile, setUploadFile] = useState(false);
  const [openWebsiteForm, setOpenWebsiteForm] = useState(false)
  const [open, setOpen] = useState(false);
  const [requestOpen, setRequestOpen] = useState(false);
  const [openLink, setOpenLink] = useState(false);
  const { t } = useTranslation();

  return(
    <React.Fragment>
      <div class="btn-group">
        <button type="button" class="btn btn-primary background-blue btn-small dropdown-toggle" data-toggle="dropdown">
          {t("Upload")} <span class="caret ml-5"></span>
        </button>

        <ul class="dropdown-menu pull-right animated fadeInUp" role="menu">
          <li>
            <a onClick={() => setUploadFile(true)} className="nav-link foobar-youtube"><i class="fal fa-upload"></i>{t("File upload")}</a>
            <Modal open={uploadFile} onClose={() => setUploadFile(false)}  className="modal-body-white" classNames={{modal: 'width-800'}} center>
              <File closeModal={() => setUploadFile(false)} organization_id={match.params.organization_id} project_id={project_id || match.params.project_id} folder_id={match.params.folder_id} task_id={task_id} refetchData={refetchData}/>
            </Modal>
          </li>

          <li>
            <a className="nav-link foobar-youtube" onClick={() => setOpenWebsiteForm(true)}><i className="far fa-globe"></i>{t("Live website")}</a>
            <Modal open={openWebsiteForm} onClose={() => setOpenWebsiteForm(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
              <WebsiteForm closeModal={() => setOpenWebsiteForm(false)} task_id={task_id} refetchData={refetchData} project_id={project_id}/>
            </Modal>
          </li>

          <li>
            <a className="nav-link" onClick={() => setOpenLink(true)}><i className="far fa-link"></i>Direct link</a>
            <Modal open={openLink} onClose={() => setOpenLink(false)}  className="modal-body-white" classNames={{modal: 'width-500'}} center>
              <LinkForm closeModal={() => setOpenLink(false)} project_id={project_id} task_id={task?.token} refetchData={refetchData}/>
            </Modal>
          </li>

          <li class="divider"></li>

          <li>
            <a onClick={() => setRequestOpen(true)} className="foobar-request-files"><i className="far fa-file-export"></i> {t("Request files")}</a>
            <Modal open={requestOpen} onClose={() => setRequestOpen(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
              <ReplayRequestsIndex setOpen={setRequestOpen} task={task}/>
            </Modal>
          </li>

          <li>
            <a className="nav-link" onClick={() => setOpen(true)}><i className="far fa-list"></i>{t("All Files")}</a>
            <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
              <RecentReplays task_id={task_id} refetchData={refetchData} project_id={project_id}/>
            </Modal>
          </li>
        </ul>
      </div>
    </React.Fragment>
  )
}

export default Upload