import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import Select from 'react-select';
import {ProjectContext} from "../Projects/ProjectContext.js";
import Linkify from 'react-linkify';

const EmailedTaskShow = ({}) => {
  // const organizationAuthorizations = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const {projects, fetchChatroomsUnread} = useContext(ProjectContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [move, setMove] = useState(false);
  // const [projects, setProjects] = useState([]);
  const [emailedTask, setEmailedTask] = useState(null);
  const [columns, setColumns] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [users, setUsers] = useState([]);
  const [usedAi, setUsedAi] = useState(false);
  const [generating, setGenerating] = useState(false);

  useEffect(() => {
    fetchEmailedTask();
    setMove(false);
  }, [match.params.emailed_task_id]);

  const fetchEmailedTask = () => {
    axios.get(`/api/o/${match.params.organization_id}/emailed_tasks/${match.params.emailed_task_id}.json`)
    .then(function(response){
    //   console.log(response);
      if(response.data.success){
        setEmailedTask(response.data.emailed_task);
        setTitle(response.data.emailed_task.subject);
        setDescription(response.data.emailed_task.body);
        if(response.data.emailed_task.project){
          handleProjectSelect({
            value: response.data.emailed_task.project.token,
            label: response.data.emailed_task.project.title
          })
        }
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const performArchive = () => {
    setDisabled(true);

    axios.delete(`/api/o/${match.params.organization_id}/emailed_tasks/${match.params.emailed_task_id}`)
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        // refetchData();
        fetchChatroomsUnread();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/portal/projects/${selectedProject?.value}/tasks`, {
      column_id: selectedColumn?.value,
      title: title, 
      description: description, 
      from: emailedTask.from,
      username: emailedTask.email_data.FromFull.Name,
      assignee_id: selectedUser?.value,
      used_ai: usedAi,
      // priority: priority, 
      // date: date, 
      // parent_task_id: parent_task_id, 
      // attachements: attachements, 
      // private: isPrivate
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        performArchive()
        history.push(`/o/${match.params.organization_id}/projects/${selectedProject?.value}/tasks?task_id=${response.data.task.token}`);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const projectsOptions = projects.map((item) => ({
    value: item.token,
    label: item.title,
  }));

  const columnsOptions = columns.map((item) => ({
    value: item.token,
    label: item.title,
  }));

  const usersOptions = users.map((item) => ({
    value: item.id,
    label: item.username,
  }));

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black', // Text color of options
    }),
  };

  const handleProjectSelect = (selectedOption) => {
    setSelectedColumn(null);

    if(selectedOption.value == null){
      setSelectedProject(null)
    } else {
      setSelectedProject(selectedOption);
      fetchUsers(selectedOption.value);
      axios.get(`/api/portal/projects/${selectedOption.value}/columns.json`)
      .then(function (response) {
        console.log(response);
        if (response.data.success) {
          setColumns(response.data.columns);
        } else {

        }
      })
      .catch(function (error) {
        console.log(error)
        notice("An error occurred")
      })
      .then(function () {
        // always executed
      });
    }
  };

  const handleColumnSelect = (selectedOption) => {
    if(selectedOption.value == null){
      setSelectedColumn(null)
    } else {
      setSelectedColumn(selectedOption);
    }
  };

  const handleUserSelect = (selectedOption) => {
    if(selectedOption.value == null){
      setSelectedUser(null)
    } else {
      setSelectedUser(selectedOption);
    }
  }

  const fetchUsers = (project_id) => {
    axios.get(`/api/o/${match.params.organization_id}/projects/${project_id}/users.json`)
      .then(function(response) {
        if (response.data.success) {
          setUsers(response.data.users);  
        } else {
          console.log('Failed to fetch users');
        }
      })
      .catch(function(error) {
        console.log('Error fetching users:', error);
      });
  };

  const handleGenerateDescription = () => {
    setGenerating(true);
    axios.post(`/api/o/${match.params.organization_id}/openais/chat_completion`, {
      user_message: emailedTask.body,
      system_message: "You are an assistant whose job is to take the email and generate a description for a task. The email is from a client and the description should be in the client's voice. The email may be a forward, so you should focus on the most important parts of the email. If the email mentions to look at any links, please include it. You don't have a limit on the length of the description, so you should include as much detail as possible, but no more than 200 words. "
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setDescription(response.data.response.choices[0].message.content);
        setUsedAi(true);
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setGenerating(false);
    });
  }


  return(
    emailedTask && 
    <React.Fragment>
      {!move ? (
        <div className="emailed-task-wrapper">
          <div className="border-radius border-all animated fadeInUp default-padding background-3 width-100-percent" style={{maxWidth: 800}}>
            <div className="emailed-task-header">
              <div>
                <h5 className="max-width-500 truncate">{emailedTask.subject}</h5>
                <p className="opacity-7 font-13">
                  {emailedTask.from}
                  <span className="ml-15">• {emailedTask.created_at}</span>
                </p>
              </div>

              <a onClick={() => setMove(true)} className="btn btn-primary">
                <i class="fal fa-share mr-8"></i>Move to Project
              </a>
            </div>

            <div className="emailed-task-body border-top">
              {/* <Linkify>
                {emailedTask.body}
              </Linkify> */}

              <iframe
                id="email-content"
                src={`/api/o/${match.params.organization_id}/emailed_tasks/${match.params.emailed_task_id}`}
                style={{
                  width: '100%',
                  height: 600,
                  border: 'none',
                  overflow: 'hidden',
                  backgroundColor: 'white',
                  borderRadius: 5
                }}
              />
            </div>
          </div>
        </div>
      ):(
        <form onSubmit={handleSubmit} className="emailed-task-wrapper">
          <div className="border-radius border-all animated fadeInUp default-padding background-3 width-100-percent" style={{maxWidth: 800}}>
            <h4 className="mb-25">Which project should we move it to? </h4>
            <div className="field">
              <label>Project</label>
              <Select
                options={projectsOptions}
                value={selectedProject}
                onChange={handleProjectSelect}
                className="color-1"
                classNamePrefix="react-select"
                placeholder="Select..."
                styles={customStyles} 
              />
            </div>

            {selectedProject && 
              <div className="field animated fadeInUp">
                <label>Column</label>
                <Select
                  options={columnsOptions}
                  value={selectedColumn}
                  onChange={handleColumnSelect}
                  className="color-1"
                  classNamePrefix="react-select"
                  placeholder="Select..."
                  styles={customStyles} 
                />
              </div>
            }
            
            {selectedColumn &&
            <>
              <div className="field">
                <label>Task Creator</label>
                <Select
                  options={usersOptions}
                  value={selectedUser}
                  onChange={handleUserSelect}
                  className="color-1"
                  classNamePrefix="react-select"
                  placeholder="Select..."
                  styles={customStyles} 
                />
              </div>

              <div className="field">
                <label>Task title</label>
                <input className="form-control" defaultValue={title} onChange={(e) => setTitle(e.target.value)}/>
              </div>

              <div className="field">
                <div className="display-flex align-items-center space-between mb-10">
                  <label>Task description</label>
                  <div class="button-wrapper">
                    {usedAi ? (
                      <a className="btn btn-small color-1 border-radius background-3" onClick={() => {setDescription(emailedTask.body); setUsedAi(false)}}>
                        <i class="fal fa-undo mr-8"></i>Undo
                      </a>
                    ):(
                      <a className="btn btn-small color-1 border-radius background-3" onClick={handleGenerateDescription} disabled={generating}>
                        {generating ? (
                          <>
                            <i class="fal fa-spinner fa-spin mr-8"></i>Generating...
                          </>
                        ):(
                          <>
                            <i class="fal fa-magic mr-8"></i>Use AI to generate description
                          </>
                        )}
                      </a>
                    )}
                    <div class="button-bg"></div>
                  </div>
                </div>
                <textarea style={{resize: true}} className="form-control" rows="10" defaultValue={description} value={description} onChange={(e) => setDescription(e.target.value)}/>
              </div>

              <div className="field text-right">
                <button disabled={disabled || !selectedProject || !selectedColumn || !selectedUser} type="submit" className="btn btn-primary">
                  {t("Create Task")}
                </button>
              </div>
            </>
            }
          </div>
        </form>
      )}
    </React.Fragment>
  )
}

export default EmailedTaskShow;