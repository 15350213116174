import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import ClientOnboarding from "./ClientOnboarding.js";
import { CircularProgressbar } from 'react-circular-progressbar';

const ClientOnboardings = ({forms, customClass, owner, refetchData}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(!localStorage.getItem(`client-onboardings-${match.params.organization_id}`) || location.href.includes("settings/client-onboarding"));
  const [progress, setProgress] = useState(((forms.filter(item => item.client_onboarding_reads.length > 0).length) / forms.length) * 100);

  useEffect(() => {
    const progressPercentage = ((forms.filter(item => item.client_onboarding_reads.length > 0).length) / forms.length) * 100;
    setProgress(Math.round(progressPercentage));
  }, [forms]);

  const forms_list = forms.map(form => {
    return (
      <ClientOnboarding form={form} refetchData={refetchData} owner={owner}/>
    )
  })


  return(
    showOnboarding ?(
      <React.Fragment>
        <div className={`client-onboarding-checklist-box default-padding ${customClass} animated fadeInUp background-3 border-all animated fadeInUp`} style={{marginLeft: 5}}>
          <div className="client-onboarding-checklist-header">
            Onboarding Checklist
            <i className="fas fa-times client-onboarding-close-icon" onClick={() => {localStorage.setItem(`client-onboardings-${match.params.organization_id}`, true); setShowOnboarding(false)}}></i>
          </div>

          <div className="client-onboarding-checklist-progress-bar row">
            <div className="client-onboarding-checklist-progress-bar-amount col-2">{forms.filter(item => item.client_onboarding_reads.length > 0).length}/{forms.length}</div>
            <div class="progress col-10">
              <div class="progress-bar" role="progressbar" aria-valuenow="5" aria-valuemin="0" aria-valuemax="100" style={{width: `${progress}%`}}>
                <span class="sr-only">5% Complete</span>
              </div>
            </div>
          </div>

          <div className="client-onboarding-checklist-item">
            {forms_list}
          </div>
        </div>
      </React.Fragment>
    ) :(
      <React.Fragment>
        {(progress != 100 || location.pathname.includes("details")) &&
          <div className="client-onboarding-progress background-3 box-shadow animated zoomIn" onClick={() => {setShowOnboarding(true)}}>
            <CircularProgressbar value={progress} text={`${progress}%`} />
          </div>
        }
      </React.Fragment>
    )
  )
}

export default ClientOnboardings;