import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import Discount from "./Discount.js";
import DiscountForm from "./DiscountForm.js";
import "@lottiefiles/lottie-player";
import Stripe from "../Stripe/Stripe.js";
import DashboardSkeleton from "../Seller/DashboardSkeleton.js";
import {OrganizationContext} from "../../Shared/OrganizationContext.js";
import PaypalDiscount from "../PaypalAccounts/PaypalDiscount.js";

const Discounts = (props) => {
  const organizationAuthorizations = useContext(OrganizationContext);
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [stripeAccount, setStripeAccount] = useState(null);

  useEffect(() => {
    fetchCoupons();
  }, []);

  const fetchCoupons = () => {
    axios.get(`/api/o/${match.params.organization_id}/coupons.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setCoupons(response.data.coupons);
        setStripeAccount(response.data.stripe_account)
        setLoaded(true)
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const coupons_list = coupons.map(coupon => {
    return(
      <Discount coupon={coupon} refetchData={fetchCoupons}/>
    )
  })


  return(
    loaded ? (

      <React.Fragment>
        <title>Discounts</title>
          <div className="row no-margin">
            <div className="col-12 daas-cta">
              {stripeAccount != null && 
                <>
                  <a className="btn btn-primary" onClick={() => setOpen(true)}><i class="far fa-tag mr-8"></i>Add discount</a>
                  <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-600'}} center>
                    <DiscountForm closeModal={() => setOpen(false)} refetchData={fetchCoupons} currency={stripeAccount.stripe_account_data.default_currency}/>
                  </Modal>
                </>
              }

              {organizationAuthorizations.organization.paypal_account != null && 
                <>
                  <a className="btn btn-primary" onClick={() => setOpen(true)}><i class="far fa-tag mr-8"></i>Add discount</a>
                  <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-600'}} center>
                    <PaypalDiscount refetchData={fetchCoupons} closeModal={() => setOpen(false)}/>
                  </Modal>
                </>
              }
            </div>

            {/* Discounts */}

            <div className="col-12 mt-15">
              {((stripeAccount != null) || (organizationAuthorizations.organization.paypal_account != null)) ? (
                <React.Fragment>
                  {coupons.length > 0 ? (
                    <table class="background-3 table table-bordered table-responsive">
                      <thead className="background-2">
                        <tr>
                          <th className="service-item-td" scope="col">Title</th>
                          <th className="service-item-td" scope="col">Code</th>
                          <th className="service-item-td" scope="col">Terms</th>
                          <th className="service-item-td" scope="col">Services</th>
                          <th className="service-item-td" scope="col">Expiration</th>
                          <th className="service-item-td" scope="col">Created</th>
                          <th className="service-item-td" scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {coupons_list}
                      </tbody>
                    </table>
                  ):(
                    <div className="information-wrapper">
                      {/* <lottie-player class="information-lottie" autoplay loop mode="normal" src="https://assets8.lottiefiles.com/packages/lf20_yseim94k.json"></lottie-player> */}
                      Create coupon codes to deduct a fixed amount or percentage from service transactions.
                    </div>
                  )}
                </React.Fragment>
              ):(
                <div className="stripe-flex-container">
                  <Stripe title="Add coupons to your services" description="Connect your Stripe account to get started" refetchData={fetchCoupons}/>
                </div>
              )}
            </div>
          </div>
      </React.Fragment>
    ):(
      <DashboardSkeleton />
    )
  )
}

export default Discounts