import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import {OrganizationContext} from "../Shared/OrganizationContext.js"
import axios from "axios"; 
import { PickerInline } from 'filestack-react';
import { Modal } from 'react-responsive-modal';
import {UserContext} from "../Shared/UserContext.js";

const EditWorkspace = ({}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const currentUser = useContext(UserContext);
  const [newImage, setNewImage] = useState(null);
  const organizationAuthorizations = useContext(OrganizationContext);
  const [disabled, setDisabled] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [organization, setOrganization] =  useState();
  const [title, setTitle] = useState("");
  const [avatar, setAvatar] = useState(""); // New state for avatar
  const [favicon, setFavicon] = useState("");
  const [subdomain, setSubdomain] = useState(""); // New state for subdomain
  const [open, setOpen] = useState(false);
  const [openFavicon, setOpenFavicon] = useState(false);
  const [isTaken, setIsTaken] = useState(false);

  const createReplay = (filestack) => {
    const filesUploaded = filestack.filesUploaded;
    const imageFiles = Object.values(filesUploaded).filter(file => file.mimetype.startsWith('image/'));
    if (imageFiles.length > 0) {
      const imageFile = imageFiles[0];
      console.log(imageFile);
      setAvatar(imageFile.url);
    } else {
      console.log('No image file uploaded');
    }
  }

  const createFavicon = (filestack) => {
    const filesUploaded = filestack.filesUploaded;
    const imageFiles = Object.values(filesUploaded).filter(file => file.mimetype.startsWith('image/'));
    if (imageFiles.length > 0) {
      const imageFile = imageFiles[0];
      console.log(imageFile);
      setFavicon(imageFile.url);
    } else {
      console.log('No image file uploaded');
    }
  }

  useEffect(() => {
    fetchOrganization()
  }, [])

  const fetchOrganization = () => {
    axios.get(`/api/o/${match.params.organization_id}.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setOrganization(response.data.organization);
        setAvatar(response.data.organization.avatar);
        setFavicon(response.data.organization.favicon)
        setTitle(response.data.organization.title);
        setSubdomain(response.data.organization.subdomain);
        setLoaded(true);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();

    axios.put(`/api/o/${match.params.organization_id}`, {
      title: title, 
      logo_link: avatar, 
      favicon: favicon
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        notice("Updated successfully");
        fetchOrganizationAuthorizations(); // global one
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const updateSubdomain = (evt) => {
    evt.preventDefault();

    axios.put(`/api/o/${match.params.organization_id}`, {
      subdomain: subdomain
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        notice("Updated successfully");
        window.location.href = `https://${response.data.organization.subdomain}.usequeue.com/o/${response.data.organization.token}/settings/company?token=${currentUser.token}`
        fetchOrganizationAuthorizations(); // global one
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const handleChange = (event) => {
    const newSubdomain = event.target.value.replace(/\s/g, ''); // Remove whitespaces
    setSubdomain(newSubdomain);
    checkIfSubdomainTaken(newSubdomain); // Check if subdomain is taken
  };

  const checkIfSubdomainTaken = (subdomain) => {
    setDisabled(true);
    axios.get(`/api/verify_subdomain?subdomain=${subdomain}`)
      .then((response) => {
        console.log(response)
        setIsTaken(response.data.taken);
        setDisabled(false);
      })
      .catch((error) => {
        setIsTaken(false); // Request failed, treat subdomain as not taken
      });
  };


  return(
    loaded && 
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <div className="">
          <div className="background-3 border-all border-radius default-padding">
            <h4 className="font-weight-600 pl-15">Company settings</h4>
            <p className="opacity-7 text-12 pl-15" style={{marginTop: "-5px"}}>Update your company information that will appear to you and your clients</p> 

            {/* Add Title field */}
            <div className="field border-bottom default-padding row">
              <div className="col-12 col-md-4">
                <div className="font-weight-600 ">
                  Company name
                </div>

                <div className="opacity-7 ">
                  Appears on receipts, invoices, and client portal.
                </div>
              </div>

              <div className="col-12 col-md-8">
                <input className="form-control form-control-dark" placeholder="ACME INC." value={title} onChange={(e) => setTitle(e.target.value)} required="true"/>
              </div>
            </div>

            {/* Favicon */}
            <div className="field border-bottom default-padding row">
              <div className="col-12 col-md-4">
                <div className="font-weight-600 ">
                  Favicon
                </div>
                <div className="opacity-7 ">
                  Upload a favicon to show. If you do not choose one, the Queue logo will be the default.
                </div>
              </div>
              <div className="col-12 col-md-8 text-right">
                <img className="border-radius mr-10" src={favicon} style={{width: "30px", height: "30px"}}/>
                <a onClick={() => setOpenFavicon(true)} className="btn btn-secondary btn-small">
                  16x16 pixel image
                </a>
                
                <Modal open={openFavicon} onClose={() => setOpenFavicon(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
                  <PickerInline
                    apikey={"AKVhxMbEQhkOIryqBvSEQz"}
                    onSuccess={(res) => console.log(res)}
                    onUploadDone={(res) => {
                      createFavicon(res);
                    }}
                    clientOptions={{
                      sessionCache: true
                    }}
                    pickerOptions={{
                      onClose: () => {
                        setOpen(false);
                      }, 
                      fromSources: ["local_file_system", "googledrive", "dropbox", "box", "onedrive", "onedriveforbusiness", "googlephotos", "facebook", "instagram"],
                      maxFiles: 1,
                      accept: 'image/*'
                    }}
                  />
                </Modal>
              </div>
            </div>

            {/* Add Avatar Field */}
            <div className="field border-bottom default-padding row">
              <div className="col-12 col-md-4">
                <div className="font-weight-600 ">
                  Avatar
                </div>
                <div className="opacity-7 ">
                  Upload your company avatar.
                </div>
              </div>
              <div className="col-12 col-md-8 text-right">
                <img className="border-radius mr-10" src={avatar} style={{width: "30px", height: "30px"}}/>
                <a onClick={() => setOpen(true)} className="btn btn-secondary btn-small">
                  Add preview image
                </a>
                
                <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
                  <PickerInline
                    apikey={"AKVhxMbEQhkOIryqBvSEQz"}
                    onSuccess={(res) => console.log(res)}
                    onUploadDone={(res) => {
                      createReplay(res);
                    }}
                    clientOptions={{
                      sessionCache: true
                    }}
                    pickerOptions={{
                      onClose: () => {
                        setOpen(false);
                      }, 
                      fromSources: ["local_file_system", "googledrive", "dropbox", "box", "onedrive", "onedriveforbusiness", "googlephotos", "facebook", "instagram"],
                      maxFiles: 1,
                      accept: 'image/*'
                    }}
                  />
                </Modal>
              </div>
            </div>

            <div className="field text-right">
              <button className="btn btn-primary" type="submit" disabled={disabled || isTaken}>Update</button>
            </div>
          </div>
        </div>
      </form>

      {/* Subdomain */}
      {(window.location.hostname.includes("usequeue") || window.location.hostname.includes("ngrok")) &&
        <form className="border-all background-3 default-padding mt-15" onSubmit={updateSubdomain}>
          <div className="field row">
            <div className="col-12 col-md-4">
              <div className="font-weight-600 ">
                Subdomain
              </div>
              <div className="opacity-7 ">
                Your company's subdomain.
              </div>
            </div>
            <div className="col-12 col-md-8">
              <div class="input-group">
                <span class="input-group-addon form-control-white" id="basic-addon1"><i class="fa fa-globe"></i></span>
                <input type="text" class="form-control form-control-dark" placeholder="Subdomain" aria-describedby="basic-addon2" value={subdomain} onChange={handleChange}/>
                <span class="input-group-addon" id="basic-addon2">.usequeue.com</span>
              </div>
              {isTaken && <p className="color-red font-10">Subdomain is taken. Please choose a different one.</p>}
            </div>

            <div className="text-right mt-15">
              <button className="btn btn-primary" type="submit" disabled={disabled || isTaken}>Update subdomain</button>
            </div>
          </div>
        </form>
      }
    </React.Fragment>
  )
}

export default EditWorkspace;