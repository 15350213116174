import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import TaskForms from "./TaskForms.js";
import { Modal } from 'react-responsive-modal';

const TaskForm = ({service_form, refetchData, services}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);

  // Delete the service form
  const deleteServiceForm = () => {
    axios.delete(`/api/o/${match.params.organization_id}/service_forms/${service_form.token}.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData();
      }
    })
  }
  const service_form_users_list = service_form.service_form_users.map(service_form_user => {
    return(
     <li>
        <img src={service_form_user.avatar} className="avatar mr-8 background-3 border-all" style={{marginLeft: -15}}/>
     </li> 
    )
  })

  return(
    <React.Fragment>
      <div className="col-12 col-md-3 hover-parent-opacity">
        <div className=" task-form-item border-all">
          <div className="task-form-item-top background-3">
            <div className="font-weight-600 truncate width-100-percent text-center">
              {service_form.title}
            </div>

            <div className="font-13 mt-5 opacity-6 truncate width-100-percent text-center">
              {service_form.service != null ? service_form.service.title : "Universal"}
            </div>

            <div className="display-flex list-unstyled mt-10">
              {service_form_users_list}
            </div>
          </div>

          <div className="task-form-item-bottom border-top background-4">
            <div className="opacity-7 width-100-percent text-center">
              {service_form.questions.length} Questions
            </div>

            <div class="btn-group hover-child-opacity animate">
              <a type="button" class="dropdown-toggle padding-8" data-toggle="dropdown">
                <i class="fas fa-ellipsis-v color-1"></i>
              </a>
              <ul class="dropdown-menu animated fadeInUp pull-right" role="menu">
                <li><a onClick={() => setOpen(true)}>Edit</a></li>
                <li><a onClick={deleteServiceForm}>Delete</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* <tr className="border-bottom">
        <td className="font-weight-600">{service_form.title}</td>
        <td>{service_form.service.title}</td>
        <td>{service_form.created_at}</td>
        <td className="text-right">
          
        </td>
      </tr> */}

      <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
        <h4 className="color-black-always modal-title">Add task form</h4>
        <p className="modal-description">Task forms are questions your client will be asked when they submit new task requests.</p>
        <div className="row no-margin">
          <TaskForms refetchData={refetchData} closeModal={() => setOpen(false)} serviceForm={service_form}/>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default TaskForm