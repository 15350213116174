import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import "@lottiefiles/lottie-player";

const Home = () => {
  return(
    <React.Fragment>
      <div className="projects-home">
        <lottie-player autoplay loop mode="normal" src="https://assets5.lottiefiles.com/packages/lf20_0jQBogOQOn.json" style={{height: "300px", width: "300px"}}></lottie-player>
        <p className="color-1 opacity-7 text-center">
          Collaborate with your team and clients on any type of file or website.
        </p>
      </div>
    </React.Fragment>
  )
}

export default Home