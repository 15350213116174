import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import ChatroomItemDropdown from "./ChatroomItemDropdown.js";

const ChatroomItem = ({chatroom, refetchData}) => {
  const history = useHistory();
  const match = useRouteMatch();

  return(
    <React.Fragment>
      <NavLink to={`/o/${match.params.organization_id}/chatrooms/${chatroom.token}`} activeClassName="background-active" className="color-1 background-hover border-radius animate mb-5">
        <div className="chatroom-item-wrapper hover-parent-opacity">
          <div className="chatroom-item-title">
            <div>
              {chatroom.title}
              {chatroom.unread_messages > 0 && 
                <span className="unread-count">{chatroom.unread_messages}</span>
              }
            </div>

            <div className="hover-child-opacity">
              <ChatroomItemDropdown chatroom={chatroom} refetchData={refetchData} />
            </div>
          </div>

          <div className="chatroom-item-message">
            <div>
              {chatroom.last_message != null ?(
                chatroom.last_message
              ):(
                "No message"
              )}
            </div>

            <div className="font-10 opacity-6">
              {chatroom.created_at_time_ago_in_words != null && 
                <React.Fragment>
                  {chatroom.created_at_time_ago_in_words}
                </React.Fragment>
              }
            </div>
          </div>
        </div>
      </NavLink>
    </React.Fragment>
  )
}

export default ChatroomItem;