import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import Currency from "../Discounts/Currency.js"
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";

const PaypalDiscount = ({refetchData}) => {
  // const organizationAuthorizations = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [code, setCode] = useState('');
  const [title, setTitle] = useState('');
  const [amountOff, setAmountOff] = useState();
  const [services, setServices] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [type, setType] = useState("Fixed");
  const [selectedCurrency, setSelectedCurrency] = useState('');

  useEffect(() => {
    fetchServices();
  },[])

  const fetchServices = () => {
    axios.get(`/api/o/${match.params.organization_id}/services.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setServices(response.data.services)
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const handleSelectChange = (event) => {
    const selectedOption = event.target.value;
    
    // Check if the selected option is already in the array
    if (!selectedOptions.includes(selectedOption)) {
      setSelectedOptions((prevOptions) => [...prevOptions, selectedOption]);
    }
  };

  const handleRemoveOption = (optionToRemove) => {
    setSelectedOptions((prevOptions) =>
      prevOptions.filter((option) => option !== optionToRemove)
    );
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/o/${match.params.organization_id}/coupons`, {
      title: title, 
      code: code, 
      selected_services: selectedOptions, 
      type: type, 
      paypal_amount: amountOff,
      currency: selectedCurrency
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData(); 
        closeModal();
      } else {
        notice(response.data.error)
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }
  
  const services_list = services.map(service => {
    return(
      <option value={service.token}>{service.title}</option>
    )
  })

  const selected_list = selectedOptions.map((selected) => {
    return (
      <span className="badge badge-primary mr-5 mt-10" key={selected}>
        {selected}
        <i className="fas fa-times cursor-pointer ml-10" onClick={() => handleRemoveOption(selected)}></i>
      </span>
    );
  });


  return(
    <React.Fragment>
      <h4 className="color-black-always modal-title">Add discount</h4>

      <div className="default-padding alert-warning mb-20"><i class="fas fa-bell-exclamation mr-15"></i>PayPal limits us to coupons that support only fixed-price checkout.</div>

      <div className="row no-margin">
        <form onSubmit={handleSubmit}>
          <div className="field">
            <label>Name</label>
            <input className="form-control" placeholder="This is so you can identify it." required={true} defaultValue={title} value={title} onChange={(e) => setTitle(e.target.value)}/>
            <p className="daas-form-control-info">This will appear on customers' receipts and invoices.</p>
          </div>

          <div className="field">
            <label>ID</label>
            <input className="form-control" placeholder="No spaces allowed" required={true} defaultValue={code} value={code} onChange={(e) => setCode(e.target.value)} />
            <p className="daas-form-control-info">This is what your customer will enter to redeem.</p>
          </div>

          <div className="field row">
            <div className="col-12 col-md-6 pr-15">
              <label>Amount off</label>
              <div className="input-group">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Amount to discount"
                  required={true}
                  defaultValue={amountOff} value={amountOff} onChange={(e) => setAmountOff(e.target.value)}
                />
              </div>
            </div>

            <div className="field col-12 col-md-6">
              <Currency setSelectedCurrency={setSelectedCurrency} />
            </div>
          </div>

          <div className="field">
            <div className="col-12">
              <label>Service</label>
              <select value={selectedOptions} onChange={handleSelectChange}>
                <option value="">Select services</option>
                {services_list}
              </select>

              {selected_list}
            </div>
          </div>

          <div className="field text-right">
            <button className="btn btn-primary" type="submit">{t("Submit")}</button>
          </div>
        </form>
      </div>
    </React.Fragment>
  )
}

export default PaypalDiscount;