import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import Toggle from 'react-toggle';
import { Modal } from 'react-responsive-modal';
import EditDiscount from "./EditDiscount.js";

const Discount = ({coupon,refetchData}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false);

  const handleDelete = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.delete(`/api/o/${match.params.organization_id}/coupons/${coupon.token}`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData();
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const formatUnixToDate = (unixTimestamp) => new Date(unixTimestamp * 1000).toISOString().split('T')[0];

  const services_list = coupon.services.map(service => {
    return(
      <option value={service.token}>{service.title}</option>
    )
  })

  return(
    <React.Fragment>
      <tr>
        <td className="service-item-td">{coupon.title}</td>
        <td className="service-item-td font-weight-600 color-blue">{coupon.code}</td>
        <td className="service-item-td">{coupon.terms}</td>
        <td className="service-item-td">
          {coupon.services.length > 0 ? (
            <select className="form-control-select-small background-3 border-all badge badge-default">
              {services_list}
            </select>
          ):(
            <span className="opacity-4">No services added</span>
          )}
        </td>
        <td className="service-item-td">{coupon.stripe_data?.redeem_by != null ? formatUnixToDate(coupon.stripe_data?.redeem_by) : "Never"}</td>
        <td className="service-item-td">{coupon.created_at}</td>
        <td className="service-item-td text-right">
          <div class="btn-group">
            <a type="button" class="btn btn-small color-1 dropdown-toggle opacity-6 opacity-10-hover" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fas fa-ellipsis-v"></i>
            </a>
            <ul class="dropdown-menu pull-right animated fadeInUp">
              <li>
                <a onClick={handleDelete}>Delete</a>
              </li>
            </ul>
          </div>
        </td>
      </tr>
    </React.Fragment>
  )
}

export default Discount