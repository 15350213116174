import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axios from 'axios'; // Import Axios

class ErrorBoundary extends React.Component {
  constructor(props) {
    console.log(props);
    super(props);
    this.state = {
      hasError: false,
      value: '',
      copied: false,
      consoleLogs: [], // Added state for capturing console logs
    };
    this.captureConsoleLog(); // Start capturing console logs
  }

  // Function to capture console logs
  captureConsoleLog() {
    const originalConsoleLog = console.log;
    const that = this;

    console.log = function (...args) {
      originalConsoleLog.apply(console, args); // Log to the console as usual
      const logMessage = args.map((arg) =>
        typeof arg === 'object' ? JSON.stringify(arg) : arg
      ).join(' '); // Convert log arguments to string
      that.setState((prevState) => ({
        consoleLogs: [...prevState.consoleLogs, logMessage], // Store the log message
      }));
    };
  }

  componentDidCatch(error, info) {
    console.log(error);
    this.setState(
      {
        hasError: true,
        value: `${error}`,
      },
      () => {
        // Handle the error here and make an Axios POST request
        axios
          .post('/record_react_error', {
            errorInfo: this.state.value,
            // current_user: this.props.current_user
          })
          .then((response) => {
            console.log('Error data sent successfully:', response.data);
          })
          .catch((error) => {
            console.error('Error sending error data:', error);
          });

        localStorage.removeItem('location');
      }
    );
  }

  render() {
    if (this.state.hasError) {
      return (
        <React.Fragment>
          <div className="container-fluid" style={{ paddingTop: '100px' }}>
            <h1 className="color-white-real col-xs-12 field text-center">
              You've gotten an error. 
            </h1>
            <p className="text-center">This is embarassing, but our engineers has been notified and will remedy the issue within 24 hours.</p>
            <div className="col-md-12 col-12 col-xs-6 default-padding">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control color-red font-11"
                  value={this.state.value}
                  onChange={({ target: { value } }) =>
                    this.setState({ value, copied: false })
                  }
                />
                <span class="input-group-btn">
                  <CopyToClipboard
                    text={this.state.value}
                    onCopy={() => this.setState({ copied: true })}
                  >
                    <button
                      class={`btn ${
                        this.state.copied ? 'btn-success' : 'btn-default'
                      }`}
                    >
                      {this.state.copied ? (
                        <React.Fragment>Copied</React.Fragment>
                      ) : (
                        <React.Fragment>Copy error code</React.Fragment>
                      )}
                    </button>
                  </CopyToClipboard>
                </span>
              </div>
              <p className="font-12">
                Please use the Intercom chat on the bottom right corner of your
                screen to get immediate 24/7 chat support.{' '}
              </p>

              <p className="text-center">
                or
                <br />
                <a className="color-blue" href="/">
                  Go home
                </a>
              </p>
            </div>
          </div>
        </React.Fragment>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
