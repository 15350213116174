import React , { useState, useEffect, useRef, useContext } from 'react';
import { useRouteMatch, Link } from 'react-router-dom'
import Message from "./Message"
import axios from "axios"; 
import {UserContext} from "../Shared/UserContext.js";
import Pusher from 'pusher-js';
import Users from "../Projects/Users.js";

const Show = ({chatroomWrapperClass, showHeader}) => {
  const match = useRouteMatch();
  const currentUser = useContext(UserContext);
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [chatroom, setChatroom] = useState(null);
  const [messages, setMessages] = useState([]);
  const [body, setBody] = useState("");
  const messagesContainerRef = useRef(null);
  const inputRef = useRef(null);
  const [hey, setHey] = useState("hehe")
  const [onlineUsers, setOnlineUsers] = useState([]);

  useEffect(() => {
    fetchMessages();
  }, [match.params.chatroom_id]);

  useEffect(() => {
    const pusher = new Pusher('a5c19937d10212cddb4e', {
      cluster: 'us2',
      authEndpoint: '/your-auth-endpoint' // Make sure this is correctly configured
    });

    // Regular channel for messages
    const messageChannel = pusher.subscribe(`chatroom-${match.params.chatroom_id}`);
    messageChannel.bind('event', (data) => {
      // Handle message event
      if (data.user_id !== currentUser.id) {
        fetchMessages();
      }
    });

    return () => {
      messageChannel.unbind_all();
      messageChannel.unsubscribe();
    };
  }, [match.params.chatroom_id]);

  // Add this code to your Widget component
  useEffect(() => {
    if (chatroom != null) {
      const pusher = new Pusher('a5c19937d10212cddb4e', { cluster: 'us2' });

      // Subscribe to the presence channel for the chatroom
      const channel = pusher.subscribe(`presence-chatroom-${chatroom.token}`);

      // Bind to the presence events
      channel.bind('pusher:subscription_succeeded', members => {
        // 'members' is an array of users in the presence channel
        const membersArray = Object.values(members.members);

        console.log('Online Users:', membersArray);
        setOnlineUsers(membersArray);
      });

      channel.bind('pusher:member_added', member => {
        // A new user has joined the channel
        console.log('User Joined:', member);
      });

      channel.bind('pusher:member_removed', member => {
        // A user has left the channel
        console.log('User Left:', member);
      });

      return () => {
        channel.unbind_all();
        channel.unsubscribe();
      };
    }
  }, [chatroom]);

  // Fetch all messages
  const fetchMessages = () => {
    axios.get(`/api/o/${match.params.organization_id}/chatrooms/${match.params.chatroom_id}.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setChatroom(response.data.chatroom)
        setMessages(response.data.messages);
        setLoaded(true);
        var objDiv = document.getElementById("messageWrapper");
        objDiv.scrollTop = objDiv.scrollHeight;

        inputRef.current.focus();
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);
    setBody("")

    axios.post(`/api/o/${match.params.organization_id}/chatrooms/${match.params.chatroom_id}/messages`, {
      body: body
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        fetchMessages();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const messages_list = messages.map(message => {
    return(
      <Message message={message} refetchData={fetchMessages}/>
    )
  });

  return(
    loaded && 
    <React.Fragment>
      <div className="">
        {showHeader && 
          <div className="chatroom-show-header border-bottom ">
            {chatroom.title}
            <div className="chatroom-users-wrapper">
              {/* <div>
                <h4>Online Users</h4>
                <ul>
                  {onlineUsers.map(user => (
                    <li key={user.id}><img src={user.filepicker_url} className="avatar-small mr-5"/>{user.username}</li>
                  ))}
                </ul>
              </div> */}
              {chatroom.project != null && 
              <React.Fragment>
                <Users project_id={chatroom.project.token} hideItems={true}/>
                <Link className="btn btn-primary btn-small ml-15" to={`/o/${match.params.organization_id}/projects/${chatroom.project.token}/chatrooms/${chatroom.token}`}>Go to project <i class="far fa-angle-right ml-5"></i></Link>
              </React.Fragment>
              }
            </div>
          </div>
        }

        <div id="messageWrapper" className={chatroomWrapperClass}>
          {messages_list}
        </div>

        <div className="chatroom-message-input">
          <form onSubmit={handleSubmit}>

            <div class="input-group">
              <input className="form-control form-control-dark" value={body} onChange={(e) => setBody(e.target.value)} required={true} placeholder="Send a message..." ref={inputRef}/>
              <span class="input-group-btn">
                <button disabled={disabled || (body === "")} className="btn btn-primary" type="submit">Submit</button>
              </span>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Show 