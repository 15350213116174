import React , { useState, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { PickerOverlay, PickerInline } from 'filestack-react';
import { Modal } from 'react-responsive-modal';
import LoomRecorder from "./LoomRecorder.js";
import ReactTooltip from 'react-tooltip';
import Mention from '@tiptap/extension-mention'
import { EditorContent, useEditor, ReactRenderer, BubbleMenu } from '@tiptap/react'
import FileHandler from '@tiptap-pro/extension-file-handler'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import MentionList from './MentionList.jsx'
import tippy from 'tippy.js'
import Placeholder from '@tiptap/extension-placeholder'
import Image from '@tiptap/extension-image'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import Underline from '@tiptap/extension-underline'
import Commands from './commands.js'
import getSuggestionItems from "./items.js";
import renderItems from "./renderItems.js";
import TipTapBubbleMenu from "../../../../Shared/TipTapBubbleMenu.js";
import Highlight from '@tiptap/extension-highlight'
import TaskTimeTracker from "../../../../TimeTracker/TaskTimeTracker.js";
import {ProjectContext} from "../../../../Projects/ProjectContext.js";
import Youtube from '@tiptap/extension-youtube'
import { useTranslation } from 'react-i18next';

const Form = ({task, refetchData, projectAuthorizations, project_id, users, taskComment, closeModal, parent_id, setTaskComments}) => {
  const {testers} = useContext(ProjectContext);
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [comment, setComment] = useState(taskComment != null ? taskComment.comment : "");
  const [isPrivate, setIsPrivate] = useState(taskComment != null ? taskComment.private : (projectAuthorizations.project_user ? true : null));
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [videoUrl, setVideoUrl] = useState(null);
  const { t } = useTranslation();

  const handleDragOver = (e) => {
    e.preventDefault(); // Prevent the default behavior, which may not allow dropping.
    setOpen(true);
  };

  const createReplay = (filestack) => {
    const filesUploaded = filestack.filesUploaded;
    setFiles(filesUploaded);
  }

  // Create task_comment
  const submitComment = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}/task_comments`, {
      comment: comment, 
      private: isPrivate, 
      loom_link: videoUrl,
      parent_id: parent_id
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        if(response.data.task_comment.parent_id === null){
          setTaskComments(oldArray => [...oldArray,response.data.task_comment] );
        } else {
          setTaskComments((oldArray) =>
            oldArray.map((taskComment) => {
              if (taskComment.id === response.data.task_comment.parent_id) {
                return {
                  ...taskComment,
                  nested_task_comments: [...taskComment.nested_task_comments, response.data.task_comment],
                };
              }
              return taskComment;
            })
          );
        }
        uploadReplays(response.data.task_comment.token);
        setComment("");
        editor.commands.clearContent();
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const updateComment = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.put(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}/task_comments/${taskComment.token}`, {
      comment: comment, 
      private: isPrivate, 
      loom_link: videoUrl
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        uploadReplays(taskComment.token);
        refetchData();
        closeModal();
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const uploadReplays = (task_comment_id) => {
    axios.post(`/api/replays`, {
      files: files,
      filestack: true, 
      project_id: (project_id || match.params.project_id), 
      folder_id: null
    })
    .then(function(response){
      console.log(response)
      if(response.data.success){
        createTaskCommentReplay(task_comment_id, response.data.replays)
        setFiles([])
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occurred")
      setDisabled(false)
    });
  }

  const createTaskCommentReplay = (task_comment_id, replays) => {
    axios.post(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}/task_comments/${task_comment_id}/task_comment_replays`, {
      replays: replays
    })
    .then(function(response){
      console.log(response)
      if(response.data.success){
        refetchData();
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
  }

  const removeFile = (handle) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.handle !== handle));
  };

  const files_list = files.map(file => {
    return(
      <div className="file-uploaded-icon hover-parent-opacity">
        <i className="fas fa-file"></i>
        <i onClick={() => removeFile(file.handle)} className="fas fa-times file-uploaded-icon-times hover-child-opacity"></i>
      </div>
    )
  });

  const editor = useEditor({
    onUpdate({ editor }) {
      setComment(editor.getHTML())
    },
    extensions: [
      StarterKit,
      Image,
      TaskList,
      Underline,
      Highlight.configure({ multicolor: true }),
      TaskItem.configure({
        nested: true,
      }),
      Youtube.configure({
        inline: false,
        width: 480,
        height: 320,
      }),
      Link.configure({
        openOnClick: false,
        autolink: true,
      }),
      FileHandler.configure({
        allowedMimeTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/webp'],
        onDrop: (currentEditor, files, pos) => {
          files.forEach(file => {
            const fileReader = new FileReader()

            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
              currentEditor.chain().insertContentAt(pos, {
                type: 'image',
                attrs: {
                  src: fileReader.result,
                },
              }).focus().run()
              
            }
          })
        },
        onPaste: (currentEditor, files, htmlContent) => {
          files.forEach(file => {
            if (htmlContent) {
              return false
            }

            const fileReader = new FileReader()

            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
              notice("Pasting image...")
              axios.post(`/api/upload_to_digitalocean`, {
                base64: fileReader.result
              })
              .then(function(response){
                if(response.data.success){
                  currentEditor.chain().insertContentAt(currentEditor.state.selection.anchor, {
                    type: 'image',
                    attrs: {
                      src: response.data.image_link,
                      class: 'your-custom-class',
                    },
                  }).focus().run()
                } else {
                  response.data.errors.forEach((error) => {
                    notice(error);
                  });
                }
              })
              .catch(function(error){
                console.log(error)
                notice("An error occured");
                reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
              })
              .then(function () {

              });
            }
          })
        },
      }),
      Placeholder.configure({
        placeholder: '/slash command...',
      }),
      Mention.configure({
        HTMLAttributes: {
          class: 'mention border-all background-3',
        },
        suggestion: {
          items: ({ query }) => {
            return users
              .filter((item) =>
                item.toLowerCase().startsWith(query.toLowerCase())
              )
              .slice(0, 5);
          },
          render: () => {
            let reactRenderer;
            let popup;

            return {
              onStart: (props) => {
                reactRenderer = new ReactRenderer(MentionList, {
                  props,
                  editor: props.editor
                });

                popup = tippy("body", {
                  getReferenceClientRect: props.clientRect,
                  appendTo: () => document.body,
                  content: reactRenderer.element,
                  showOnCreate: true,
                  interactive: true,
                  trigger: "manual",
                  placement: "bottom-start"
                });
              },
              onUpdate(props) {
                reactRenderer.updateProps(props);

                popup[0].setProps({
                  getReferenceClientRect: props.clientRect
                });
              },
              onKeyDown(props) {
                if (props.event.key === "Escape") {
                  popup[0].hide();

                  return true;
                }

                return reactRenderer?.ref?.onKeyDown(props);
              },
              onExit() {
                popup[0].destroy();
                reactRenderer.destroy();
              }
            };
          }
        }
      }),
      Commands.configure({
        suggestion: {
          items: getSuggestionItems,
          render: renderItems
        }
      })
    ],
    content: comment,
  });

  

  return(
    <React.Fragment>
      <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
        <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
        <PickerInline
          apikey={"AKVhxMbEQhkOIryqBvSEQz"}
          onSuccess={(res) => console.log(res)}
          onUploadDone={(res) => {
            createReplay(res);
            setOpen(false);
          }}
          clientOptinos={{
            sessionCache:true
          }}
          pickerOptions={{
            onClose: () => {
              setOpen(false);
            }, 
            fromSources: ["local_file_system", "googledrive", "dropbox", "box", "onedrive", "onedriveforbusiness", "googlephotos", "facebook", "instagram"],
            maxFiles: 15
          }}
        />
      </Modal>

      <form className="animated fadeIn" onSubmit={taskComment != null ? updateComment : submitComment} onDragOver={handleDragOver}>
        <div className="field">
          <label>{t("Reply")}</label>
          <div className="full-card-description">
            {/* <Editor value={comment} onChange={(e) => setComment(e.target.value)} containerProps={{ style: { minHeight: 150 } }} onPaste={handlePaste}/> */}
            <EditorContent editor={editor} className="border-all border-radius"/>
            {editor && 
              <TipTapBubbleMenu editor={editor}/>
            }
          </div>
        </div>

        <div className="file-uploaded-icon-wrapper">
          {files_list}
        </div>

        <div className="field task-comment-form-button-wrapper">
          <div className="task-comment-form-buttons">
            {(projectAuthorizations.organization_user || projectAuthorizations.project_user) &&
              <>
                {testers.some(tester => tester.kind === 'Timesheets') && 
                  <TaskTimeTracker project_id={project_id || match.params.project_id} task={task} closeModal={closeModal}/>
                }
              </>
            }

            <a className="btn btn-small btn-primary-outline mr-10" onClick={() => setOpen(true)}>
              <i data-tip={t("Upload")} className="fal fa-link task-comment-replay-icon margin-none"></i>
            </a>

            {(projectAuthorizations.organization_user || projectAuthorizations.project_user) &&
              <div class="btn-group dropup">
                  {isPrivate ? (
                    <React.Fragment>
                      <a type="button" class="btn btn-danger-outline btn-small dropdown-toggle mr-10" data-toggle="dropdown">
                        <i class="fal fa-lock mr-5"></i>{t("Private")}<i class="far fa-angle-up ml-10"></i>
                      </a>
                    </React.Fragment>
                  ):(
                    <React.Fragment>
                      <a type="button" class="btn btn-primary-outline btn-small dropdown-toggle mr-10" data-toggle="dropdown">
                        <i class="far fa-globe-americas mr-5"></i>{t("Everyone")}<i class="far fa-angle-up ml-10"></i>
                      </a>
                    </React.Fragment>
                  )}
                <ul class="dropdown-menu animated fadeInUp" role="menu">
                  <li><a onClick={() => setIsPrivate(false)}><i class="far fa-globe-americas mr-5"></i>{t("Everyone")}</a></li>
                  <li><a onClick={() => setIsPrivate(true)}><i class="fal fa-lock mr-5"></i>{t("Team only")}</a></li>
                </ul>
              </div>
            }
            
            {taskComment == null && 
              <LoomRecorder setVideoUrl={setVideoUrl} videoUrl={videoUrl}/>
            }
            <button disabled={disabled || comment.length == 0} className="btn btn-primary btn-small" type="submit">{t("Submit")}</button>
          </div>
        </div>
      </form>
    </React.Fragment>
  )
}

export default Form