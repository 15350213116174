import React , { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import _ from 'lodash'; 
import Select from "react-select";

const ChooseUpgradeOptions = ({services, service, closeModal, refetchData}) => {
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [selectedUpgrades, setSelectedUpgrades] = useState([]);

  useEffect(() => {
    // Set default values from service.service_upgrades
    const defaultUpgrades = service.service_upgrades.map(upgrade => ({
      value: upgrade.service.token,
      label: upgrade.service.title
    }));
    setSelectedUpgrades(defaultUpgrades);
  }, [service]);

  const handleChange = (selectedOptions) => {
    console.log(selectedOptions);
    setSelectedUpgrades(selectedOptions);
  };


  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);
    // Perform axios POST request
    axios.post(`/api/o/${match.params.organization_id}/services/${service.token}/service_upgrades`, { 
      selected_upgrades: selectedUpgrades 
    })
    .then(function(response) {
      if (response.data.success) {
        // Handle success (e.g., show a success message)
        notice('Upgrades updated successfully');
        refetchData();
        setDisabled(false);
        closeModal();
      } else {
        // Handle errors
        console.error('Failed to update upgrades:', response.data.errors);
      }
    })
    .catch(function(error) {
      console.error('An error occurred while updating upgrades:', error);
      // You might want to show an error message to the user here
    });
  }

  const options = services.filter(s => !selectedUpgrades.some(upgrade => upgrade.value === s.token)).map((service) => ({
    value: service.token,
    label: service.title,
  }));


  return(
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <div className="field">
          <label htmlFor="service_upgrade_original_service_id">Upgrade to</label>
          <Select
            name="service_upgrade_original_service_id"
            options={options}
            value={selectedUpgrades}
            onChange={handleChange}
            isMulti={true}
            isDisabled={disabled}
            className="color-1"
            classNamePrefix="react-select"
            placeholder="Select..."
          />
        </div>
        <div className="field text-right">
          <button disabled={disabled} type="submit" className="btn btn-primary btn-small">Save</button>
        </div>
      </form>
    </React.Fragment>
  )
}

export default ChooseUpgradeOptions;