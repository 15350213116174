import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 

const Review = ({review}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [chosenStars, setChosenStars] = useState(review.rating);
  const [options, setOptions] = useState(review.chosen_options || []);

  return(
    <React.Fragment>
      <div className="mb-15">
        <div className="col-12 review-item background-3 border-all border-radius default-padding">
          <div className="reviews-list-username">
            <div className="reviews-list-username">
              <div className="mr-10 font-weight-600">{review.user.username}</div>

              {[...Array(5)].map((_, index) => (
                <i
                  key={index}
                  className={`fas fa-star ${chosenStars !== null && index < chosenStars ? 'color-yellow' : ''} mr-10 cursor-pointer`}
                  style={{fontSize: "14px"}}
                ></i>
              ))}
            </div>

            <div className="opacity-7 font-12">
              {review.created_at} ago
            </div>
          </div>
          
          <p>
            {review.comment}
          </p>
          
          {options.length > 0 && 
            <div className="font-weight-600 mb-10">What didn't you like?</div>
          }

          {
            options.map(item => (
              <span key={item} className="badge badge-default mr-5">
                {item}
              </span>
            ))
          }
          <p className="font-12 text-right">
            <Link classsName="font-13 ml-15 color-1" to={`/o/${match.params.organization_id}/projects/${review.project.token}/tasks?task_id=${review.task.token}`}>
              <i class="fal fa-external-link mr-5"></i> View task
            </Link>
          </p>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Review;