import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 

const ChatroomItemDropdown = ({chatroom, refetchData}) => {
  const match = useRouteMatch();

  const archiveChatroom = (evt) => {
    axios.post(`/api/o/${match.params.organization_id}/chatrooms/${chatroom.token}/archive`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData();
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {

    });
  }

  const unarchiveChatroom = (evt) => {
    axios.post(`/api/o/${match.params.organization_id}/chatrooms/${chatroom.token}/unarchive`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData();
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {

    });
  }

  return(
    <React.Fragment>
      <i 
        className={`fal ${chatroom.archive ? "fa-box-open" : "fa-archive"}  btn btn-small color-1 background-hover opacity-6 opacity-10-hover`} 
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (chatroom.archive) {
            unarchiveChatroom();
          } else {
            archiveChatroom();
          }
        }}
      ></i>

    </React.Fragment>
  )
}

export default ChatroomItemDropdown