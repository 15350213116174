import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import ColorPicker from 'react-pick-color';

const EditTHeme = ({organization, refetchData, onboarding}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [bodyBackground, setBodyBackground] = useState(organization.body_background || "#101114");
  const [background, setBackground] = useState(organization.background || "#141619");
  const [backgroundSecondary, setBackgroundSecondary] = useState(organization.background_secondary || "#0c0d0f")
  const [backgroundHover, setBackgroundHover] = useState(organization.background_hover || "#222529");
  const [textColor, setTextColor] = useState(organization.text_color || "#DAE1E9");
  const [inputBackground, setInputBackground] = useState(organization.input_background || "#16191D");
  const [buttonBackground, setButtonBackground] = useState(organization.button_background || "#4169E1");
  const [buttonColor, setButtonColor] = useState(organization.button_color || "#DAE1E9");
  const [borderColor, setBorderColor] = useState(organization.border_color || "#3c4054");
  const [sidebarBackgroundColor, setSidebarBackgroundColor] = useState(organization.sidebar_background_color || "#101114");
  const [sidebarTextColor, setSidebarTextColor] = useState(organization.sidebar_text_color || "#DAE1E9");
  const [advanced, setAdvanced] = useState(false);

  // Array of predefined themes
  const themes = [
    {
      name: "Default",
      bodyBackground: "#101114",
      background: "#141619",
      backgroundSecondary: "#0c0d0f",
      backgroundHover: "#222529",
      textColor: "#dae1e9",
      inputBackground: "#16191D",
      buttonBackground: "#4169e1",
      buttonColor: "#dae1e9",
      borderColor: "#212429",
      sidebarBackgroundColor: "#101114",
      sidebarTextColor: "#dae1e9"
    },
    {
      name: "Light",
      bodyBackground: "#f5f8fa",
      background: "#fff",
      backgroundSecondary: "#f5f5f5",
      backgroundHover: "#dae1e9",
      textColor: "#202634",
      inputBackground: "#fbfbfd",
      buttonBackground: "#4169e1",
      buttonColor: "#ffffff",
      borderColor: "#dae1e9",
      sidebarBackgroundColor: "#f5f5f5",
      sidebarTextColor: "#202634"
    },
    {
      name: "Dim",
      bodyBackground: "#262836",
      background: "#292d3e",
      backgroundSecondary: "#2a2c3d",
      backgroundHover: "#323850",
      textColor: "#dae1e9",
      inputBackground: "#41465e",
      buttonBackground: "#4169e1",
      buttonColor: "#dae1e9",
      borderColor: "#3c4054",
      sidebarBackgroundColor: "#2a2c3d",
      sidebarTextColor: "#dae1e9"
    },
    {
      name: "Mystic",
      bodyBackground: "#261f28",
      background: "#392e3e",
      backgroundSecondary: "#2a222c",
      backgroundHover: "#463b4b",
      textColor: "#ebe4ee",
      inputBackground: "#312735",
      buttonBackground: "#403446",
      buttonColor: "#ede4ef",
      borderColor: "#463b4b",
      sidebarBackgroundColor: "#382a3b",
      sidebarTextColor: "#b4a9b7"
    },
    {
      name: "Darkness",
      bodyBackground: "#0e0f0f",
      background: "#101111",
      backgroundSecondary: "#101111",
      backgroundHover: "#28282c",
      textColor: "#b5b5b5",
      inputBackground: "#161618",
      buttonBackground: "#403446",
      buttonColor: "#1f1f1f",
      borderColor: "#28282c",
      sidebarBackgroundColor: "#101111",
      sidebarTextColor: "#b5b5b5"
    },
  ];

  // Function to handle theme selection
  const handleThemeChange = (theme) => {
    setBodyBackground(theme.bodyBackground);
    setBackground(theme.background);
    setBackgroundSecondary(theme.backgroundSecondary);
    setBackgroundHover(theme.backgroundHover);
    setTextColor(theme.textColor);
    setInputBackground(theme.inputBackground);
    setButtonBackground(theme.buttonBackground);
    setButtonColor(theme.buttonColor);
    setBorderColor(theme.borderColor);
    setSidebarBackgroundColor(theme.sidebarBackgroundColor);
    setSidebarTextColor(theme.sidebarTextColor);
  };

  const handleSubmit = (evt) => {
    if(evt != null){
      evt.preventDefault();
    }
    setDisabled(true);

    const data = {
      body_background: bodyBackground,
      background: background,
      background_secondary: backgroundSecondary,
      background_hover: backgroundHover,
      text_color: textColor,
      input_background: inputBackground,
      button_background: buttonBackground,
      button_color: buttonColor,
      border_color: borderColor,
      sidebar_background_color: sidebarBackgroundColor,
      sidebar_text_color: sidebarTextColor,
    };

    axios.put(`/api/o/${match.params.organization_id}`, data)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        location.reload();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }


  return(
    (organization != null) && 
    <React.Fragment>
      <div className="row">
        <div className="col-md-6 col-12 default-padding">
          <div className={`background-3 border-radius default-padding ${onboarding ? "" : "border-all"} themes-wrapper`}>
            {!onboarding && 
              <React.Fragment>
                <h4 className="font-weight-600">Theme</h4>
                <p className="opacity-7 text-12" style={{marginTop: "-5px"}}>Update the platform theme to fit your brand</p> 
              </React.Fragment>
            }
            <React.Fragment>
              <form className="row">
                {/* Dropdown for theme selection */}
                <div className="default-padding col-md-3">
                  <label>Themes</label>
                  <div className="field">
                    <select className="form-control form-control-small" onChange={(e) => handleThemeChange(themes[e.target.value])}>
                      {themes.map((theme, index) => (
                        <option key={index} value={index}>{theme.name}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="default-padding col-md-3">
                  <label>Button</label>

                  <div className="field">
                    <div class="btn-group width-100-percent">
                      <button type="button" class="btn btn-small dropdown-toggle color-picker-box border-all" data-toggle="dropdown" style={{background: buttonBackground}}>
                        {buttonBackground}
                      </button>
                      <ul class="dropdown-menu animated fadeInUp" role="menu">
                        <ColorPicker color={buttonBackground} onChange={buttonBackground => setButtonBackground(buttonBackground.hex)} />
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="default-padding col-md-3">
                  <label>Button Text</label>
                  <div className="field">
                    <div class="btn-group width-100-percent">
                      <button type="button" class="btn btn-small dropdown-toggle color-picker-box border-all" data-toggle="dropdown" style={{background: buttonColor}}>
                        {buttonColor}
                      </button>
                      <ul class="dropdown-menu animated fadeInUp" role="menu">
                        <ColorPicker color={buttonColor} onChange={buttonColor => setButtonColor(buttonColor.hex)} />
                      </ul>
                    </div>
                  </div>
                </div>
              
                <label className="cursor-pointer default-padding" onClick={() => setAdvanced(!advanced)}>Advanced</label>
              </form>
            </React.Fragment>

            <div>
              {advanced && 
                <div className="animated fadeIn slow-1">
                  <form className="row" onSubmit={handleSubmit}>
                    <div className="default-padding col-md-3">
                      <label>Body</label>
                      <div className="field">
                        <div class="btn-group width-100-percent">
                          <button type="button" class="btn btn-small dropdown-toggle color-picker-box border-all" data-toggle="dropdown" style={{background: bodyBackground}}>
                            {bodyBackground}
                          </button>
                          <ul class="dropdown-menu animated fadeInUp" role="menu">
                            <ColorPicker color={bodyBackground} onChange={bodyBackground => setBodyBackground(bodyBackground.hex)} />
                          </ul>
                        </div>
                      </div>
                    </div>
                    
                    <div className="default-padding col-md-3">
                      <label>Background</label>
                      
                      <div className="field">
                        <div class="btn-group width-100-percent">
                          <button type="button" class="btn btn-small dropdown-toggle color-picker-box border-all" data-toggle="dropdown" style={{background: background}}>
                            {background}
                          </button>
                          <ul class="dropdown-menu animated fadeInUp" role="menu">
                            <ColorPicker color={background} onChange={background => setBackground(background.hex)} />
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="default-padding col-md-3">
                      <label>Background 2nd</label>
                      
                      <div className="field">
                        <div class="btn-group width-100-percent">
                          <button type="button" class="btn btn-small dropdown-toggle color-picker-box border-all" data-toggle="dropdown" style={{background: backgroundSecondary}}>
                            {backgroundSecondary}
                          </button>
                          <ul class="dropdown-menu animated fadeInUp" role="menu">
                            <ColorPicker color={backgroundSecondary} onChange={background => setBackgroundSecondary(background.hex)} />
                          </ul>
                        </div>
                      </div>
                    </div>
                    
                    <div className="default-padding col-md-3">
                      <label>Hover</label>
                      <div className="field">
                        <div class="btn-group width-100-percent">
                          <button type="button" class="btn btn-small dropdown-toggle color-picker-box border-all" data-toggle="dropdown" style={{background: backgroundHover}}>
                            {backgroundHover}
                          </button>
                          <ul class="dropdown-menu animated fadeInUp" role="menu">
                            <ColorPicker color={backgroundHover} onChange={backgroundHover => setBackgroundHover(backgroundHover.hex)} />
                          </ul>
                        </div>
                      </div>
                    </div>
                    
                    <div className="default-padding col-md-3">
                      <label>Text</label>
                      
                      <div className="field">
                        <div class="btn-group width-100-percent">
                          <button type="button" class="btn btn-small dropdown-toggle color-picker-box border-all" data-toggle="dropdown" style={{background: textColor}}>
                            {textColor}
                          </button>
                          <ul class="dropdown-menu animated fadeInUp" role="menu">
                            <ColorPicker color={textColor} onChange={textColor => setTextColor(textColor.hex)} />
                          </ul>
                        </div>
                      </div>
                    </div>
                    
                    <div className="default-padding col-md-3">
                      <label>Input</label>
                      
                      <div className="field">
                        <div class="btn-group width-100-percent">
                          <button type="button" class="btn btn-small dropdown-toggle color-picker-box border-all" data-toggle="dropdown" style={{background: inputBackground}}>
                            {inputBackground}
                          </button>
                          <ul class="dropdown-menu animated fadeInUp" role="menu">
                            <ColorPicker color={inputBackground} onChange={inputBackground => setInputBackground(inputBackground.hex)} />
                          </ul>
                        </div>
                      </div>
                    </div>
                  
                    
                    <div className="default-padding col-md-3">
                      <label>Border Color</label>
                      
                      <div className="field">
                        <div class="btn-group width-100-percent">
                          <button type="button" class="btn btn-small dropdown-toggle color-picker-box border-all" data-toggle="dropdown" style={{background: borderColor}}>
                            {borderColor}
                          </button>
                          <ul class="dropdown-menu animated fadeInUp" role="menu">
                            <ColorPicker color={borderColor} onChange={borderColor => setBorderColor(borderColor.hex)} />
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="default-padding col-md-3">
                      <label>Sidebar</label>
                      

                      <div className="field">
                        <div class="btn-group width-100-percent">
                          <button type="button" class="btn btn-small dropdown-toggle color-picker-box border-all" data-toggle="dropdown" style={{background: sidebarBackgroundColor}}>
                            {sidebarBackgroundColor}
                          </button>
                          <ul class="dropdown-menu animated fadeInUp" role="menu">
                            <ColorPicker color={sidebarBackgroundColor} onChange={sidebarBackgroundColor => setSidebarBackgroundColor(sidebarBackgroundColor.hex)} />
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="default-padding col-md-3">
                      <label>Sidebar Text</label>
                      
                      <div className="field">
                        <div class="btn-group width-100-percent">
                          <button type="button" class="btn btn-small dropdown-toggle color-picker-box border-all" data-toggle="dropdown" style={{background: sidebarTextColor}}>
                            {sidebarTextColor}
                          </button>
                          <ul class="dropdown-menu animated fadeInUp" role="menu">
                            <ColorPicker color={sidebarTextColor} onChange={sidebarTextColor => setSidebarTextColor(sidebarTextColor.hex)} />
                          </ul>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              }
            </div>

            <div className="default-padding col-md-12 text-center">
              <button onClick={handleSubmit} className="btn btn-primary">Update colors</button>
            </div>
          </div>
        </div>
        
        <div className={`col-md-6 col-12 border-radius default-padding`}>
          <div className="row border-all border-radius" style={{height: "100%", borderColor: borderColor, background: bodyBackground}}>
            <div className="col-3 field border-right" style={{background: sidebarBackgroundColor, borderColor: borderColor}}>
              <div className="pr-15 border-right border-radius" style={{borderColor: borderColor}}>
                <ul style={{paddingLeft: "0", listStyle: "none", padding: "15px"}}>
                  <li style={{color: sidebarTextColor}}>
                    Sidebar text 1
                  </li>

                  <li style={{color: sidebarTextColor}}>
                    Sidebar text 2
                  </li>

                  <li style={{color: sidebarTextColor}}>
                    Sidebar text 3
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-9 field default-padding">
              <div className="border-all border-radius default-padding" style={{background: background, borderColor: borderColor}}>
                <p className="font-12" style={{color: textColor}}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>

                <div className="field default-padding" style={{color: textColor, background: backgroundHover}}>
                  Hover active
                </div>

                <div className="field">
                  <input className="form-control" placeholder="Input Background" style={{color: textColor, background: inputBackground, borderColor: borderColor}}/> 
                </div>

                <div className="field">
                  <button className="btn" style={{color: buttonColor, background: buttonBackground}}>
                    Button
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EditTHeme;