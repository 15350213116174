import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import RecurringInvoice from "./RecurringInvoice.js";
import animationData from "../../Shared/Lotties/lottie-empty.json";
import { Player } from "@lottiefiles/react-lottie-player";

const RecurringInvoices = ({}) => {
  // const organizationAuthorizations = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [recurringInvoices, setRecurringInvoices] = useState([]);

  useEffect(() => {
    fetchRecurringInvoices();
  }, []);

  const fetchRecurringInvoices = () => {
    axios.get(`/api/o/${match.params.organization_id}/recurring_invoice_items.json`)
    .then(function(response){
    //   console.log(response);
      if(response.data.success){
        setRecurringInvoices(response.data.recurring_invoice_items);
        setLoaded(true);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const recurring_invoices_list = recurringInvoices.map(recurring_invoice_item => {
    return(
      <RecurringInvoice recurring_invoice_item={recurring_invoice_item} refetchData={fetchRecurringInvoices} />
    )
  })

  return(
    loaded && 
    <React.Fragment>
      {recurringInvoices.length > 0 ? (
        <table class="table table-responsive border-all table-bordered">
          <thead>
            <tr className="border-bottom">
              <th className="react-table-th" scope="col"><i class="fal fa-toggle-on"></i>Active</th>
              {/* <th className="react-table-th" scope="col"><i class="fal fa-calendar"></i>Original start date</th> */}
              <th className="react-table-th" scope="col"><i class="fal fa-calendar-day"></i>Last sent</th>
              <th className="react-table-th" scope="col"><i class="fal fa-dollar-sign"></i>Amount</th>
              <th className="react-table-th" scope="col"><i class="fal fa-calendar-plus"></i>Next invoice</th>
              <th className="react-table-th" scope="col"><i class="fal fa-layer-group"></i>Project</th>
            </tr>
          </thead>

          <tbody>
            {recurring_invoices_list}
          </tbody>
        </table>
      ):(
        <p className="font-12 large-padding opacity-4 text-center">
          <Player style={{width: "300px", margin: "auto"}} mode="normal" autoplay loop src={animationData} />
          <p className="mt-25">
            Recurring invoices will automatically generate invoices and send it to your client. <br /> Video guide coming soon.
          </p>
        </p>
      )}
    </React.Fragment>
  )
}

export default RecurringInvoices;