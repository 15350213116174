import React , { useState } from 'react';
import { useRouteMatch } from 'react-router-dom'
import { Modal } from 'react-responsive-modal';

const VideoGuide = ({src}) => {
  const [open, setOpen] = useState(false);
  
  return(
    <React.Fragment>
      <a className="mt-10 font-weight-600" onClick={() => setOpen(true)}>
        <i class="far fa-video"></i> Video Guide
      </a>

      <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'modal-video-guide'}} center>
        <iframe src={src} frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style={{height: "100%", width: "100%"}}></iframe>
      </Modal>
    </React.Fragment>
  )
}

export default VideoGuide