import React, { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import Select from "react-select";

const ServicePriceSelect = ({ service_id, selectedServices, refetchData, closeModal }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [servicePrices, setServicePrices] = useState([]);
  const [chosenServicePrice, setChosenServicePrice] = useState(null);

  useEffect(() => {
    setLoaded(false);
    setChosenServicePrice(null);
    fetchService();
  }, [service_id]);

  const fetchService = () => {
    axios.get(`/api/o/${match.params.organization_id}/services/${service_id}.json`)
      .then(function (response) {
        console.log(response);
        if (response.data.success) {
          setServicePrices(response.data.service.service_prices);
          setLoaded(true)
        } else {

        }
      })
      .catch(function (error) {
        console.log(error)
        notice("An error occurred")
      })
      .then(function () {
        // always executed
      });
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/o/${match.params.organization_id}/create_existing_service_addon`, {
      selected_services: selectedServices,
      selected_service_id: service_id,
      chosen_service_price: chosenServicePrice.value,
    })
    .then(function (response) {
      console.log(response);
      if (response.data.success) {
        notice("Great work on your addons!"); 
        closeModal();
        refetchData();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function (error) {
      console.log(error)
      notice("An error occurred");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const handleServicePriceChange = (selectedOption) => {
    setChosenServicePrice(selectedOption);
  };

  const options = servicePrices.map((service_price) => ({
    value: service_price.token,
    label: `${(parseFloat((service_price.stripe_data.unit_amount/ 100).toFixed(2))).toLocaleString('en-US', {style: 'currency',currency: (service_price.stripe_data.currency)})} ${service_price.stripe_data.recurring != null && `per ${service_price.stripe_data.recurring.interval_count} ${service_price.stripe_data.recurring.interval}`}`,
  }));

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black', // Text color of options
    }),
  };

  return (
    loaded && 
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <div className="field">
          <label>Select pricing</label>
          <Select
            options={options}
            value={chosenServicePrice}
            onChange={handleServicePriceChange}
            placeholder="Select a Service Price"
            styles={customStyles} 
          />
        </div>

        <div className="field text-right">
          <button disabled={disabled || chosenServicePrice === null} className="btn btn-primary" type="submit">Create add-on</button>
        </div>
      </form>
    </React.Fragment>
  )
}

export default ServicePriceSelect;
