import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {UserContext} from "../../Shared/UserContext.js";
import VideoGuide from "../../Shared/VideoGuide.js";

const Domain = ({onboarding}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const currentUser = useContext(UserContext);
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [cname, setCname] = useState()
  const [organization, setOrganization] = useState();
  const [domain, setDomain] = useState("");
  const [dns, setDns] = useState([]);
  const [setupFinished, setSetupFinished] = useState(null);

  useEffect(() => {
    fetchOrganization();
  }, []);

  const fetchOrganization = () => {
    axios.get(`/api/o/${match.params.organization_id}.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setOrganization(response.data.organization);
        setCname(`${response.data.organization.subdomain}.${document.getElementById("production-level") ? "usequeue.com" : "queue.ngrok.io"}`)
        setLoaded(true);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/o/${match.params.organization_id}/domain_setup`, {
      domain: domain
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        fetchOrganization();
        notice("Domain added. Please be sure to update the DNS records.")
      } else {
        notice("Sorry, an error occured. Please contact customer support.")
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }


  const verify = () => {
    axios.post(`https://www.whoisxmlapi.com/whoisserver/DNSService?apiKey=at_zSSBDuspuGFFelQXMUZgG5LS8OKzl&domainName=${organization.domain}&type=cname&outputFormat=json`)
    .then(function(response){
      console.log(response);
      setDns(response.data.DNSData.dnsRecords);
      // Check if the conditions are met in the `dns` state array
      const hasMatchingRecord = response.data.DNSData.dnsRecords.some(
        record =>
          record.name === `${organization.domain}.` &&
          record.target === `${cname}.`
      );

      console.log('Has matching record:', hasMatchingRecord);
      setSetupFinished(hasMatchingRecord);
      if(hasMatchingRecord){
        createHatchboxDomain(); // update the organization 
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const createHatchboxDomain = () => {
    axios.post(`/api/o/${match.params.organization_id}/create_hatchbox_domain`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        notice(`Your setup is now complete. Go to ${response.data.organization.domain} to verify. SSL takes about 5 minutes to finish.`)
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const dns_list = dns.map(item => {
    return(
      <React.Fragment>
        <div className="daas-dns-wrapper">
          <div className={`opacity-6 font-12 ${onboarding ? "color-black-always" : ""}`}>
            {item.name}
          </div>

          <div className={`opacity-6 font-12 ${onboarding ? "color-black-always" : ""}`}>
            {item.target}
          </div>
        </div>
      </React.Fragment>
    )
  });

  return(
    loaded &&
    <React.Fragment>
      <div className="row justify-content-center mt-25">
        <div className={`${onboarding ? "col-12" : "col-md-6 col-lg-6 col-12"}`}>
          <p className="text-center mb-10">
            <VideoGuide src="https://www.loom.com/embed/c49f1f4ecbae42e3b5cf862b0d33324f?sid=dcb1438d-ab68-4aa5-bf03-a7c874346983"/>
          </p>

          <div className={`border-all border-radius default-padding background-3`}>
            <h4 className={`font-weight-600 no-margin ${onboarding ? "color-black-always" : ""}`}>
              Step 1
              {organization.domain_verified && 
                <i className="fas fa-check-circle ml-5 color-green"></i>
              }
            </h4>
            <p className={`opacity-6 font-12 ${onboarding ? "color-black-always" : ""}`}>Add the domain you'd like to route to</p>

            <form onSubmit={handleSubmit}>
              <div className="field">
                <label >URL</label>
                <input onChange={(e) => setDomain(e.target.value)} className={`form-control ${onboarding ? "" : "form-control-dark"}`} placeholder="login.example.com" required={true} type="text" defaultValue={organization.domain}/>
                <p>We recommend using a subdomain</p>
              </div>

              <div className="field text-right">
                <button className="btn btn-primary btn-small" disabled={disabled || (domain == '')} type="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="row justify-content-center mt-25">
        <div className={`${onboarding ? "col-12" : "col-md-6 col-lg-6 col-12"}`}>
          <div className={`border-all border-radius default-padding background-3`}>
            <h4 className={`font-weight-600 no-margin ${onboarding ? "color-black-always" : ""}`}>
              Step 2
              {organization.domain_verified && 
                <i className="fas fa-check-circle ml-5 color-green"></i>
              }
            </h4>
            <p className={`opacity-6 font-12 ${onboarding ? "color-black-always" : ""}`}>Add a CNAME record to your DNS server. Contact support if you'd like help.</p>

            <div className="field">
              <label className={`${onboarding ? "color-black-always" : "dark-color-white"}`}>CNAME Record</label>
              <div class="input-group">
                <input type="text" className={`form-control ${onboarding ? "" : "form-control-dark"}`} disabled={true} value={cname}/>
                <span class="input-group-btn">
                  <CopyToClipboard text={cname} onCopy={() => notice("Copied")}>
                    <button class="btn border-all btn-primary" type="button">Copy</button>
                  </CopyToClipboard>
                </span>
              </div>
              <p className={`opacity-6 font-12 ${onboarding ? "color-black-always" : ""}`}>Set TTL to 30</p>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-center mt-25">
        <div className={`${onboarding ? "col-12" : "col-md-6 col-lg-6 col-12"}`}>
          <div className={`border-all border-radius default-padding background-3`}>
            <h4 className={`font-weight-600 no-margin ${onboarding ? "color-black-always" : ""}`}>
              Step 3
              {organization.domain_verified && 
                <i className="fas fa-check-circle ml-5 color-green"></i>
              }
            </h4>
            <p className={`opacity-6 font-12 ${onboarding ? "color-black-always" : ""}`}>Verify your DNS setup. Takes ~15min to show up.</p>

            <div className="field row">
              <div className={`col-8 ${onboarding ? "color-black-always" : ""}`}>
                Follow the step-by-step instructions and verify your connection again.
              </div>

              <div className="col-4 text-right">
                  {setupFinished == null && 
                    <a className="btn btn-primary btn-small" onClick={verify}>
                      Verify connection
                    </a>
                  }
                
                  {setupFinished != null &&
                    <React.Fragment>
                      {setupFinished ? (
                        <a className="btn btn-success btn-small" onClick={verify}>
                          <i className="fas fa-check mr-5"></i> Connection verified
                        </a>
                      ):(
                        <a className="btn btn-primary-outline btn-small" onClick={verify}>
                          <i className="fas fa-times mr-5 color-red"></i> Records not found
                        </a>
                      )}
                    </React.Fragment>
                  }
              </div>
            </div>

            <div className="field">
              <div className="daas-dns-wrapper">
                <div className="font-weight-600">Name</div>
                <div className="font-weight-600">Target</div>
              </div>
              {dns_list}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Domain