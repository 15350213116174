import React, { useState, useRef } from 'react';
import axios from "axios"; 

const VerifyCode = ({refetchData, email, organization, setStep}) => {
  const [disabled, setDisabled] = useState(false)
  const inputRefs = useRef([]);
  const [inputValues, setInputValues] = useState(['', '', '', '', '', '']);

  const handleInputChange = (event, index) => {
    const { value } = event.target;
  
    if (value.length === 1) {
      setInputValues((prevValues) => {
        const newValues = [...prevValues];
        newValues[index] = value;
        return newValues;
      });
  
      // Move focus to the next input if available
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };    
  
  const handlePaste = (event) => {
    event.preventDefault();
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData('text');
    const valuesToPaste = pastedText.split('').slice(0, inputValues.length);

    setInputValues(valuesToPaste);
    inputRefs.current.forEach((input, index) => {
      input.value = valuesToPaste[index];
    });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);
  
    const code = inputValues.join(''); // Join the array into a single string
  
    axios.post(`/api/verify_code`, {
      code: code, 
      email: email
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        if(refetchData != null){
          refetchData();
        } else {
          window.location.href = "/"
        }
      } else {
        notice("Invalid code")
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occurred")
    })
    .then(function () {
      setDisabled(false);
    });
  }
  

  return(
    <React.Fragment>
        <form onSubmit={handleSubmit}>
          <div class="field text-center mb-25">
            {organization != null && 
              <img src={organization.avatar} className="avatar-md mb-10" />
            }
            <h3 class="font-weight-600">Login</h3>
            <p className="opacity-7">Please enter the code sent to <strong>{email}</strong>. Please check spam folder too.</p>
          </div>
          
          <div className="field login-code-wrapper">
            {inputValues.map((value, index) => (
              <input
                autoFocus={index === 0}
                key={index}
                ref={(el) => (inputRefs.current[index] = el)}
                onChange={(event) => handleInputChange(event, index)}
                onPaste={handlePaste}
                className="login-code-input form-control"
                maxlength="1"
                type="tel" pattern="[0-9]*" novalidate
              />
            ))}
          </div>
          <div className="field mt-30">
            <button className="btn btn-primary btn-block" disabled={disabled}>Login</button>
          </div>
        </form>

        <div class="form-group text-center">
          <p className="font-12">
            <a class="color-1" onClick={() => setStep("Check")}>Wrong email</a>
          </p>
        </div>
    </React.Fragment>
  )
}
export default VerifyCode;