import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import Currency from "./Currency.js";

const Referred = ({affiliate, refetchData, setSelectedCurrency, selectedCurrency}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [kind, setKind] = useState(affiliate.recipient_commission_kind)
  const [amount, setAmount] = useState(affiliate.recipient_commission_rate || "");

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.put(`/api/o/${match.params.organization_id}/affiliates/${affiliate.token}`, {
      recipient_commission_kind: kind, 
      recipient_commission_rate: amount, 
      recipient_period_days: null, 
      referred: true, 
      currency: selectedCurrency
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        notice("Updated successfully");
        refetchData();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }


  return(
    <React.Fragment>
      <form onSubmit={handleSubmit} className="col-12 col-md-6 pr-15">
        <div className="background-3 border-all border-green border-radius default-padding">
          <h4 className="mt-0 font-weight-600">Buyer</h4>
          <p className="opacity-4">Set the benefits of the person who was referred</p>
          
          <div className="col-12">
            <div className="banner-warning mb-15 mt-15">
              <i className="fas fa-question-circle mr-5"></i>This discount will apply to each transacation if client purchases a Subscription Service.
            </div>
          </div>
          
          <div className="mb-15" style={{marginTop: "40px"}}>
            <div class="btn-group mb-25" style={{display: "flex", justifyContent: "space-around"}}>
              <button onClick={() => setKind("percentage")} type="button" class={`width-100-percent btn ${kind == "percentage" ? "btn-primary" : "btn-primary-outline"}`}>PERCENTAGE</button>
              <button onClick={() => setKind("fixed")} type="button" class={`width-100-percent btn ${kind == "fixed" ? "btn-primary" : "btn-primary-outline"}`}>FIXED</button>
            </div>

            {/* PERCENTAGE */}
            {kind === "percentage" && 
              <React.Fragment>
                <div className="row">
                  <div className="col-12 col-md-12 pr-15">
                    <div className="field">
                      <label className="dark-color-white">Discount on purchase</label>
                      <div class="input-group">
                        <input type="number" class="form-control form-control-dark" value={amount} onChange={(e) => setAmount(e.target.value)} required="true" min="0" max="100"/>
                        <span class="input-group-addon">%</span>
                        <Currency setSelectedCurrency={setSelectedCurrency} selectedCurrency={selectedCurrency}/>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }

            {/* FIXED */}
            {kind === "fixed" && 
              <React.Fragment>
                <div className="row">
                  <div className="col-12 col-md-12 pr-15">
                    <div className="field">
                      <label cassName="dark-color-white">Discount on purchase</label>
                      <div class="input-group">
                        <input type="number" class="form-control form-control-dark" value={amount} onChange={(e) => setAmount(e.target.value)} required="true"/>
                        <Currency setSelectedCurrency={setSelectedCurrency} selectedCurrency={selectedCurrency}/>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
            
          </div>

          <div className="field text-right">
            <button className="btn btn-primary" type="submit">Save</button>
          </div>
        </div>
      </form>
    </React.Fragment>
  )
}

export default Referred