import React, {useEffect, useState, useContext} from 'react';
import { useRouteMatch } from 'react-router-dom'
import axios from "axios";
import User from "./User";
import Invite from "./ProjectUsers/Invite.js"
import ReactTooltip from 'react-tooltip';
import { Modal } from 'react-responsive-modal';
import Skeleton from "react-loading-skeleton"
import {OrganizationContext} from "../Shared/OrganizationContext.js";
import { useTranslation } from 'react-i18next';

const Users = ({project_id, hideItems}) => {
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const organizationAuthorizations= useContext(OrganizationContext)
  const [client, setClient] = useState(!location.pathname.includes("portal") || false)
  const { t } = useTranslation();

  useEffect(()=> {
    fetchProjectUsers()
  },[match.params.project_id])

  function fetchProjectUsers() {
    axios.get(`/api/o/${match.params.organization_id}/projects/${match.params.project_id || project_id}/users.json`)
    .then(function(response){
      // console.log(response)
      if(response.data.success){
        setUsers(response.data.users);
      } else {
        notice(response.data.error)
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setLoaded(true)
    });
  }
  window.fetchProjectUsers = fetchProjectUsers

  const users_list = users.map((user, index) => {
    if (location.href.includes("/portal/o/") && user.private) {
      // If condition is met, return null or an empty fragment to skip rendering
      return null;
    }

    return (
      <User key={user.id} user={user}/>
    );
  });

  return(
    <React.Fragment>
      <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
      <div className="users-list hover-parent-opacity">
        {(organizationAuthorizations.organization_user  && !hideItems )&&
          location.href.includes("/portal/") ?(
            <a className="btn btn-small background-hover color-1 mr-15" href={`/o/${match.params.organization_id}/projects/${match.params.project_id}/tasks`} target="blank">
              <i className="fas fa-repeat mr-5"></i>
              Back to admin view
            </a>
          ):(
            organizationAuthorizations.organization_user &&
            <a
              className="btn btn-small background-hover color-1 mr-15 opacity-6 opacity-10-hover"
              onClick={() => {
                // Set project_id in localStorage
                localStorage.setItem('client_view_project_id', match.params.project_id);
                
                // Navigate user to the specified link
                window.open(`/portal/o/${match.params.organization_id}/projects/${match.params.project_id}/tasks`, '_blank');
              }}
            >
              <i className="fas fa-repeat mr-5"></i>
              View as client
            </a>

          )
        }
        
        {loaded ? (
          users_list
        ):(
          <React.Fragment>
            <div className="users-all-item" style={{width: "30px"}}>
              <Skeleton className="skeleton client-users-item-image" style={{borderRadius: "100%", width: "30px"}}/>
            </div>

            <div className="users-all-item" style={{width: "30px"}}>
              <Skeleton className="skeleton client-users-item-image" style={{borderRadius: "100%", width: "30px"}}/>
            </div>

            <div className="users-all-item" style={{width: "30px"}}>
              <Skeleton className="skeleton client-users-item-image" style={{borderRadius: "100%", width: "30px"}}/>
            </div>

            <div className="users-all-item" style={{width: "30px"}}>
              <Skeleton className="skeleton client-users-item-image" style={{borderRadius: "100%", width: "30px"}}/>
            </div>
          </React.Fragment>
        )}
          
        {(!hideItems) && 
          <React.Fragment>
            {!organizationAuthorizations.project_user && 
              <div data-tip={t("Invite others")} onClick={() => setOpen(true)} className="users-all-item background-3 background-hover">
                <i className="fas fa-user-plus client-users-item-add animate dark-color-black border-all background-hover"></i>
              </div>
            }
            
            {/* Invite client */}
            <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
              <Invite users={users} refetchData={fetchProjectUsers} defaultType={(organizationAuthorizations.organization.organization_user && !location.pathname.includes("/portal/o/")) ? "Workspace" : "Client"}/>
            </Modal>

          </React.Fragment>
        }
      </div>
    </React.Fragment>
  )
}

export default Users; 