import React, {useState} from 'react';
import JSZip from 'jszip';

const ZipDownloader = ({ classNames, files, message, zipFileName}) => {
  const fileUrls = files.map(attachment => attachment.url);
  const [downloading, setDownloading] = useState(false);
  
  const downloadFilesAsZip = () => {
    setDownloading(true);
    const zip = new JSZip();

    const promises = files.map(file => {
      return fetch(file.url)
        .then(response => {
          const contentType = response.headers.get('content-type');
          const extension = contentType.split('/')[1];
          return response.blob().then(blob => ({ blob, extension }));
        })
        .then(({ blob, extension }) => {
          const filename = file.title || file.filename
          zip.file(`${filename}.${extension}`, blob);
        });
    });

    Promise.all(promises)
      .then(() => {
        zip.generateAsync({ type: "blob" })
          .then(blob => {
            const zipFilename = `${zipFileName}.zip`;
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = zipFilename;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            setDownloading(false)
          });
      })
      .catch(error => console.error('Error downloading files as zip:', error));
  };

  return (
    <a disabled={downloading} className={classNames} onClick={downloadFilesAsZip}><i class="fal fa-download mr-8"></i>{downloading ? "Please wait..." : message}</a>
  );
};

export default ZipDownloader;