import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import Files from "./Files.js";
import ReactTooltip from 'react-tooltip';

const Field = ({project_id, field, task_id, refetchData}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);

  // Function to update field data
  const updateFieldData = (newValue) => {
    axios
      .post(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task_id}/update_field_data`, {
        field_token: field.id,
        value: newValue,
      })
      .then(function (response) {
        console.log(response);
        if (response.data.success) {
          refetchData();
          notice("Updated successfully")
          // Do something after successfully updating field data
        } else {
          // Handle error if update fails
        }
      })
      .catch(function (error) {
        console.log(error);
        // Handle error
      });
  };

  return(
    <React.Fragment>
      <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
      <div className="row full-card-info-wrapper">
        <div className="col-3 pr-15 task-question-answer-label">
          <label className="task-list-label" data-tip={field.label}>

            {field.type == "text" && 
              <i class="far fa-horizontal-rule mr-5"></i>
            }

            {field.type == "textarea" && 
              <i class="far fa-grip-lines mr-5"></i>
            }

            {field.type == "link" && 
              <i class="far fa-link mr-5"></i>
            }

            {(field.type == "date" && field.kind == "date") && 
              <i class="far fa-calendar mr-5"></i>
            }

            {field.type == "upload" && 
              <i class="far fa-upload mr-5"></i>
            }

            {field.type == "checkbox" && 
              <i class="far fa-check-square mr-5"></i>
            }

            {field.type == "radio" && 
              <i class="far fa-dot-circle mr-5"></i>
            } 
              {field.label}
          </label>
        </div>

        <div className="col-9 task-question-answer">
          {(field.value == "" && field.kind != "date") && <span className="opacity-4">Empty</span>}
          {field.type === "upload" && 
            <div>
              {typeof field.value === 'string' ? (
                // If field.value is a string, render each link separately
                field.value.split(', ').map((link, index) => (
                  <p key={index}>
                    <a href={link} target="_blank">{link}</a>
                  </p>
                ))
              ) : (
                <>
                  <a className="" onClick={() => setOpen(true)}>
                    {field.value.length} Uploads
                  </a>
                  <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
                    <Files attachements={field.value} zipFileName={field.label}/>
                  </Modal>
                </>
                // // If field.value is an array of objects, render each object separately
                // field.value.map((link, index) => (
                //   <p key={index}>
                //     <a href={link.url} target="_blank">{link.filename}</a>
                //   </p>
                // ))
              )}
            </div>
          }


          {field.kind == "link" && 
            <p>
              <a href={field.value} target="_blank">{field.value}</a>
            </p>
          }

          {(field.kind == "text" && field.type == "text") &&
            <p>{field.value}</p>
          }

          {field.kind == "textarea" && 
            <p>{field.value}</p>
          }

          {field.kind == "date" && 
            <input onChange={(e) => {updateFieldData(e.target.value)}} type="date" defaultValue={field.value} className="form-control-small border-none background-none pl-0" style={{width: "unset"}}/>
          }

          {field.type == "textarea" && 
            <p>{field.value}</p>
          }

          {field.type == "checkbox" && 
            <p>
              {field.value.map(item => {
                return(
                  <span className="btn btn-small-task-question-answer mr-8 background-3 mb-5">{item}</span>
                )
              })}
            </p>
          }

          {field.type == "radio" && 
            <p>
              <span className="badge badge-primary mr-10">{field.value}</span>
            </p>
          }
        </div>
      </div>
    </React.Fragment>
  )
}

export default Field;