import React from 'react';
import Filestack from "../Portal/Projects/TaskForms/Filestack.js";
import Linkify from 'react-linkify';

const UploadField = ({ field, removeField, handleLabelChange, taskForm, handleValueChange }) => {
  return (
    <React.Fragment>
      <div className="daas-task-form-label">
        {!taskForm ? (
          <label className={`task-form-label animate ${!taskForm ? "border-all-blue-hover" : ""}`} style={{cursor: "text"}} contentEditable={!taskForm} onBlur={(e) => handleLabelChange(field.id, e.target.textContent)}>
            {field.label}
          </label>
        ):(
          <label>
            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
              <a href={decoratedHref} key={key} target="_blank">{decoratedText}</a>
            )}>
              {field.label}
            </Linkify>
          </label>
        )}
          
        {!taskForm && 
          <div>
            <i onClick={() => removeField()} className="fas fa-times color-red opacity-4 opacity-10-hover animate padding-8 cursor-pointer"></i>
            {/* <i class="fas fa-grip-vertical ml-10"></i> */}
          </div>
        }
      </div>

      <div className="field">
        <Filestack field={field} handleValueChange={handleValueChange}/>
        <p className="font-12 opacity-4 mt-15">
          {field.value.length} uploaded
        </p>
        {/* <a className="font-12" target="_blank" href={field.value}>{field.value}</a> */}
      </div>
    </React.Fragment>
  );
};

export default UploadField;
