import React , { useState, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import Referrer from "./Referrer.js";
import Referred from "./Referred.js";
import NoAffiliate from "./NoAffiliate.js";
import SetupStatus from "./SetupStatus.js";
import ReferralScript from "./ReferralScript.js";
import Stripe from "../Daas/Stripe/Stripe.js";
import {OrganizationContext} from "../Shared/OrganizationContext.js";
import finishedData from "../Shared/Lotties/lottie-not-allowed.json";
import { Player } from "@lottiefiles/react-lottie-player";

const ReferralSetup = ({affiliate, refetchData, stripeAccount}) => {
  const match = useRouteMatch();
  const organizationAuthorizations = useContext(OrganizationContext);
  const [disabled, setDisabled] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(affiliate?.currency || stripeAccount?.stripe_account_data.default_currency);

  const deleteAffiliate = () => {
    setDisabled(true);

    axios.delete(`/api/o/${match.params.organization_id}/affiliates/${affiliate.token}`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  return(
    <React.Fragment>
      {affiliate != null ? (
        <div className="row">
          {stripeAccount != null ? (
            <React.Fragment>
              <SetupStatus affiliate={affiliate} />
              <Referrer affiliate={affiliate} refetchData={refetchData} setSelectedCurrency={setSelectedCurrency} selectedCurrency={selectedCurrency}/>
              <Referred affiliate={affiliate} refetchData={refetchData} setSelectedCurrency={setSelectedCurrency} selectedCurrency={selectedCurrency}/>
              <ReferralScript affiliate={affiliate}/>

              <div className="text-center mt-25 mb-25">
                <button disabled={disabled} onClick={deleteAffiliate} className="btn btn-danger-outline">Delete referral program</button>
              </div>
            </React.Fragment>
          ):(
            <React.Fragment>
              {(organizationAuthorizations.organization.paypal_account && !organizationAuthorizations.organization.stripe_account) ? (
                <div className="text-center large-padding">
                  <Player style={{width: "200px", margin: "auto"}} mode="normal" autoplay loop src={finishedData} />
                  <p className="mt-15 opacity-6">Sorry, only Stripe is supported for this. Supporting PayPal soon!</p>

                  <div className="text-center mt-25 mb-25">
                    <button disabled={disabled} onClick={deleteAffiliate} className="btn btn-danger-outline">Delete referral program</button>
                  </div>
                </div>
              ):(
                <Stripe title="Create a referral program" description="Connect your Stripe account to get started" refetchData={refetchData}/>
              )}
            </React.Fragment>
          )}
        </div>
      ):(
        <div className="row">
          <div className="background-3 border-radius border-all default-padding no-affiliate-wrapper">
            <NoAffiliate refetchData={refetchData}/>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default ReferralSetup