import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import Overview from "./Overview.js";
import ReferralSetup from "./ReferralSetup.js";
import axios from "axios"; 
import {CopyToClipboard} from 'react-copy-to-clipboard';

const ReferralRouter = () => {
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false);
  const [affiliate, setAffiliate] = useState(null);
  const [stripeAccount, setStripeAccount] = useState(null);

  useEffect(() => {
    fetchAffiliate();
  }, []);

  const fetchAffiliate = () => {
    axios.get(`/api/o/${match.params.organization_id}/affiliates.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setAffiliate(response.data.affiliate);
        setStripeAccount(response.data.stripe_account)
        setLoaded(true)
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  return(
    loaded && 
    <React.Fragment>
      <title>Referral Program</title>
      <div className="container mt-25 mb-25">
        <div className="row">
          <h1 className="font-weight-600 mb-15" style={{marginTop: "0"}}>Referral Program</h1>
          <p className="opacity-5" style={{display: "flex", justifyContent: "space-between"}}>
            <div>
              Enable your clients and friends to bring you new customers with incentives.
            </div>
            <CopyToClipboard text={`https://${window.location.host}/affiliates`} onCopy={() => notice("Link copied")}>
              <div className="cursor-pointer font-weight-600 opacity-5 opacity-10-hover">
                <i class="fal fa-link font-weight-600 mr-5"></i> Affiliate Portal
              </div>
            </CopyToClipboard>
          </p>
          <ul class="bootstrap-nav default-padding border-radius border-all background-3">
            <li class="bootstrap-nav-item">
              <NavLink exact activeClassName="active-tab" className="bootstrap-nav-tab" to={`/o/${match.params.organization_id}/referrals`}>Overview</NavLink>
            </li>
            
            <li class="bootstrap-nav-item">
              <NavLink activeClassName="active-tab" className="bootstrap-nav-tab" to={`/o/${match.params.organization_id}/referrals/setup`}>Program Settings</NavLink>
            </li>
          </ul>
        </div>

        <div className="mt-15 mb-35">
          <Switch>
            <Route exact path="/o/:organization_id/referrals" render={() => <Overview affiliate={affiliate} refetchData={fetchAffiliate}/>} />
            <Route exact path="/o/:organization_id/referrals/setup" render={() => <ReferralSetup affiliate={affiliate} refetchData={fetchAffiliate} stripeAccount={stripeAccount}/>} />
          </Switch>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ReferralRouter