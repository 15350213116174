import React , { useState } from 'react';
import { useRouteMatch } from 'react-router-dom'
import axios from "axios"; 

const AddonDropdown = ({serviceAddon, refetchData}) => {
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false);

  const handleDelete = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.delete(`/api/o/${match.params.organization_id}/service_addons/${serviceAddon.token}`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        notice("Removed add-on");
        refetchData();
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  return(
    <div class="btn-group">
      <a type="button" class="btn btn-small color-1 dropdown-toggle opacity-6 opacity-10-hover" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="far fa-ellipsis-v"></i>
      </a>
      <ul class="dropdown-menu pull-right animated fadeInUp">
        <li>
          <a onClick={handleDelete} disabled={disabled}>Delete</a>
        </li>
      </ul>
    </div>
  )
}

export default AddonDropdown;