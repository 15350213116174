import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
import {ProjectContext} from "../Projects/ProjectContext.js";
import ReactTooltip from 'react-tooltip';
import { confirmAlert } from 'react-confirm-alert'; // Import


const EmailedTask = ({emailed_task, refetchData}) => {
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false);
  const {fetchChatroomsUnread} = useContext(ProjectContext);

  const archive = () => {
    confirmAlert({
      title: 'Confirm Archive',
      message: 'Are you sure you want to archive this emailed task?',
      buttons: [
        {
          label: 'No',
          className: 'btn background-3 btn-small',
          onClick: () => {} // Do nothing if "No" is clicked
        },
        {
          label: 'Yes',
          className: 'btn btn-danger btn-small',
          onClick: () => performArchive()
        },
      ]
    });
  }

  const performArchive = () => {
    setDisabled(true);

    axios.delete(`/api/o/${match.params.organization_id}/emailed_tasks/${emailed_task.token}`)
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        refetchData();
        fetchChatroomsUnread();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }


  return(
    <React.Fragment>
      <>
        <NavLink to={`/o/${match.params.organization_id}/chatrooms/emailed_tasks/${emailed_task.token}`} activeClassName="background-active" className="color-1 background-2 border-radius mb-5 background-hover">
          <div className="chatroom-item-wrapper hover-parent-opacity">
            <div className="chatroom-item-title">
              <div className="truncate">
                {emailed_task.subject}
              </div>

              <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
              <div className="hover-child-opacity" data-tip="Delete">
                <i onClick={(e) => {e.stopPropagation();e.preventDefault();archive();}} disabled={disabled} class="fal fa-archive btn btn-small background-hover opacity-6 opacity-10-hover" ></i>
              </div>
            </div>

            <div className="chatroom-item-message">
              <div>
                {emailed_task.from}
              </div>

              <div className="font-10 opacity-6">
                {emailed_task.created_at}
              </div>
            </div>
          </div>
        </NavLink>
      </>
      {/* <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <h4 className="color-black-always modal-title">Modal title</h4>

        <div className="row no-margin">
          Modal content here 
        </div>
      </Modal> */}
    </React.Fragment>
  )
}

export default EmailedTask;