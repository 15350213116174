import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import ZipDownloader from "../../../../Shared/ZipDownloader.js";

const Files = ({attachements, zipFileName}) => {
  const files_list = attachements.map(attachement => {
    // const downloadFile = (download_link) => {
    //   const url = download_link;
  
    //   // Get the last portion of the URL after the last "/"
    //   const filename = url.substring(url.lastIndexOf("/") + 1);
  
    //   // Get the file extension by getting the portion of the filename after the last "."
    //   const fileExtension = filename.substring(filename.lastIndexOf(".") + 1);
  
    //   console.log(fileExtension); // Output: "png"
  
    //   fetch(replay.download_link, {
    //     method: 'GET',
    //     headers: {
    //       'Content-Disposition': 'attachment'
    //     }
    //   })
    //   .then(response => response.blob())
    //   .then(blob => {
    //     // Create a URL for the blob
    //     const url = URL.createObjectURL(blob);

    //     // Create a link element with the download attribute
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', replay.title.includes(fileExtension) ? `${replay.title}` : `${replay.title}`);

    //     // Add the link to the page and click it to download the file
    //     document.body.appendChild(link);
    //     link.click();

    //     // Cleanup the URL and link element
    //     URL.revokeObjectURL(url);
    //     link.remove();
    //   })
    //   .catch(error => console.error(error));
    // }
    return(
      <div className="task-files-list-item">
        <a className="font-weight-600 color-1 opacity-6 opacity-10-hover" href={attachement.url} target="_blank">
          <i class="fal fa-file mr-8"></i> {attachement.title || attachement.filename}
        </a>

        {/* {(attachement.url.includes("digitaloceanspaces") || replay.download_link.includes("filestack")) && 
          <>
            {replay.download_link.includes("digitaloceanspaces") ? (
              <a className="btn btn-primary-outline btn-small" onClick={() => downloadFile(attachement.url)}>
                <i className="fal fa-download"></i> Download
              </a>
            ):(
              <a className="btn btn-primary-outline btn-small" href={attachement.url} target="_blank" download={attachement.title || attachement.filename}>
                <i className="fal fa-download"></i> Download
              </a>
            )}
          </>
        } */}

        <ZipDownloader message="Download" classNames="btn btn-primary-outline btn-small" files={[attachement]} zipFileName={attachement.title || attachement.filename}/>
      </div>
    )
  })

  return(
    <React.Fragment>
      <h4 className="color-black-always modal-title">Files</h4>

      <div className="row task-files-list-modal-wrapper mb-25">
        {files_list}
      </div>

      <ZipDownloader message={`Download all (${attachements.length})`} classNames="btn btn-primary btn-small mt-25 display-block" files={attachements} zipFileName={zipFileName}/>
    </React.Fragment>
  )
}

export default Files;