import React, {useEffect, useCallback} from 'react';
import ReactTooltip from 'react-tooltip';
import { NavLink, useHistory } from 'react-router-dom'
import ProjectDropdown from "./ProjectDropdown.js";

const Project = ({project, fetchProjects, privateProject, index}) => {
  const history = useHistory();
  // const [show, setShow] = useState(location.href.includes(`/portal/o/`) ? (location.href.includes(project.token)) : true)

  // const onKey = useCallback(
  //   (e) => {
  //     if (e.shiftKey && e.code === `Digit${index + 1}`) {
  //       history.push(`${(project.project_access_type === "Client" || location.href.includes(`/portal/o/`)) ? "/portal" : ""}/o/${project.organization.token}/projects/${project.token}/tasks`);
  //     }
  //   },
  //   [history, index, project.project_access_type, project.organization.token, project.token]
  // );

  // useEffect(() => {
  //   window.addEventListener('keydown', onKey);
  //   return () => {
  //     window.removeEventListener('keydown', onKey);
  //   };
  // }, [onKey]);

  
  return (
    // show && 
    <li  className="daas-platform-links-li color-1 hover-parent-opacity hover-parent">
      <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200} html={true}/>

      <NavLink activeClassName="background-active" to={`${(project.project_access_type === "Client" || location.href.includes(`/portal/o/`)) ? "/portal" : ""}/o/${project.organization.token}/projects/${project.token}/tasks`} className={`daas-platform-links-a sidebar-text-color truncate ${location.href.includes(project.token) ? "background-active" :""}`}>
        <span className="sidebar-text-color">
          <i class="sidebar-text-color fas fa-grip-vertical projects-move-icon"></i>
        </span> 
        <img src={project.avatar} className="project-list-avatar" />
        {project.title} {project.unreads && <span className="project-badge-notification"></span>} {privateProject && <i class="fas fa-lock opacity-6 sidebar-text-color font-10 ml-5"></i>}
        {(project.status != null) && 
          <React.Fragment>
            {project.status.paused && 
              <i data-tip="Paused" className="fas fa-pause color-yellow ml-5"></i>
            }

            {
              (project.status.cancel_at_period_end && project.status.expired) && 
              <i data-tip="Cancelled" className="fas fa-times color-red ml-5"></i>
            }
          </React.Fragment>
        }
      </NavLink>
      
      {!location.pathname.includes("/portal/o/") && 
        <div class="btn-group">
          <ProjectDropdown project={project} refetchData={fetchProjects}/>
        </div>
      }
    </li>
  )
}

export default Project