import React , { useState, useEffect, useRef } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import EmailEditor, { EditorRef, EmailEditorProps } from 'react-email-editor';
import { Modal } from 'react-responsive-modal';

const WelcomeEmail = ({organization, refetchData}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [email, setEmail] = useState(null);
  const [text, setText] = useState(null);
  const emailEditorRef = useRef(null);
  const [recipientEmail, setRecipientEmail] = useState(null);
  const [subject, setSubject] = useState(null);

  useEffect(() => {
    fetchEmails();
  }, []);

  // Fetch email 
  const fetchEmails = () => {
    axios.get(`/api/o/${match.params.organization_id}/emails?identifier=welcome_email`)
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        setEmail(response.data.email);
        setLoaded(true);
        if(response.data.email != null){
          setSubject(response.data.email.subject);
        }
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const deleteEmail = () => {
    const confirmThis = confirm("Are you sure?")
    if(confirmThis){
      axios.delete(`/api/o/${match.params.organization_id}/emails/${email.token}`)
      .then(function(response){
        // console.log(response);
        if(response.data.success){
          setEmail(response.data.email);
          setLoaded(true);
          if(response.data.email != null){
            // setSubject(response.data.email.subject);
          }
        } else {
  
        }
      })
      .catch(function(error){
        console.log(error)
        notice("An error occured")
      })
      .then(function () {
        // always executed
      });
    }
  }

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      // console.log('exportHtml', html);
      console.log(data)
      setText(html);
      setDisabled(true);

      axios.put(`/api/o/${match.params.organization_id}/emails/${email.token}`, {
        text: html,
        subject: subject,
        data: data
      })
      .then(function(response){
        // console.log(response);
        if(response.data.success){
          notice("Publish successful")
          setOpen(false);
          fetchEmails();
        } else {
          response.data.errors.forEach((error) => {
            notice(error);
          });
        }
      })
      .catch(function(error){
        console.log(error)
        notice("An error occured");
        reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
      })
      .then(function () {
        setDisabled(false);
      });
    });
  };

  // Create welcome email
  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/o/${match.params.organization_id}/emails?identifier=welcome_email`)
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        fetchEmails();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  // View preview of the email
  const viewPreview = (evt) => {
    evt.preventDefault();

    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      console.log('exportHtml', html);
      setText(html);
      setDisabled(true);

      axios.post(`/api/o/${match.params.organization_id}/emails/${email.token}/view_preview`, {
        text: html, 
        recipient_email: recipientEmail, 
        subject: subject
      })
      .then(function(response){
        // console.log(response);
        if(response.data.success){
          notice("Publish successful")
          fetchEmails();
        } else {
          response.data.errors.forEach((error) => {
            notice(error);
          });
        }
      })
      .catch(function(error){
        console.log(error)
        notice("An error occured");
        reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
      })
      .then(function () {
        setDisabled(false);
      });
    });
  }


  return(
    loaded &&
    <React.Fragment>
      {email != null ? (
        <>
          <h4 className="font-weight-600">Welcome email</h4>
          <p className="opacity-7 text-12" style={{marginTop: "-5px"}}>Welcome email is sent out when a client purchases any service. </p> 
          <div className="field row">
            <div className="col-6">
              <input className="form-control background-none border-none" placeholder="Subject line" defaultValue={subject} onChange={(e) => setSubject(e.target.value)}/>
            </div>

            <div className="col-6 text-right">
              <a onClick={deleteEmail} className="mr-15 color-red opacity-4 opacity-10-hover">Remove welcome email</a>
              <button onClick={() => setOpen(true)} className="btn btn-primary-outline mr-15"><i class="fal fa-eye mr-8"></i>Preview</button>
              <button onClick={exportHtml} className="btn btn-primary"><i class="fal fa-save mr-8"></i>Publish  </button>
            </div>
          </div>
          <EmailEditor ref={emailEditorRef} onLoad={() => {email.data != null && emailEditorRef.current.editor.loadDesign(email.data.design)}} onReady={() => console.log('onReady')} style={{height: "calc(100vh - 250px)", borderRadius: "5px"}}/>

          <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
            <h4 className="color-black-always modal-title">Preview your email template</h4>
            <div className="row no-margin">
              <form onSubmit={viewPreview}>
                <div className="field">
                  <label>Email</label>
                  <input onChange={(e) => setRecipientEmail(e.target.value)} className="form-control" type="email" required="true"/>
                </div>

                <div className="field text-right">
                  <button type="submit" className="btn btn-primary">Submit</button>
                </div>
              </form>
            </div>
          </Modal>
        </>
      ):(
        <div className="text-center content-center center-content animated fadeIn" style={{height: "75vh"}}>
          <h4>Custom welcome email</h4>
          <p className="opacity-4">
            Setup a custom welcome email that'll be recieved when a client purchases a service or is invited.
          </p>

          <form onSubmit={handleSubmit}>
            <button className="btn btn-primary mt-15" type="submit" disabled={disabled}>Create welcome email</button>
          </form>
        </div>
      )}
    </React.Fragment>
  )
}

export default WelcomeEmail;