import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Stripe from "./Stripe.js";
import Domain from "../Seller/Domain.js";
import EditWorkspace from "../../Organizations/EditWorkspace.js";
import Snippets from "../../Organizations/Snippets/Snippets.js";
import CheckoutSettings from "./CheckoutSettings.js";
import EmailSetup from "../../Organizations/Setup/EmailSetup.js";
import EditTheme from "../../Organizations/EditTheme.js";
import ClientPortal from "../Settings/ClientPortal.js";
import WelcomeEmail from "../Settings/WelcomeEmail.js";
import EmailLogs from "../../EmailLogs/EmailLogs.js";

const Settings = () => {
  const match = useRouteMatch();
  const [organization, setOrganization] = useState(null);
  const [loaded, setLoaded] = useState(false);
  
  useEffect(() => {
    fetchOrganization()
  }, [])

  const fetchOrganization = () => {
    axios.get(`/api/o/${match.params.organization_id}.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setOrganization(response.data.organization);
        setLoaded(true);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  return(
    loaded && 
    <React.Fragment>
      <title>Settings</title>
      <div className="container mt-25 mb-25">
        <div className="row">
          <ul class="bootstrap-nav background-3 default-padding border-radius border-all">
            <li class="bootstrap-nav-item">
              <NavLink activeClassName="active-tab" className="bootstrap-nav-tab background-hover" to={`/o/${match.params.organization_id}/settings/company`}>Company</NavLink>
            </li>

            <li class="bootstrap-nav-item">
              <NavLink activeClassName="active-tab" className="bootstrap-nav-tab background-hover" to={`/o/${match.params.organization_id}/settings/theme`}>Theme</NavLink>
            </li>
            
            <li class="bootstrap-nav-item">
              <NavLink activeClassName="active-tab" className="bootstrap-nav-tab background-hover" to={`/o/${match.params.organization_id}/settings/domain`}>Domain setup</NavLink>
            </li>

            <li class="bootstrap-nav-item">
              <NavLink activeClassName="active-tab" className="bootstrap-nav-tab background-hover" to={`/o/${match.params.organization_id}/settings/email_setup`}>Email setup</NavLink>
            </li>

            <li class="bootstrap-nav-item">
              <NavLink activeClassName="active-tab" className="bootstrap-nav-tab background-hover" to={`/o/${match.params.organization_id}/settings/welcome-email`}>Welcome email</NavLink>
            </li>

            <li class="bootstrap-nav-item">
              <NavLink activeClassName="active-tab" className="bootstrap-nav-tab background-hover" to={`/o/${match.params.organization_id}/settings/email-logs`}>Email Logs</NavLink>
            </li>

            <li class="bootstrap-nav-item">
              <NavLink activeClassName="active-tab" className="bootstrap-nav-tab background-hover" to={`/o/${match.params.organization_id}/settings/client-portal`}>Client Portal</NavLink>
            </li>

            <li class="bootstrap-nav-item">
              <NavLink exact activeClassName="active-tab" className="bootstrap-nav-tab background-hover" to={`/o/${match.params.organization_id}/settings`}>Checkout</NavLink>
            </li>

            <li class="bootstrap-nav-item">
              <NavLink activeClassName="active-tab" className="bootstrap-nav-tab background-hover" to={`/o/${match.params.organization_id}/settings/3rd-party-integrations`}>3rd Party Integrations</NavLink>
            </li>
          </ul>
        </div>

        <div className="mt-15">
          <Switch>
            <Route exact path="/o/:organization_id/settings" render={() => <CheckoutSettings organization={organization} refetchData={fetchOrganization}/>} />
            <Route exact path="/o/:organization_id/settings/domain" render={() => <Domain/>} />
            <Route exact path="/o/:organization_id/settings/company" render={() => <EditWorkspace/>}/>
            <Route exact path="/o/:organization_id/settings/email_setup" render={() => <EmailSetup/>}/>
            <Route exact path="/o/:organization_id/settings/email-logs" render={() => <EmailLogs emailable_type={null}/>}/>
            <Route exact path="/o/:organization_id/settings/theme" render={() => <EditTheme organization={organization} refetchData={fetchOrganization}/>}/>
            <Route exact path="/o/:organization_id/settings/client-portal" render={() => <ClientPortal organization={organization} refetchData={fetchOrganization}/>}/>
            <Route exact path="/o/:organization_id/settings/welcome-email" render={() => <WelcomeEmail organization={organization} refetchData={fetchOrganization}/>}/>
            <Route exact path="/o/:organization_id/settings/3rd-party-integrations" render={() => <Snippets/>}/>
          </Switch>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Settings