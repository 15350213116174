import React from 'react';

const Loading = ({loading}) => {
  return(
    loading && 
    <div className="loaded-wrapper background-3 border-all border-radius box-shadow animated fadeIn slow">
      <i className="fas fa-spinner fa-spin mr-8"></i>Fetching fresh data...
    </div>
  )
}

export default Loading 