import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import SetupStatus from "./SetupStatus.js";

const Overview = ({affiliate, refetchData}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [affiliatePartners, setAffiliatePartners] = useState([]);
  const [affiliateReferrals, setAffiliateReferrals] = useState([]);
  const [totalEarned, setTotalEarned] = useState(null);
  const [currency, setCurrency] = useState("usd");

  useEffect(() => {
    if(affiliate != null){
      fetchAffiliateReferrals()
    }
  },[affiliate])

  const fetchAffiliateReferrals = () => {
    axios.get(`/api/o/${match.params.organization_id}/affiliate_referrals.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setCurrency(response.data.currency);
        const validAmounts = response.data.all_affiliate_referrals.filter((item) => item.service_checkout.amount !== null);
        const totalAmount = validAmounts.reduce((accumulator, item) => accumulator + item.service_checkout.amount, 0);
        setTotalEarned(totalAmount)

        setAffiliatePartners(response.data.affiliate_partners)
        setAffiliateReferrals(response.data.all_affiliate_referrals)
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const affiliate_referrals_list = affiliateReferrals.map(referral => {
    return(
      <tr className="background-3 tr-hover">
        <td>{referral.user.username}</td>
        <td>{referral.user.email}</td>
        <td className="font-weight-600">
          {(parseFloat((referral.service_checkout.amount / 100).toFixed(2))).toLocaleString('en-US', {style: 'currency',currency: currency})}
        </td>
        <td className="text-right">
          {(paparseFloatrseInt((referral.sender_amount / 100).toFixed(2))).toLocaleString('en-US', {style: 'currency',currency: currency})}
        </td>
        <td>{referral.affiliate_partner.user.username}</td>
        <td>
          {referral.created_at}
        </td>
        <td>
          {referral.stripe_transfer_data != null &&
            <i className="fas fa-check-square color-green"></i>
          }
        </td>
      </tr>
    )
  });

  const affiliate_partners_list = affiliatePartners.map(partner => {
    return(
      <tr className="background-3 tr-hover">
        <td>{partner.user.username}</td>
        <td>{partner.user.email}</td>
        <td>{partner.token}</td>
        <td>{partner.created_at}</td>
      </tr>
    )
  })

  return(
    <React.Fragment>
      {affiliate === null ?(
        <div className="banner-danger">
          You have no referral program setup, yet. Please go <Link to={`/o/${match.params.organization_id}/referrals/setup`} className="color-blue">here</Link> to set it up. 
        </div>
      ):(
        <SetupStatus affiliate={affiliate}/>
      )}
      <div className="row" style={{marginLeft: "-15px", marginRight: "-15px"}}>
        <div className="col-12 col-md-3 default-padding">
          <div className="background-3 border-radius default-padding border-all">
            <label className="opacity-4 dark-color-white">
              Revenue Generated
            </label>

            <h2 className="font-weight-600 mt-0">
              {currency != null ?(
                <React.Fragment>
                  {(parseFloat((totalEarned / 100).toFixed(2))).toLocaleString('en-US', {style: 'currency',currency: currency})}
                </React.Fragment>
              ):(
                "0.00"
              )
              }
            </h2>
          </div>
        </div>

        <div className="col-12 col-md-3 default-padding">
          <div className="background-3 border-radius default-padding border-all">
            <label className="opacity-4 dark-color-white">
              Affiliate Partners
            </label>
            <h2 className="font-weight-600 mt-0">
              {affiliatePartners.length}
            </h2>
          </div>
        </div>

        <div className="col-12 col-md-3 default-padding">
          <div className="background-3 border-radius default-padding border-all">
            <label className="opacity-4 dark-color-white">
              Referred
            </label>

            <h2 className="font-weight-600 mt-0">
              {affiliateReferrals.length}
            </h2>
          </div>
        </div>

        {/* <div className="col-12 col-md-3 default-padding">
          <div className="background-3 border-radius default-padding border-all">
            <label className="opacity-4">
              Reward Given
            </label>

            <h2 className="font-weight-600 mt-0">$450.00</h2>
          </div>
        </div> */}
      </div>

      <div className="row" style={{marginLeft: "-15px", marginRight: "-15px"}}>
        <div className="col-12 col-md-12 default-padding">
          <div className="background-3 border-radius default-padding border-all">
            <label className="pl-15 dark-color-white">Recent Referrals</label>

            <table class="table react-table-table no-padding">
              <thead>
                <tr>
                  <th className="react-table-th" scope="col">Buyer name</th>
                  <th className="react-table-th" scope="col">Buyer email</th>
                  <th className="react-table-th" scope="col">Purchased</th>
                  <th className="react-table-th" scope="col">Reward</th>
                  <th className="react-table-th" scope="col">Affiliate Partner</th>
                  <th className="react-table-th" scope="col">Date</th>
                  <th className="react-table-th" scope="col">Payout status</th>
                </tr>
              </thead>

              <tbody>
                {affiliate_referrals_list}
              </tbody>
            </table>
          </div>
        </div>

        <div className="col-12 col-md-12 default-padding">
          <div className="background-3 border-radius default-padding border-all">
            <label className="pl-15 dark-color-white">Affiliate partners</label>

            <table class="table react-table-table no-padding">
              <thead>
                <tr>
                  <th className="react-table-th" scope="col">Name</th>
                  <th className="react-table-th" scope="col">Email</th>
                  <th className="react-table-th" scope="col">Code</th>
                  <th className="react-table-th" scope="col">Created on</th>
                </tr>
              </thead>

              <tbody>
                {affiliate_partners_list}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Overview