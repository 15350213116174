import React, { useState, useEffect, useContext } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import {UserContext} from "../Shared/UserContext.js";
import axios from "axios"; 
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs'; // Choose a code style
import {CopyToClipboard} from 'react-copy-to-clipboard';
import WidgetWelcomeMessage from "./Widget/WidgetWelcomeMessage.js";
import { Modal } from 'react-responsive-modal';
import { PickerInline } from 'filestack-react';
import {OrganizationContext} from "../Shared/OrganizationContext.js";
import Premium from "../Subscriptions/Premium.js";

const WidgetSetup = ({}) => {
  const match = useRouteMatch();
  const currentUser = useContext(UserContext);
  const organizationAuthorizations = useContext(OrganizationContext);
  const [loaded, setLoaded] = useState(false);
  const [tester, setTester] = useState(false);
  const code = `<script id="queue-chat-widget-script" async src="https://app.usequeue.com/chat_widget.js" data-organization_id="${match.params.organization_id}"></script>`;
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [organization, setOrganization] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [openSubscriptionModal, SetOpenSubscriptionModal] = useState(false);

  useEffect(() => {
    fetchTesters();
    fetchWelcomeMessage();
  },[match.params.organization_id]);

  const fetchTesters = () => {
    axios.get(`/testers.json?organization_id=${match.params.organization_id}`)
    .then(function(response){
      console.log(response); 
      if(response.data.success){
        setTester(response.data.chatroom_tester);
        // setOrganization(response.data.organization);
        // setWelcomeMessage(response.data.organization.welcome_message)
        setLoaded(true);
      }
    })
  }

  const fetchWelcomeMessage = () => {
    axios.get(`/api/o/${match.params.organization_id}/widget_data.json`)
    .then(function(response){
      console.log(response); 
      if(response.data.success){
        setOrganization(response.data.organization);
        setWelcomeMessage(response.data.organization.welcome_message);
        setImage(response.data.organization.widget_avatar);
      }
    })
  }

  const joinBeta = () => {
    axios.post(`/testers.json`, {
      organization_id: match.params.organization_id,
      kind: "chatroom"
    })
    .then(function(response){
      console.log(response); 
      if(response.data.success){
        fetchTesters()
      }
    })
  }

  const unjoinBeta = () => {
    axios.delete(`/testers/${tester.id}`, {
      organization_id: match.params.organization_id,
      kind: "chatroom"
    })
    .then(function(response){
      console.log(response); 
      if(response.data.success){
        fetchTesters();
      }
    })
  }

  const handleWelcomeMessageSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.put(`/api/o/${match.params.organization_id}`, {
      welcome_message: welcomeMessage,
      widget_avatar: image
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        notice("Update successful")
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  };

  const createReplay = (filestack) => {
    const filesUploaded = filestack.filesUploaded;
    const imageFiles = Object.values(filesUploaded).filter(file => file.mimetype.startsWith('image/'));
    if (imageFiles.length > 0) {
      const imageFile = imageFiles[0];
      console.log(imageFile);
      setImage(imageFile.url);
      setOpen(false);
    } else {
      console.log('No image file uploaded');
    }
  }

  return(
    loaded &&
    <React.Fragment>
      <div className="chatrooms-wrapper-index">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-6 default-padding" style={{height: "calc(100vh - 55px)", overflow: "auto"}}>
            <div className="background-3 border-all mb-25 pr-15 border-radius default-padding">
              <h4 className="mt-0 font-weight-600">
                <h1 className="mr-5 display-inline mt-0">1</h1>
                Install chat widget
              </h4>
              <p className="opacity-4">Insert our script tag in your website's head tag on your website to talk with visitors.</p>
              <div className="row">
                <div className="col-11">
                  <SyntaxHighlighter language="javascript" style={docco}>
                    {code}
                  </SyntaxHighlighter>
                </div>

                <div className="col-1">
                  <CopyToClipboard text={code} onCopy={() => {notice("Copied Code")}}>
                    <button className="btn btn-small btn-primary-outline border-none"><i className="fal fa-copy"></i></button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>

            {/* Launcher icon */}
            <div className="background-3 border-all mb-25 mt-25 pr-15 border-radius default-padding width-100-percent">
              <h4 className="mt-0 font-weight-600">
                <h1 className="mr-5 display-inline mt-0">2</h1>
                Customize the Launcher
              </h4>
              <p className="opacity-4">Change the launcher icon to a custom one</p>
              <div className="row">
                <div className="col-12">
                  <form onSubmit={handleWelcomeMessageSubmit}>
                    <div className="field">
                      <img className="avatar-md" src={image || "https://uploads-ssl.webflow.com/64b74e5ee7672852e391da2c/658ef416c7b40fc6418e3656_256x256.png"} />
                      <a onClick={() => setOpen(true)} className="btn btn-small color-1" style={{paddingLeft: "5"}}>
                        <i class="far fa-pencil mr-5"></i> Change 
                      </a>
                      
                      <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
                        <PickerInline
                          apikey={"AKVhxMbEQhkOIryqBvSEQz"}
                          onSuccess={(res) => console.log(res)}
                          onUploadDone={(res) => {
                            createReplay(res);
                          }}
                          clientOptions={{
                            sessionCache: true
                          }}
                          pickerOptions={{
                            onClose: () => {
                              setOpen(false);
                            }, 
                            fromSources: ["local_file_system", "googledrive", "dropbox", "box", "onedrive", "onedriveforbusiness", "googlephotos", "facebook", "instagram"],
                            maxFiles: 1,
                            accept: 'image/*'
                          }}
                        />
                      </Modal>
                    </div>

                    <div className="field text-right">
                      {organizationAuthorizations.organization_subscribed.allow_access ? (
                        <button className="btn btn-primary" type="submit">Save</button>
                        ):(
                        <a className="btn btn-primary" onClick={() => SetOpenSubscriptionModal(true)}><i class="fal fa-lock mr-5"></i>Premium feature</a>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>

            {/* Welcome message */}
            <div className="background-3 border-all mb-25 mt-25 pr-15 border-radius default-padding width-100-percent">
              <h4 className="mt-0 font-weight-600">
                <h1 className="mr-5 display-inline mt-0">3</h1>
                Welcome message
              </h4>
              <p className="opacity-4">Add a welcome message for visitors to encourage a conversation.</p>
              <div className="row">
                <div className="col-12">
                  <form onSubmit={handleWelcomeMessageSubmit}>
                    <div className="field">
                      <textarea rows="5" className="form-control form-control-dark" placeholder="Welcome message" value={welcomeMessage} onChange={(e) => setWelcomeMessage(e.target.value)}/>
                    </div>
                    
                    <div className="field text-right">
                      {organizationAuthorizations.organization_subscribed.allow_access ? (
                        <button disabled={disabled || welcomeMessage === ""} class="btn btn-primary" type="submit">Save</button>
                        ):(
                        <a className="btn btn-primary" onClick={() => SetOpenSubscriptionModal(true)}><i class="fal fa-lock mr-5"></i>Premium feature</a>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
            
            {/* Mobile app */}
            <div className="background-3 border-all mb-25 mt-25 pr-15 border-radius default-padding width-100-percent">
              <h4 className="mt-0 font-weight-600">
                <h1 className="mr-5 display-inline mt-0">4</h1>
                Download IOS app (BETA)
              </h4>
              <p className="opacity-4">Never miss a message from a lead. Please contact support for a secure password to login.</p>
              <div className="row">
                <div className="col-12 field text-right mt-15">
                  <a className="btn btn-primary" href="https://apps.apple.com/us/app/queue-how-agencies-operate/id1501942318" target="_blank"><i class="fab fa-apple color-white-always mr-5"></i>Download</a>
                </div>
              </div>
            </div>
            <p className="mt-5 text-center"><a className="color-blue" onClick={unjoinBeta}> Click here to opt out</a></p>
          </div>

          {/* Widget preview */}
          <div className="col-12 col-md-6 col-lg-6">
            <div className="welcome-message-framer">
              <div className="welcome-message-framer-wrapper">
                <WidgetWelcomeMessage welcome_message={welcomeMessage}/>
                <img className="avatar-md mt-15" src={image || "https://uploads-ssl.webflow.com/64b74e5ee7672852e391da2c/658ef416c7b40fc6418e3656_256x256.png"} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal open={openSubscriptionModal} onClose={() => SetOpenSubscriptionModal(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <Premium organization_id={match.params.organization_id}/>
      </Modal>
    </React.Fragment>
  )
}

export default WidgetSetup;