import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import ServiceAddon from "./ServiceAddon.js";
import Form from "../Services/Form.js"
import { Modal } from 'react-responsive-modal';
import Stripe from "../Stripe/Stripe.js";
import {UserContext} from "../../Shared/UserContext.js";
import VideoGuide from "../../Shared/VideoGuide.js";
import DashboardSkeleton from "../Seller/DashboardSkeleton.js";

const Addons = () => {
  const history = useHistory();
  const currentUser = useContext(UserContext);
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [serviceAddons, setServicesAddons] = useState([]);
  const [stripeAccount, setStripeAccount] = useState(null);

  useEffect(() => {
    fetchAddons();
  }, []);

  const fetchAddons = () => {
    axios.get(`/api/o/${match.params.organization_id}/service_addons.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setServicesAddons(response.data.service_addons); 
        setStripeAccount(response.data.stripe_account)
        setLoaded(true)
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
    });
  }

  const service_addons_list = serviceAddons.map(service_addon => {
    return (
      <React.Fragment>
        <ServiceAddon serviceAddon={service_addon} service={service_addon.service} refetchData={fetchAddons} stripeAccount={stripeAccount}/>
      </React.Fragment>
    )
  })

  return(
    loaded ? (

      <div className="container mt-25">
        <title>Addons</title>
        <div className="row no-margin">
          <div className="header-page">
            <div>

            </div>

            <a onClick={() => setOpen(true)} className="btn btn-primary" disabled={stripeAccount == null}>
              <i className="fas fa-plus mr-5"></i>Create Add-on
            </a>  
          </div>

          {/* CTA */}
          <div className="row no-margin">
            <div className="col-12 text-right">
              {stripeAccount != null && 
                <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
                  <Form closeModal={() => setOpen(false)} refetchData={fetchAddons} currency={stripeAccount.stripe_account_data.default_currency} addon={true}/>
                </Modal>
              }
            </div>
          </div>

          {/* Services */}
          <div className="row services-list">
            <div className="col-12 no-padding">
              {stripeAccount == null ?(
                <div className="stripe-flex-container">
                  <Stripe title="Create subscriptions and services" description="Connect your Stripe account to get started" refetchData={fetchAddons}/>
                </div>
              ):(
                <React.Fragment>
                  {serviceAddons.length > 0 ? (
                    <table class="table table-bordered table-responsive background-3">
                      <thead className="background-3">
                        <tr>
                          <th className="service-item-td" scope="col">Add-on</th>
                          <th className="service-item-td" scope="col">Price</th>
                          <th className="service-item-td" scope="col">Service</th>
                          <th className="service-item-td" scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {service_addons_list}
                      </tbody>
                    </table>
                  ):(
                    <div className="information-wrapper">
                      <lottie-player class="information-lottie" autoplay loop mode="normal" src="https://assets9.lottiefiles.com/packages/lf20_ssrGyJ7BiX.json"></lottie-player>
                      Create Add-ons that enables you to upsell additional services to your clients on checkout 
                      {/* <VideoGuide src="https://www.loom.com/embed/212b35c9bbe34e54a66cc9df43f5b72a?sid=62df94bc-7b92-4bac-bf8c-e89a599caf16"/> */}
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    ):(
      <DashboardSkeleton />
    )
  )
}

export default Addons