import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import Select from "react-select";
import AddonDropdown from "./AddonDropdown.js";
import {OrganizationContext} from "../../Shared/OrganizationContext.js";
import finishedData from "../../Shared/Lotties/lottie-not-allowed.json";
import { Player } from "@lottiefiles/react-lottie-player";

const AddonForm = ({services, refetchData, selected_services, closeModal, service_addons}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const organizationAuthorizations = useContext(OrganizationContext);
  const [disabled, setDisabled] = useState(false);
  const [selectedServices, setSelectedServices] = useState([selected_services]);
  const [selectedService, setSelectedService] = useState(null);
  const [servicePrices, setServicePrices] = useState([]);
  const [chosenServicePrice, setChosenServicePrice] = useState(null);

  useEffect(() => {
    setChosenServicePrice(null);
  }, []);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/o/${match.params.organization_id}/create_existing_service_addon`, {
      selected_services: selectedServices,
      selected_service_id: selectedService.value,
      chosen_service_price: chosenServicePrice.value,
    })
    .then(function (response) {
      console.log(response);
      if (response.data.success) {
        notice("Great work on your add-ons!"); 
        closeModal();
        refetchData();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function (error) {
      console.log(error)
      notice("An error occurred");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const handleServicePriceChange = (selectedOption) => {
    setChosenServicePrice(selectedOption);
  };

  const options = servicePrices.map((service_price) => ({
    value: service_price.token,
    label: `${(parseFloat((service_price.amount/ 100).toFixed(2))).toLocaleString('en-US', {style: 'currency',currency: (service_price.currency)})} ${service_price.recurring ? `per ${service_price.recurring_interval_count} ${service_price.recurring_interval}` : ""}`,
  }));

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black', // Text color of options
    }),
  };

  const serviceOptions = services.filter((item) => !selectedServices.some((selected) => selected.value === item.token)).map((item) => ({
    value: item.token,
    label: item.title,
  }));

  const clearOption = { label: '+ New service', value: null }; // Option to clear the selection
  const handleServiceSelect = (selectedOption) => {
    setChosenServicePrice(null);
    if(selectedOption.value == null){
      setSelectedService(null)
    } else {
      setSelectedService(selectedOption);
      axios.get(`/api/o/${match.params.organization_id}/services/${selectedOption.value}.json`)
      .then(function (response) {
        console.log(response);
        if (response.data.success) {
          setServicePrices(response.data.service.service_prices);
        } else {

        }
      })
      .catch(function (error) {
        console.log(error)
        notice("An error occurred")
      })
      .then(function () {
        // always executed
      });
    }
  };

  const service_addons_list = service_addons.map(service_addon => {
    return(
      <tr>
        <td>{service_addon.service.title}</td>
        <td>{service_addon.service_price.price}</td>
        <td className="text-right">
          <AddonDropdown serviceAddon={service_addon} refetchData={refetchData} />
        </td>
      </tr>
    )
  })

  return(
    (organizationAuthorizations.organization.paypal_account && !organizationAuthorizations.organization.stripe_account) ?(
        <div className="large-padding text-center">
          <Player style={{width: "200px", margin: "auto"}} mode="normal" autoplay loop src={finishedData} />
          <p className="mt-15 opacity-6">Sorry, only Stripe supports multi-service checkouts...</p>
        </div>
      ):(
        <React.Fragment>
          <form onSubmit={handleSubmit}>
            <div className="field">
              <label>Service</label>
              <Select
                options={serviceOptions}
                value={selectedService}
                onChange={handleServiceSelect}
                className="min-width-350"
                placeholder="Select..."
                styles={customStyles} 
              />
              <p className="dark-color-black opacity-4 font-12 mt-10">You can offer an existing service as an add-on or create a new one.</p>
            </div>

            {selectedService != null && 
              <div className="field">
                <label>Select pricing</label>
                <Select
                  options={options}
                  value={chosenServicePrice}
                  onChange={handleServicePriceChange}
                  placeholder="Select a Service Price"
                  styles={customStyles} 
                />
              </div>
            }

            <div className="field text-right">
              <button disabled={disabled || chosenServicePrice === null} className="btn btn-primary" type="submit">Create add-on</button>
            </div>
          </form>

          {service_addons.length > 0 && 
            <div className="field border-top pt-15">
              <label>Add-ons</label>

              <table class="table" style={{marginLeft: "-15px"}}>
                <tbody>
                  {service_addons_list}
                </tbody>
              </table>
            </div>
          }
        </React.Fragment>
      
      )
  )
}

export default AddonForm;