import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 

const SnippetForm = ({refetchData, closeModal}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [location, setLocation] = useState('All');
  const [title, setTitle] = useState("");
  const [code, setCode] = useState("");

  const handleChange = (event) => {
    setLocation(event.target.value);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/o/${match.params.organization_id}/snippets`, {
      title: title, 
      code: code, 
      location: location
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData(); 
        closeModal();
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  

  return(
    <React.Fragment>
      <h4 className="color-black-always modal-title">Add snippet</h4>

      <div className="row no-margin">
        <form onSubmit={handleSubmit}>
          <div className="field">
            <label>Title</label>
            <input className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} required="true"/>
          </div>

          <div className="field">
            <label htmlFor="location-dropdown">Location (Client side pages)</label>
            <select id="location-dropdown" value={location} onChange={handleChange}>
              <option value="All">Client portal</option>
              <option value="Checkout">Checkout</option>
              {/* <option value="Project">Project</option>
              <option value="Catalog">Catalog</option> */}
            </select>
          </div>


          <div className="field">
            <label>Code</label>
            <textarea className="form-control" value={code} onChange={(e) => setCode(e.target.value)} rows="5" required="true"></textarea>
          </div>

          <div className="field text-right">
            <button disabled={disabled} className="btn btn-primary" type="submit">Submit</button>
          </div>
        </form>
      </div>
    </React.Fragment>
  )
}

export default SnippetForm