import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 

const InvoicePayment = ({invoice, refetchData, closeModal}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [paidAmount, setPaidAmount] = useState(invoice.paid_amount);
  const [paidMethod, setPaidMethod] = useState(invoice.paid_method);
  const [paidDate, setPaidDate] = useState(invoice.paid_date);
  const [description, setDescription] = useState(invoice.description);
  const [notify, setNotify] = useState(true);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.put(`/api/o/${match.params.organization_id}/projects/${invoice.project.token}/invoices/${invoice.token}`, {
      paid_amount: paidAmount,
      paid_method: paidMethod,
      paid_date: paidDate,
      description: description,
      notify: notify
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        notice("Successfully recorded payment");
        closeModal();
        refetchData();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });

    // axios.post(`/api/o/${match.params.organization_id}/projects/${invoice.project.token}/invoices/${invoice.token}`, {
    //   kind: "Invoice",
    //   invoice_manual_payment_data: {
    //     paid_amount: paidAmount,
    //     paid_method: paidMethod,
    //     paid_date: paidDate,
    //     description: description,
    //     notify: notify
    //   }
    // })
    // .then(function(response){
    //   console.log(response);
    //   if(response.data.success){
    //     notice("Successfully recorded payment");
    //     closeModal();
    //     refetchData();
    //   } else {
    //     response.data.errors.forEach((error) => {
    //       notice(error);
    //     });
    //   }
    // })
    // .catch(function(error){
    //   console.log(error)
    //   notice("An error occured");
    //   reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    // })
    // .then(function () {
    //   setDisabled(false);
    // });
  }


  return(
    <React.Fragment>
      <h4 className="color-black-always modal-title">Record Invoice Payment</h4>

      <div className="row no-margin">
        <form onSubmit={handleSubmit}>
          <div className="row field">
            <div className="col-6 pr-15">
              <label>Payment method</label>
              <select className="form-control" onChange={(e) => setPaidMethod(e.target.value)} required>
                {paidMethod == null ?(
                  <option>Payment method</option>
                ):(
                  <option value={paidMethod}>{paidMethod}</option>
                )}
                <option value="Bank Transfer">Bank Transfer</option>
                <option value="Cash">Cash</option>
                <option value="Check">Check</option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div className="col-6">
              <label>Payment amount</label>
              <div class="input-group">
                <input className="form-control" defaultValue={paidAmount} onChange={(e) => setPaidAmount(e.target.value)} type="number" required/>
                <span class="input-group-addon caps">{invoice.currency}</span>
              </div>
            </div>
          </div>

          <div className="row field">
            <div className="col-6 pr-15">
              <label>Date Paid</label>
              <input className="form-control" type="date" defaultValue={paidDate} onChange={(e) => setPaidDate(e.target.value)} required/>
            </div>

            <div className="col-6">
              <label>Email client receipt</label>
              <select className="form-control" onChange={(e) => setNotify(e.target.value)} required>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
          </div>

          <div className="field">
            <label>Internal note</label>
            <textarea defaultValue={description} className="form-control" rows="3" placeholder="Optional" onChange={(e) => setDescription(e.target.value)}/>
          </div>

          <div className="field text-right">
            <button className="btn btn-primary" type="submit">Mark as Paid</button>
          </div>
        </form>
        
        <p className="opacity-4 text-center font-12">Last updated {invoice.updated_at}</p>
      </div>
    </React.Fragment>
  )
}

export default InvoicePayment;