import React , { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import ClientOnboardings from "./ClientOnboardings.js";
import VideoGuide from "../Shared/VideoGuide.js"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ClientOnboardingFormItem from "./ClientOnboardingFormItem.js";
import animationData from "../Shared/Lotties/lottie-empty.json";
import { Player } from "@lottiefiles/react-lottie-player";

const New = (props) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [forms, setForms] = useState([]); // State to hold an array of forms
  const [services, setServices] = useState([]);
  const [nextId, setNextId] = useState(2); // State to track the next available ID

  useEffect(() => {
    fetchClientOnboardings();
    fetchServices();
  }, []);

  const fetchClientOnboardings = () => {
    axios.get(`/api/o/${match.params.organization_id}/client_onboardings.json`)
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        setForms(response.data.client_onboardings)
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const fetchServices = () => {
    axios.get(`/api/o/${match.params.organization_id}/services.json`)
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        setServices(response.data.services);
        setLoaded(true);
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const createOnboardingDemo = () => {
    setDisabled(true)
    axios.post(`/api/o/${match.params.organization_id}/client_onboardings_demo`)
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        fetchClientOnboardings();
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }
  

  const handleAddForm = () => {
    axios.post(`/api/o/${match.params.organization_id}/client_onboardings`, {
      title: "New item", 
      position: (forms.length + 1)
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        setForms((prevForms) => [
          ...prevForms,
          {
            id: response.data.client_onboarding.token,
            token: response.data.client_onboarding.token,
            title: response.data.client_onboarding.title,
            description: response.data.client_onboarding.description,
            kind: response.data.client_onboarding.kind,
            link: response.data.client_onboarding.link, 
            page: response.data.client_onboarding.page, 
            video_link: response.data.client_onboarding.video_link, 
            position: response.data.client_onboarding.position, 
            selected_services: [], 
            client_onboarding_reads: []
          },
        ]);
        setNextId((prevId) => prevId + 1);
      } else {
        errors_list = response.data.errors;
        errors_list.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  };


  // const handleServiceSelect = (selectedOption) => {
  //   setChosenServicePrice(null);
  //   setSelectedService(selectedOption);
  // };

  
  
  const handleDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
  
    const updatedForms = Array.from(forms);
    const [movedItem] = updatedForms.splice(result.source.index, 1);
    updatedForms.splice(result.destination.index, 0, movedItem);
  
    // Update the position property of the forms based on their new index
    updatedForms.forEach((form, index) => {
      form.position = index + 1; // Adding 1 because positions start from 1, not 0
      axios.put(`/api/o/${match.params.organization_id}/client_onboardings/${form.token}`, {
        position: index + 1
      })
      .then(function(response) {
        // Handle the response as needed
        console.log(`Update successful for item with token ${form.token}:`, response.data);
      })
      .catch(function(error) {
        // Handle errors
        console.error(`Error updating position for item with token ${form.token}:`, response.data.errors);
      })
    });
  
    setForms(updatedForms);
  };

  return(
    loaded &&
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-6" style={{padding: "30px"}}>
            <h4 className="font-weight-600 display-flex justify-content-between">
              Onboarding Checklist
              <button className="btn btn-primary border-none btn-small" onClick={handleAddForm}>
                <i class="far fa-plus mr-8"></i>Onboarding item
              </button>
            </h4>
            <p className="font-12 opacity-6">Add an onboarding checklist to help your client setup faster. <VideoGuide src={"https://www.loom.com/embed/e14bc28f8b6449c19082684445ef4466?sid=b8594180-dfaf-42b1-a439-dd577bb4d5e0"}/></p>
            
            <DragDropContext onDragEnd={handleDragEnd}>
              <div id="sortable-container" className="panel-group mt-15">
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {forms.slice().sort((a, b) => a.position - b.position).map((form, index) => (
                        <Draggable key={form.token} draggableId={form.token} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="panel panel-default background-1 border-all dark-background-3"
                              data-token={form.token}
                            >
                              <ClientOnboardingFormItem index={index} form={form} setForms={setForms} services={services} refetchData={fetchClientOnboardings} forms={forms} loaded={loaded}/>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </DragDropContext>

            {forms.length === 0 && 
              <p className="font-12 large-padding opacity-4 text-center">
                <Player style={{width: "300px", margin: "auto"}} mode="normal" autoplay loop src={animationData} />
                You haven't added any onboarding checklists 
              </p>
            }
            
            {forms.length === 0 && 
              <div className="mt-60">
                <h4>Or use our template</h4>
                <div className="row">
                  <div className="col-12 col-md-4">
                    <div className="border-all border-radius background-3 cursor-pointer border-all-blue-hover" onClick={() => {disabled ? console.log("disabled") : createOnboardingDemo()}} disabled={disabled}>
                      <img src="https://thinked.nyc3.cdn.digitaloceanspaces.com/onboarding-template.png" style={{width: "100%"}}/>
                      <div className="default-padding">
                        <label className="opacity-10">
                          Onboarding template
                        </label>
                        <p className="font-12 opacity-6">
                          4 questions
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
          
          <div className="col-12 col-md-6 col-lg-6">
            <div className="row">
              <div className="client-onboarding-checklist-wrapper">
                <ClientOnboardings forms={forms} customClass="" owner={true} refetchData={fetchClientOnboardings}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default New