import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import Toggle from 'react-toggle';
import { Modal } from 'react-responsive-modal';
import Form from "./Form.js";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import AddonDropdown from "./AddonDropdown.js";

const ServiceAddon = ({serviceAddon, service, refetchData}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);


  const handleDelete = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.delete(`/api/o/${match.params.organization_id}/services/${service.token}`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        notice("Deleted");
        refetchData();
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const toggleService = (boolean) => {
    axios.post(`/api/o/${match.params.organization_id}/services/${service.token}/toggle`, {
      active: boolean
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        notice("Deleted");
        refetchData();
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  // const prices_list = service.service_prices.map(price => {
  //   return(
  //     <div className="daas-services-item-prices-item">
  //       {price.stripe_data.type == "recurring" && 
  //       <React.Fragment>
  //         {numberToCurrency((price.stripe_data.unit_amount/100), price.stripe_data.currency)} {price.stripe_data.currency.toUpperCase()} per {price.stripe_data.recurring.interval_count} {price.stripe_data.recurring.interval}
  //       </React.Fragment>
  //       }
  //       {price.stripe_data.type == "one_time" && 
  //         <React.Fragment>
  //           {numberToCurrency((price.stripe_data.unit_amount/100), price.stripe_data.currency)} {price.stripe_data.currency.toUpperCase()}
  //         </React.Fragment>
  //       }
  //     </div>
  //   )
  // })

  return(
    <React.Fragment>
      <tr>
        <td className="service-item-td font-weight-600">
          {service.title}
        </td>
        <td className="service-item-td">

          {`${(parseFloat((serviceAddon.service_price.stripe_data.unit_amount/ 100).toFixed(2))).toLocaleString('en-US', {style: 'currency',currency: (serviceAddon.service_price.stripe_data.currency)})} ${serviceAddon.service_price.stripe_data.recurring != null && `per ${serviceAddon.service_price.stripe_data.recurring.interval_count} ${serviceAddon.service_price.stripe_data.recurring.interval}`}`}
        </td>
        <td className="service-item-td">{serviceAddon.parent_service.title}</td>
        <td className="service-item-td text-right"> 
          <AddonDropdown serviceAddon={serviceAddon} service={service} refetchData={refetchData} addon={true}/>
        </td>
      </tr>
    </React.Fragment>
  )
}

export default ServiceAddon