import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 

const SetupStatus = ({affiliate}) => {
  const match = useRouteMatch();

  return(
    <React.Fragment>
      <React.Fragment>
        {!affiliate.affiliate_setup_finished && (
          <div className="banner-warning">
            You have not finished setup. Please go <Link to={`/o/${match.params.organization_id}/referrals/setup`} className="color-blue">program settings</Link> to finish it.
          </div>
        )}
      </React.Fragment>
    </React.Fragment>
  )
}

export default SetupStatus