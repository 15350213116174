import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import Premium from "../Subscriptions/Premium.js";
import { Modal } from 'react-responsive-modal';

const TrialEnding = ({organizationAuthorizations, upgrade}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false); 

  return(
    <React.Fragment>
      {(organizationAuthorizations.organization.organization_user && organizationAuthorizations.organization.tier === null && organizationAuthorizations.organization_subscribed.days_left != null) && 
        <React.Fragment>
          <a onClick={upgrade} className="trial-ending-wrapper alert-warning">
            🥳 Congrats on getting a client! You have {organizationAuthorizations.organization_subscribed.days_left} days to <strong>upgrade</strong>. If you're just testing, you can delete the project or remove the client from the peoples page.
          </a>
        </React.Fragment>
      }
    </React.Fragment>
  )
}

export default TrialEnding;