import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import ReactTooltip from 'react-tooltip';

const TaskUsers = ({task, refetchData, organizationUser, project_id}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    axios.get(`/api/o/${match.params.organization_id}/projects/${project_id || match.params.project_id}/users.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setUsers(response.data.users)
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const task_users_list = task.task_users.map(task_user => {
    const removeTaskUser = () => {
      axios.delete(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}/task_users/${task_user.token}`)
      .then(function(response){
        console.log(response);
        if(response.data.success){
          refetchData();
        } else {
  
        }
      })
      .catch(function(error){
        console.log(error)
        notice("An error occured")
      })
      .then(function () {
        setDisabled(false);
      });
    }

    return(
      <div className="task-user-item hover-parent-opacity animated fadeIn animate btn btn-small-task-question-answer background-hover">
        <i className="fas fa-times task-user-item-delete hover-child-opacity" onClick={removeTaskUser}></i>
        <img src={task_user.user.avatar} className="avatar-small mr-8"/>
        <span className="font-13">{task_user.user.username}</span>
      </div>
    )
  })

  const users_list = users.map(user => {
    if (location.href.includes("/portal/o/") && user.private) {
      // If condition is met, return null or an empty fragment to skip rendering
      return null;
    }

    const isUserAssigned = task.task_users.some(taskUser => taskUser.user.id === user.id);
    const assignedTaskUser = task.task_users.find(taskUser => taskUser.user.id === user.id);

    const handleSubmit = (evt) => {
      evt.preventDefault();
      setDisabled(true);
  
      axios.post(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}/task_users`, {
        email: user.email
      })
      .then(function(response){
        console.log(response);
        if(response.data.success){
          refetchData();
        } else {
  
        }
      })
      .catch(function(error){
        console.log(error)
        notice("An error occured")
      })
      .then(function () {
        setDisabled(false);
      });
    }

    const handleDelete = (token) => {

      axios.delete(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}/task_users/${token}`)
      .then(function(response){
        console.log(response);
        if(response.data.success){
          refetchData();
        } else {
  
        }
      })
      .catch(function(error){
        console.log(error)
        notice("An error occured")
      })
      .then(function () {
        setDisabled(false);
      });
    }

    return(
      <React.Fragment> 
        {isUserAssigned ? (
          <li onClick={() => handleDelete(assignedTaskUser.token)}>
            <a className="hover-parent-opacity">
              <img src={user.avatar} className="task-user-form-avatar"/>{user.username} {isUserAssigned ? <i className="fas fa-check ml-5 font-12"></i> : null}
            </a>
          </li>
        ):(
          <li onClick={handleSubmit}>
            <a className="hover-parent-opacity">
              {isUserAssigned ? <i className="fas fa-check color-green"></i> : null}
              <img src={user.avatar} className="task-user-form-avatar"/>{user.username}
            </a>
          </li>
        )}
      </React.Fragment>
    )
  })

  return(
    <React.Fragment>
      <div className="task-users-wrapper">
        {task_users_list}

        {(organizationUser && !location.href.includes("/portal/o/")) && 
          <div class="btn-group">
            <a type="button" class="badge animate dropdown-toggle border-all background-hover color-1" data-toggle="dropdown" data-tip="Assign">
              <i class="fas fa-plus dark-color-black"></i>
            </a>
            
            <ul class="dropdown-menu animated fadeInUp pull-right" role="menu">
              {users_list}
            </ul>
          </div>
        }
      </div>
    </React.Fragment>
  )
}

export default TaskUsers