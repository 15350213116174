import React, {useState} from 'react';
import { PickerInline } from 'filestack-react';
import { Modal } from 'react-responsive-modal';

const Filestack = ({ id, updateFormById }) => {
  const [open, setOpen] = useState(false);

  const createReplay = (filestack) => {
    const filesUploaded = filestack.filesUploaded;
    const imageFiles = Object.values(filesUploaded).filter(file => file.mimetype.startsWith('video/'));
    if (imageFiles.length > 0) {
      const imageFile = imageFiles[0];
      console.log(imageFile);
      updateFormById(id, { video_link: imageFile.url })
    } else {
      console.log('No image file uploaded');
    }
  }

  return (
    <React.Fragment>
      <a onClick={() => setOpen(true)} className="btn btn-secondary btn-small mr-15">
        Upload video
      </a>
      
      <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
        <PickerInline
          apikey={"AKVhxMbEQhkOIryqBvSEQz"}
          onSuccess={(res) => console.log(res)}
          onUploadDone={(res) => {
            createReplay(res);
          }}
          clientOptions={{
            sessionCache: true
          }}
          pickerOptions={{
            onClose: () => {
              setOpen(false);
            }, 
            fromSources: ["local_file_system", "googledrive", "dropbox", "box", "onedrive", "onedriveforbusiness", "googlephotos", "facebook", "instagram"],
            maxFiles: 1,
            accept: 'video/*'
          }}
        />
      </Modal>
    </React.Fragment>
  );
}

export default Filestack;