import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import Theme from "./Theme.js";
import OrganizationTheme from "./OrganizationTheme.js";
import ScrollAnimation from "react-animate-on-scroll";

const Themes = ({onboarding}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [themesLoaded, setThemesLoaded] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [themes, setThemes] = useState([]);
  const [organizationThemes, setOrganizationThemes] = useState([]);

  useEffect(() => {
    fetchThemes();
    fetchOrganizationThemes();
  }, []);

  const fetchThemes = () => {
    axios.get(`/api/themes.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setThemes(response.data.themes);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const fetchOrganizationThemes = () => {
    axios.get(`/api/o/${match.params.organization_id}/organization_themes.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setOrganizationThemes(response.data.organization_themes);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const themes_list = themes.map((theme, index) => {
    return(
      <Theme theme={theme} refetchData={fetchOrganizationThemes}/>
    )
  });

  const organization_themes_list = organizationThemes.map(organization_theme => {
    return(
      <OrganizationTheme organization_theme={organization_theme} refetchData={fetchOrganizationThemes}/>
    )
  })

  return(
    <React.Fragment>
      <div className="container mt-15">
        {!onboarding && 
          <h4>Themes</h4>
        }

        <div className="row">
          <div className="col-12">
            <div className="border-all border-radius background-3 default-padding pb-0">
              <div className="default-padding">
                <label>Theme library</label>
                <p className="opacity-6">You can switch to another theme by making it a primary.</p>

                {organization_themes_list}
              </div>
            </div>
          </div>
        </div>

        {/* Available themes */}
        <div className="border-all border-radius background-3 default-padding mt-25">
          <div className="default-padding pb-0">
            <label>Popular free themes</label>
            <p className="opacity-6 mb-0">
              Made with core features you can easily customize—no coding needed.
            </p>
          </div>

          <div className="row">
            {themes_list}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Themes;