import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import TipTap from "../../Shared/TipTap.js";
import { PickerInline } from 'filestack-react';

const ClientOnboardingReadForm = ({form, refetchData, closeModal}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [files, setFiles] = useState([]);
  
  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/o/${match.params.organization_id}/client_onboardings/${form.token}/client_onboarding_reads`, {
      project_id: match.params.project_id,
      uploads: files,
      comment: comment,
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData();
        closeModal();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const createReplay = (filestack) => {
    const filesUploaded = filestack.filesUploaded;
    setFiles(filesUploaded);
  }

  const client_onboarding_reads_list = form.client_onboarding_reads.map(item => {
    const uploads_list = item.uploads.map(upload => {
      return(
        <a className="badge badge-blue mr-8" target="_blank" href={`https://cdn.filestackcontent.com/AKVhxMbEQhkOIryqBvSEQz/content=t:attachment,f:${upload.filename}/${upload.url.match(/\/([^\/]+)$/)[1]}`}>{upload.filename}</a>
      )
    })
    return(
      <div className="default-padding background-3 border-all mb-15 border-radius">
        <label className="opacity-10 mb-15">
          {item.user.username} <span className="font-12 ml-5 opacity-4">{item.created_at}</span>
        </label>
        <div className="font-13 opacity-4" dangerouslySetInnerHTML={{ __html: item.comment }} />
        {uploads_list}
      </div>
    )
  })

  return(
    <React.Fragment>
      <h4 className="color-black-always modal-title">
        {form.title}
        {/* <a className="btn btn-primary btn-small">Submit</a> */}
      </h4>
      <p className="modal-description">{form.description}</p>

      {/* Form  */}
      {form.client_onboarding_reads.length === 0 &&
        <div className="row no-margin">
          {form.kind === "Text" && 
            <form className="mt-15" onSubmit={handleSubmit}>
              <div className="field">
                <label>Response</label>
                <TipTap comment={comment} setComment={setComment} users={[]}/>
              </div>

              <div className="field text-right">
                <button className="btn btn-primary" type="submit" disabled={disabled || comment === ""}>Submit</button>
              </div>
            </form>
          }
          
          {form.kind === "File" && 
            <form className="mt-15" onSubmit={handleSubmit}>
              <div className="field">
                <PickerInline
                  apikey={"AKVhxMbEQhkOIryqBvSEQz"}
                  onSuccess={(res) => console.log(res)}
                  onUploadDone={(res) => {
                    createReplay(res);
                  }}
                  clientOptions={{
                    sessionCache: true
                  }}
                  pickerOptions={{
                    onClose: () => {
                      setOpen(false);
                    }, 
                    fromSources: ["local_file_system", "googledrive", "dropbox", "box", "onedrive", "onedriveforbusiness", "googlephotos", "facebook", "instagram"],
                    maxFiles: 100
                  }}
                />
              </div>
              

              <div className="field text-right">
                <button className="btn btn-primary" type="submit" disabled={disabled || files.length === 0}>Submit</button>
              </div>
            </form>
          }
        </div>
      }

      {form.kind === "Comments" && 
        <div className="font-13 opacity-4" dangerouslySetInnerHTML={{ __html: form.comments }} />
      }
          
      {((form.client_onboarding_reads.length > 0) && (form.kind === "File" || form.kind === "Text")) && 
        <div className="row no-margin pb-15 pt-15">
          <h5 className="">Response</h5>
          {client_onboarding_reads_list}
        </div>
      }
    </React.Fragment>
  )
}

export default ClientOnboardingReadForm;