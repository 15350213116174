import React , { useState, useEffect, useContext } from 'react';
import { useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import {UserContext} from "../../Shared/UserContext.js";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ReactTooltip from 'react-tooltip';

const EmailSetup = ({}) => {
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [organization, setOrganization] =  useState();
  const [email, setEmail] = useState("");
  const [postmarkSetup, setPostmarkSetup] = useState(null);
  const currentUser = useContext(UserContext);

  useEffect(() => {
    fetchOrganization()
  }, [])

  const fetchOrganization = () => {
    axios.get(`/api/o/${match.params.organization_id}.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setEmail(response.data.organization.email);
        setOrganization(response.data.organization);
        setPostmarkSetup(response.data.organization.postmarksetup)
        setLoaded(true);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();

    axios.post(`/api/o/${match.params.organization_id}/verify_email`, {
      email: email
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        notice("Please complete the setup on step 2");
        fetchOrganization();
      } else {
        notice(response.data.error)
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const handleVerify = (evt) => {
    evt.preventDefault();

    axios.post(`/api/o/${match.params.organization_id}/verify_dns`, {
      email: email
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        notice("Verification finished");
        fetchOrganization();
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  return(
    loaded && 
    <React.Fragment>
        <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
        <div className="">
          <div className="background-3 border-all border-radius default-padding">
            <h4 className="font-weight-600">Step 1: Add your email</h4>
            <p className="opacity-7 text-12" style={{marginTop: "-5px"}}>
              By default emails will show up as <span className="badge badge-default">From: {currentUser.username} {"<team@usequeue.com>"}</span>to your clients, but you can add your own custom email to whitelabel it.
            </p> 

            <form onSubmit={handleSubmit}>
              <div className="field border-bottom default-padding row" style={{marginLeft: "-15px"}}>
                <div className="col-12 col-md-8">
                  <div className="font-weight-600 ">
                    Email
                  </div>

                  <div className="opacity-7">
                    This email will be used to send all emails related to {organization.title}
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <input type="email" className="form-control form-control-dark" placeholder="ACME INC." value={email} onChange={(e) => setEmail(e.target.value)} required="true"/>
                </div>
              </div>
              
              <div className="field text-right">
                <button className="btn btn-primary" type="submit" disabled={disabled}>Update</button>
              </div>
            </form>
          </div>
          
          {postmarkSetup != null &&
            <div className="background-3 border-all border-radius default-padding mt-20">
              <div className="field">
                <h4>Step 2: Update your DNS</h4>
                <p className="opacity-7 text-12" style={{marginTop: "-5px"}}>Reach out to customer support if you'd like us to walk you through this.</p>
              </div>

              <div className="field dark-border-bottom-dark default-padding row" style={{marginLeft: "-15px"}}>
                <div className="col-2">
                  
                </div>

                <div className="col-3">
                  Hostname
                </div>
                
                <div className="col-1">
                  Type
                </div>

                <div className="col-5 cursor-pointer word-wrap-break">
                  Add this value
                </div>

                <div className="col-1 text-right">
                  
                </div>
              </div>

              {/* DKIM */}
              <div className="field dark-border-bottom-dark default-padding row" style={{marginLeft: "-15px"}}>
                <div className="col-2">
                  {postmarkSetup.DKIMVerified ? (
                    <i class="fas fa-check-circle color-green font-21 mr-5"></i>
                  ):(
                    <i class="fas fa-times-circle color-red font-21 mr-5"></i>
                  )}
                  DKIM
                </div>

                <div className="col-3 cursor-pointer word-wrap-break" data-tip="Click to copy">
                  {postmarkSetup.DKIMHost != null && 
                    <CopyToClipboard text={postmarkSetup.DKIMHost != "" ? postmarkSetup.DKIMHost.split("_domainkey")[0] + "_domainkey" : postmarkSetup.DKIMPendingHost.split("_domainkey")[0] + "_domainkey"} onCopy={() => {notice("Copied")}}>
                      <div>
                        {postmarkSetup.DKIMHost != "" ? postmarkSetup.DKIMHost.split("_domainkey")[0] + "_domainkey" : postmarkSetup.DKIMPendingHost.split("_domainkey")[0] + "_domainkey"}
                      </div>
                    </CopyToClipboard>
                  }
                </div>
                
                <div className="col-1">
                  TXT
                </div>

                <div className="col-5 cursor-pointer word-wrap-break" data-tip="Click to copy">
                  <CopyToClipboard text={postmarkSetup.DKIMPendingTextValue || postmarkSetup.DKIMTextValue} onCopy={() => {notice("Copied")}}>
                    <div>
                      {postmarkSetup.DKIMPendingTextValue || postmarkSetup.DKIMTextValue}
                    </div>
                  </CopyToClipboard>
                </div>

                <div className="col-1 text-right">
                  <button className="btn btn-primary btn-small" onClick={handleVerify}>Verify</button>
                </div>
              </div>

              {/* RETURN-PATH */}
              <div className="field dark-border-bottom-dark default-padding row" style={{marginLeft: "-15px"}}>
                <div className="col-2">
                  {postmarkSetup.ReturnPathDomainVerified ? (
                    <i class="fas fa-check-circle color-green font-21 mr-5"></i>
                  ):(
                    <i class="fas fa-times-circle color-red font-21 mr-5"></i>
                  )}
                  Return-Path
                </div>

                <div className="col-3 cursor-pointer word-wrap-break" data-tip="Click to copy">
                  <CopyToClipboard text={"pm-bounces"} onCopy={() => {notice("Copied")}}>
                    <div>
                      pm-bounces
                    </div>
                  </CopyToClipboard>
                </div>
                
                <div className="col-1">
                  CNAME
                </div>

                <div className="col-5 cursor-pointer word-wrap-break" data-tip="Click to copy">
                  <CopyToClipboard text={postmarkSetup.ReturnPathDomainCNAMEValue} onCopy={() => {notice("Copied")}}>
                    <div>
                      {postmarkSetup.ReturnPathDomainCNAMEValue}
                    </div>
                  </CopyToClipboard>
                </div>

                <div className="col-1 text-right">
                  <button className="btn btn-primary btn-small" onClick={handleVerify}>Verify</button>
                </div>
              </div>
            </div>
          }

          {organization.email_verified && 
            <div className="row">
              <div className="default-padding text-center col-12">
                <h4 className="color-green"><i class="fas fa-check mr-5"></i> Setup finished</h4>
              </div>
            </div>
          }
        </div>
    </React.Fragment>
  )
}

export default EmailSetup