import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import {OrganizationContext} from "../../Shared/OrganizationContext.js";
import {UserContext} from "../../Shared/UserContext.js";
import finishedData from "../../Shared/Lotties/lottie-finished.json";
import { Player } from "@lottiefiles/react-lottie-player";

const Paypal = ({refetchData}) => {
  const organizationAuthorizations = useContext(OrganizationContext);
  const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [paypalAccount, setPaypalAccount] = useState(organizationAuthorizations.organization.paypal_account)
  const [paypalAccountToken, setPaypalAccountToken] = useState(null);
  const [email, setEmail] = useState(null);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/o/${match.params.organization_id}/paypal_accounts/${paypalAccountToken}/add_organization`, {
      organization_id: match.params.organization_id
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        setPaypalAccount(response.data.paypal_account);
        setTimeout(() => {
          refetchData();
          setOpen(false)
        }, 2500);
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  return(
    <React.Fragment>
      <a style={{background: "#00457C"}} className="btn btn-primary mt-15" onClick={() => setOpen(true)}>
        <i class="fab fa-paypal mr-8"></i>PayPal
      </a>

      <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        
          {paypalAccount ? (
            <div className="text-center large-padding display-flex justify-content-center align-items-center" style={{flexDirection: "column"}}>
              <Player style={{width: "300px", margin: "auto"}} mode="normal" autoplay loop src={finishedData} />
            </div>
          ):(
            <>
              <div className="row no-margin mt-30">
                <label>Step 1: Integrate with PayPal</label>
                <a className="btn btn-primary" href={`https://www.paypal.com/signin/authorize?flowEntry=static&client_id=ASmRCY7P0GZIDQXZ5g_dvBrjOgp-bvX9pOO5TDqKpME2grCexIulIQD1RKUWVHdBSS7eymLq6eE-Rout&scope=openid profile email address https://uri.paypal.com/services/paypalattributes https://uri.paypal.com/services/disputes/read-seller https://uri.paypal.com/services/disputes/update-seller https://uri.paypal.com/services/identity/activities https://uri.paypal.com/services/invoicing https://uri.paypal.com/services/payments/payment/authcapture https://uri.paypal.com/services/payments/realtimepayment https://uri.paypal.com/services/payments/refund https://uri.paypal.com/services/paypalattributes/business https://uri.paypal.com/services/paypalhere https://uri.paypal.com/services/subscriptions &redirect_uri=https://${organizationAuthorizations.production ? "app.usequeue.com" : "queue.ngrok.io"}/api/paypal_oauth_callback`} target="blank">
                  <i class="fab fa-cc-paypal mr-8"></i>Connect PayPal
                </a>

                <p className="opacity-4 text-center font-12 mt-15">
                  Use the link to connect your PayPal and grab your access token to finish setup. 
                </p>

                <div className="mt-15 mb-15">
                  <hr />
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="field">
                    <label>Step 2: Access token</label>
                    <div class="input-group">
                      {/* A23AAMeIPRFkAR_26MqLiw2ybogYNf7HXAt-Iaw6Q_k2Zxysh0z9-aolpdz6Dw5uXh48n_GIG6gLkcUH-OlYcvTJ9D39Qi4-g */}
                      <input className="form-control" defaultValue={paypalAccount?.token} placeholder="Access token" required="true" onChange={(e) => setPaypalAccountToken(e.target.value)} />
                      <span class="input-group-btn">
                        <button class="btn btn-default" type="submit" disabled={disabled}>{t("Submit")}</button>
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
      </Modal>
    </React.Fragment>
  )
}

export default Paypal;