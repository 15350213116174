import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import Versions from "../../../Replays/Versions/Versions.js";

const TaskReplay = ({replay, refetchData, setReplays, replays, task_id}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  
  const deleteReplay = (evt) => {
    const confirmThis = confirm("Are you sure?")
    if(confirmThis){
      axios.delete(`/replays/${replay.token}`)
      .then(function(response){
        console.log(response);
        if(response.data.success){
          notice("Removed file")
          refetchData();
          // setReplays((replays) => replays.filter((replay) => replay.token !== replayToken));
        }
      })
      .catch(function(error){
        console.log(error)
        notice("An error occured")
      })
      .then(function () {
        setDisabled(false);
      });
    }
  }

  const trashReplay = () => {
    axios.post(`/api/replays/${replay.token}/remove_replay_from_task`, {
      task_id: task_id
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData();
        // setReplays((replays) => replays.filter((replay) => replay.token !== replayToken));
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });

  }

  return(
    <React.Fragment>
      <div className="daas-task-replays-wrapper hover-parent-opacity">
        <div className="col-6 card-replays-info">
          <img src={replay.preview_image || "https://img.icons8.com/?size=100&id=11321&format=png"} className="card-replays-info-image border-radius mr-10" />

          <div className="color-black-always">
            <a className="truncate color-1 width-250 opacity-6 opacity-10-hover animate" href={`/replays/${replay.token}`} target="_blank">
              {replay.title}
            </a>

            <div className="font-12 opacity-7">
              <i class="far fa-clock mr-5"></i>{replay.created_ago} ago by {replay.user.username}
            </div>
          </div>
        </div>

        <div className="col-1 font-12 daas-task-replays-cta">
          {replay.descendants_size > 1 && 
            <span onClick={() => setOpen(true)} className="badge background-active ml-8 cursor-pointer">v{replay.descendants_size}</span>
          }
        </div>

        <div className="col-1 font-12 daas-task-replays-cta">
          <div className="dark-color-black">
            <i class="fal fa-comment-alt-lines mr-5"></i>{replay.video_markers_size}
          </div>
        </div>
        
        <div className="col-3">
          <a className="btn btn-primary-outline btn-small mr-15" href={`/replays/${replay.token}`} target="_blank">
            Review <i className="fas fa-angle-right ml-5"></i>
          </a>

          <div class="btn-group opacity-6 opacity-10-hover">
            <button type="button" class="btn btn-small background-none hover-child-opacity dropdown-toggle color-1" data-toggle="dropdown">
              <i class="fas fa-ellipsis-v"></i>
            </button>
            <ul class="dropdown-menu pull-right animated fadeInUp" role="menu">
              <li><a href={replay.download_link} download target="_blank"><i class="fal fa-download mr-6"></i>Download</a></li>
              <li><a onClick={trashReplay}><i class="fal fa-minus-circle mr-5"></i>Remove</a></li>
              <li><a onClick={deleteReplay}><i class="fal fa-trash mr-5"></i>Delete file</a></li>
            </ul>
          </div>
        </div>
      </div>

      <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <Versions replay_id={replay.token} refetchData={() => refetchData()} closeModal={() => setOpen(false)}/>
      </Modal>
    </React.Fragment>
  )
}

export default TaskReplay;