import React, {useState, useRef} from 'react';
import Linkify from 'react-linkify';

const RadioGroup = ({ field, selectedOption, onChange, removeField, addOptionToField, handleRadioChange, taskForm, handleLabelChange, removeOption, handleRadioCheckboxOptionChange }) => {
  const [value, setValue] = useState('');
  const spanRef = useRef(null);

  const handleKeyPress = (e) => {
    setValue(spanRef.current.textContent);

    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent the default behavior of creating a new line
      
      // Perform the desired action here
      console.log('Enter key pressed');
      addOptionToField(field.id, value);
      setValue('');
      // Clear the content of the span
      spanRef.current.textContent = '';
    }
  };

  const handleOptionChange = (e) => {
    const selectedOption = e.target.value;
    handleRadioChange(field.id, selectedOption);
  };

  return (
    <React.Fragment>
      <div className="daas-task-form-label">
      {!taskForm ? (
          <label className={`task-form-label animate ${!taskForm ? "border-all-blue-hover" : ""}`} style={{cursor: "text"}} contentEditable={!taskForm} onBlur={(e) => handleLabelChange(field.id, e.target.textContent)}>
            {field.label}
          </label>
        ):(
          <label>
            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
              <a href={decoratedHref} key={key} target="_blank">{decoratedText}</a>
            )}>
              {field.label}
            </Linkify>
          </label>
        )}
        {!taskForm && 
          <div>
            <i onClick={() => removeField()} className="fas fa-times color-red opacity-4 opacity-10-hover animate padding-8 cursor-pointer"></i>
            {/* <i class="fas fa-grip-vertical ml-10"></i> */}
          </div>
        }
      </div>

      {field.options.map((option) => (
        <div className="field" key={option}>
          <input
            type="radio"
            style={{display: "inline"}}
            checked={selectedOption === option}
            onChange={handleOptionChange}
            value={option}
          />
          {!taskForm ? (
            <React.Fragment>
              <input className="daas-task-form-checkbox-empty border-all color-black-always" defaultValue={option} onBlur={(e) => handleRadioCheckboxOptionChange(field.id, option, e.target.value)}/>
              <i className="fas fa-times color-red opacity-4 opacity-10-hover animate cursor-pointer" style={{paddingLeft: "10px", paddingRight: "10px"}} onClick={() => removeOption(field.id, option)} />
            </React.Fragment>
          ):(
            <span className="ml-10 color-black-always">{option}</span>
          )}
        </div>
      ))}

      {!taskForm && 
        <div className="field daas-task-form-checkbox-wrapper">
          <input
            type="radio"
            style={{display: "inline"}}
            disabled
          />
          <span className="daas-task-form-checkbox-empty border-all color-black-always" contentEditable ref={spanRef} onKeyUp={handleKeyPress}></span>
        </div>
      }
    </React.Fragment>
  );
};

export default RadioGroup;
