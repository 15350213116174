import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 

const ClientInfo = ({organization, refetchData, closeModal, clientAddress, setClientAddress, clientEmail, setClientEmail, clientName, setClientName}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [address, setAddress] = useState(organization.address)
  const [bankInfo, setBankInfo] = useState(organization.bank_info);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    closeModal(true);
    // setDisabled(true);

    // axios.put(`/api/o/${match.params.organization_id}`, {
    //   address: address,
    //   bank_info: bankInfo
    // })
    // .then(function(response){
    //   console.log(response);
    //   if(response.data.success){
    //     refetchData();
    //     notice("Updated successfully");
    //     closeModal();
    //   } else {
    //     notice("An error occured")
    //   }
    // })
    // .catch(function(error){
    //   console.log(error)
    //   notice("An error occured");
    //   reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    // })
    // .then(function () {
    //   setDisabled(false);
    // });
  }


  return(
    <React.Fragment>
      <h4 className="color-black-always modal-title">Wire transfer instructions</h4>
      
      <div className="row no-margin">
        <form onSubmit={handleSubmit}>
          <div className="field">
            <label>Client Name</label>
            <input  className="form-control" placeholder="John Doe" value={clientName} onChange={(e) => setClientName(e.target.value)} required="true"/>
          </div>

          <div className="field">
            <label>Client Email</label>
            <input type="email" className="form-control" placeholder="something@email.com" value={clientEmail} onChange={(e) => setClientEmail(e.target.value)} required="true"/>
          </div>

          <div className="field">
            <label>Address (optional)</label>
            <textarea className="form-control" rows="3" placeholder="Optional" value={clientAddress} onChange={(e) => setClientAddress(e.target.value)}/>
          </div>

          <div className="field text-right">
            <button className="btn btn-primary" type="submit">Submit</button>
          </div>
        </form>
      </div>
    </React.Fragment>
  )
}

export default ClientInfo;