import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import ReactTooltip from 'react-tooltip';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs'; // Choose a code style
import {CopyToClipboard} from 'react-copy-to-clipboard';

const ReferralScript = ({affiliate}) => {
  const match = useRouteMatch();
  const code = `<script id="queue-affiliate-script" async src="https://app.usequeue.com/affiliate_code.js" data-organization_id="${match.params.organization_id}"></script>`;

  return(
    <React.Fragment>
      <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
      <div className="background-3 border-all mb-25 mt-25 pr-15 border-radius default-padding">
        <h4 className="mt-0 font-weight-600">Setup Affiliate Link (optional)</h4>
        {affiliate.organization_domain != null ? (
          <React.Fragment>
            <p className="opacity-4">Insert our script tag in your website's head tag for {affiliate.organization_domain}.</p>
            <div className="row">
              <div className="col-11">
                <SyntaxHighlighter language="javascript" style={docco}>
                  {code}
                </SyntaxHighlighter>
              </div>

              <div className="col-1">
                <CopyToClipboard text={code} onCopy={() => {notice("Copied Code")}}>
                  <button className="btn btn-small btn-primary-outline ml-5"><i className="fal fa-copy"></i></button>
                </CopyToClipboard>
              </div>
            </div>
            <div className="col-12">
              <p className="font-12 opacity-7">
                This step enables affiliate partners to share a referral URL, or they can share their Referral Code for checkout if not set up.
              </p>
            </div>
          </React.Fragment>
        ):(
          <React.Fragment>
            <div className="default-padding text-center">
              Please <Link to={`/o/${match.params.organization_id}/settings/domain`}>setup</Link> your domain to enable affiliate links.
            </div>
          </React.Fragment>
        )}

      </div>
    </React.Fragment>
  )
}

export default ReferralScript