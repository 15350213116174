import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 

const WidgetWelcomeMessage = ({welcome_message}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [welcomeMessage, setWelcomeMessage] = useState(welcome_message || null);
  const [organization, setOrganization] = useState(null);

  useEffect(() => {
    fetchWelcomeMessage();
  }, []);

  const fetchWelcomeMessage = () => {
    axios.get(`/api/o/${match.params.organization_id}/widget_data.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setWelcomeMessage(response.data.organization.welcome_message);
        setOrganization(response.data.organization)
        setLoaded(true);
        // Calculate the content height (you may need to adjust this logic)
        const contentHeight = document.body.scrollHeight;
        
        // Send a message to the parent page with the content height
        parent.postMessage({ type: 'resize-iframe', height: contentHeight }, '*');
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const toggleIframeVisibility = () => {
    window.parent.postMessage('hideWelcomeMessage', '*');
    window.parent.postMessage('toggleIframeVisibility', '*');
  }

  const hideWelcomeMessage = () => {
    window.parent.postMessage('hideWelcomeMessage', '*');
  }

  return(
    loaded && 
    <React.Fragment>
      <div className="widget-message-wrapper default-padding animated ZoomIn">
        <div className="widget-message-user field">
          <div>
            <img src={organization.user_avatar} className="avatar mr-5"/> <strong>{organization.username}</strong>
          </div>
          <i onClick={hideWelcomeMessage} className="weidget-message-close fas fa-times animate"></i>
        </div>

        <div className="widget-message-message field">
          {welcome_message || welcomeMessage}
        </div>

        <div className="widget-message-input field" onClick={() => {toggleIframeVisibility();}}>
          <input className="form-control text-black-only" placeholder="Write a reply..." />
        </div>
      </div>
    </React.Fragment>
  )
}

export default WidgetWelcomeMessage;